.review-stars-content {
  width: 110px;
  height: 18px;
  padding: 2px 0 0;

  &.small {
    width: 110px;
    height: 18px;
  }

  &.large {
    width: 160px;
    height: 24px;
  }

  .review-stars-underlay {
    position: relative;

    background-color: #f2c94c;
    width: 100%;

    &.small {
      height: 17px;
    }

    &.large {
      height: 22px;
    }
    svg {
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
  }

  .review-stars {
    position: absolute;
    top: -1px;
    left: 0px;
    display: flex;
  }

  .review-star {
    &.small {
      width: 18px;
      height: 18px;
    }

    &.large {
      width: 24px;
      height: 24px;
    }

    &.edit {
      cursor: pointer;
    }
  }

  .review-stars-divider {
    background: #fff;
    &.small {
      width: 5px;
      height: 18px;
    }

    &.large {
      width: 10px;
      height: 24px;
    }

    &.gray {
      background: #f8f8fa;
    }
  }
}
