@import "../../../../common.scss";

.review-card {
  max-width: 100%;
  width: 100%;
  background: #f8f8fa;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  .review-card-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
  }

  .review-card-content-wrap {
    max-width: 50%;
    display: flex;
  }

  .review-card-avatar {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    margin: 0 10px 0 0;
  }

  .review-card-avatar-plug {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    margin: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: $yellow;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-transform: uppercase;
    color: #fff;
  }

  .review-card-info {
  }

  .review-card-title {
    margin: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
  }

  .review-card-date {
    margin: 0;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: $original-gray;
  }

  .review-card-stars {
    margin: 0 0 20px;
    display: flex;
    gap: 5px;
  }

  .review-card-text {
    margin: 0;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
    opacity: 0.8;
  }

  .review-card-gallery {
    width: 100%;
    margin: 20px 0 0;
    display: flex;
    justify-content: flex-start;

    .swiper-container {
      margin: 0;

      .swiper-wrapper {
        .swiper-slide {
          max-width: 55px;
          min-width: 55px;
          height: 55px;
          cursor: pointer;
        }
      }
    }
    .review-card-gallery-image {
      width: 55px;
      height: 55px;
      border-radius: 5px;
      object-fit: cover;
    }
  }
}
