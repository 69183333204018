@import "../../../../../common.scss";

$major-color: rgba(255, 255, 255, 0.8);

.item-edit {
    @extend .flex-centered;
    width: 162px;
    max-height: 40px;
    background-color: transparent;

    &.max-width {
        width: 100%;
    }

    .quantity-btn {
        background: transparent;
        border: 1px solid rgba(155, 159, 179, 0.2);
        box-sizing: border-box;
        color: #fff;
        box-sizing: border-box;
        min-width: 40px;
        min-height: 40px;
        border-radius: 40px;
        @extend .flex-centered;
        display: flex;
        justify-content: center;
        // margin: 0 5px;
        cursor: pointer;
        position: relative;
        svg {
            fill: #fff;
        }
        .quantity-btn-tooltip {
            position: absolute;
            top: -62px;
            width: fit-content;
            width: -moz-fit-content;
            white-space: nowrap;
            padding: 15px;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: $very-dark-blue;
            font-family: Open Sans;
            display: none;
            background: #fff;
            border: 1px solid rgba(32, 81, 163, 0.05);
            box-shadow: 15px 15px 25px rgba(29, 30, 27, 0.05);
            border-radius: 10px;
            z-index: 10;

            &::before {
                content: "";
                border: solid transparent;
                position: absolute;
                top: 100%;
                left: calc(50% - 9px);
                border-top-color: #fff;
                border-width: 9px;
                margin-left: -1px;
            }
        }

       

        &:hover {
            border: 5px solid $major-color;

            .quantity-btn-tooltip {
                display: block;
            }
        }

        &.disabled {
            svg {
                path {
                    fill: $original-gray;
                }
            }
  
            &:hover {
              border: 1px solid rgba(155, 159, 179, 0.2);
  
              .quantity-btn-tooltip {
                  display: block;
              }
          }
        }

        
    }

    &.simple {
        .quantity-btn {
            border: 1px solid rgba(155, 159, 179, 0.2);
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            color: $original-blue;
            svg {
                fill: #0b1f35;
            }
            &:hover {
                border: 1px solid $original-blue;

                svg {
                    fill: $original-blue;
                }
            }

            &.disabled {
                svg {
                    path {
                        fill: $original-gray;
                    }
                }
      
                &:hover {
                  border: 1px solid rgba(155, 159, 179, 0.2);
      
                  .quantity-btn-tooltip {
                      display: block;
                  }
              }
            }
        }
    }

    .input-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
            border: none;
            border-radius: 4px;
            width: 100%;
            padding: 0 5px;
            margin: 0;
            height: 40px;
            color: $very-dark-blue;
            font-size: 13px;
            box-sizing: border-box;
            text-align: center;
            background-color: transparent;

            &:focus {
                outline: none;
            }
        }

        .measure {
            border-radius: 4px;
            padding: 0 5px 0 0;
            font-family: Open Sans;
            // font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: $original-gray;
            text-align: center;
            min-width: 22px;
            top: -1px;
            position: relative;
            // right: 15px;
            // position: absolute;
            // top: 10px;
        }
    }

    &.large {
        border-radius: 50px;
        min-height: 50px;
        width: 198px;
        .input-wrapper {
            input {
                text-align: right;
            }
        }
        .measure {
            padding: 0 25px 0 0;
        }
        .quantity-btn {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            min-width: 50px;
        }
    }

    @include phone {
        .quantity-btn {
            border-width: 2px;
            &:hover {
                border-width: 2px;
                .quantity-btn-tooltip {
                    display: none;
                }
            }
        }

        .input-wrapper {
            position: relative;
            input {
                height: 35px;
                padding-right: 30px;
                padding-left: 10px;
                margin: 0 10px;
                width: 100%;
                box-sizing: border-box;
                -webkit-appearance: none;
            }

            // .measure {
            //   top: 6px;
            // }
        }

        &.simple {
            .quantity-btn {
                &:hover {
                    color: $original-blue;
                    border: 1px solid #2051a3;
                    svg {
                        fill: $original-blue;
                    }
                }
            }
        }
    }
}
