@import "../../../common.scss";

// @-webkit-keyframes animatetop {
//     from {top: -25%; opacity:0}
//     to {top:0; opacity:1}
//   }

@keyframes animatetop {
  from {
    top: -5%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -5%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes animatetoptocenter {
  from {
    top: -5%;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(239, 239, 239, 0.4);
  backdrop-filter: blur(20px);
  padding-top: 5%;
  &.show {
    display: block;
  }

  h2 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
  }

  &.save-success {
    padding: 0;
  }
  .modal-content {
    position: relative;
    padding: 30px 30px 30px 40px;
    background-color: #fff;
    margin: auto;
    box-shadow: 50px 50px 50px rgba(26, 47, 82, 0.1);
    border-radius: 15px;
    width: 80%;
    // -webkit-animation-name: animatetop;
    // -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;

    &.save-success {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 440px;
      width: 100%;
      transform: translate(-50%, -50%);
      animation-name: animatetoptocenter;
    }
  }

  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    svg {
      fill: $original-gray;
      height: 24px;
      width: 24px;
    }
  }

  // .close:hover,
  // .close:focus {
  //     color: black;
  //     text-decoration: none;
  //     cursor: pointer;
  // }

  @include phone {
    padding-top: 0;
    backdrop-filter: blur(0px);
    .modal-content {
      &.max-size {
        width: 100%;
      }

      bottom: 0;
      // position: fixed;
      max-width: 329px;
      padding: 26px 26px 30px 20px;
      box-sizing: border-box;
      animation-name: animatebottom;
      border-radius: 0;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;

      &.save-success {
        min-width: 100%;
        bottom: 0;
        top: auto;
        left: 0;
        position: fixed;
        transform: none;
        padding: 30px 26px 35px 18px;
        box-sizing: border-box;
        animation-name: animatebottom;
        max-width: 331px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
      }
    }
  }
}
