@import "../../common.scss";

.profile-page {
  display: flex;
  flex-direction: column;

  .back-button {
    @extend .flex-centered;
    @extend %common-blue-link;
    padding: 30px 20px 5px;
    svg {
      margin-right: 11px;
    }
  }

  .profile-menu-bar {
    border-radius: 10px;
  }

  .profile-page-title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
    margin: 0 0 30px;
  }

  .profile-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .profile-sidebar {
      min-width: 270px;
      max-width: 270px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 0 30px 20px 0;
    }

    .profile-content {
      border-radius: 5px;
      width: 100%;
      padding-bottom: 90px;

      // h2 {
      //   font-size: 15px;
      //   line-height: 18px;
      //   letter-spacing: -0.084px;
      //   margin: 0;
      //   margin-bottom: 25px;
      //   &.second {
      //     margin-top: 30px;
      //   }
      // }

      .action-buttons {
        margin-top: 31px;
        .edit-buttons {
          display: flex;
          flex-direction: row;

          .cancel-button {
            @extend .flex-centered;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: $original-blue;
            margin-left: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }

  @include phone {
    &.container {
      padding: 0;
    }

    .back-button {
      font-size: 12px;
      line-height: 15px;
    }

    .profile-content {
      padding-bottom: 110px;
      display: flex;
      flex-direction: column;
      // padding-bottom: $mobile-header-height;
    }
    .profile-header {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      padding: 15px 20px 20px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f4f7fd;

      .exit-btn {
        font-family: Open Sans;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.084px;
        color: $original-gray;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          margin-left: 10px;
          fill: $original-gray;
        }
      }
    }

    .profile-layout {
      flex-direction: column;
      .profile-sidebar {
        margin: 0 0 20px;
        padding: 0 20px;
        width: 100%;
        min-width: auto;
        max-width: initial;
        box-sizing: border-box;

        .profile-menu-payment-delay {
          order: 2;
        }
        .profile-menu-bar {
          padding: 25px 20px;
          border-bottom: 2px solid $blue-gray;
          box-sizing: border-box;
          order: 1;
          border-radius: 10px;
        }
      }
    }
  }

  @include tablet {
    &.container {
      padding: 0;
    }

    .back-button {
      font-size: 12px;
      line-height: 15px;
    }

    .profile-content {
      padding-bottom: 110px;
      display: flex;
      flex-direction: column;
      // padding-bottom: $mobile-header-height;
    }
    .profile-header {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      padding: 15px 20px 20px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f4f7fd;

      .exit-btn {
        font-family: Open Sans;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.084px;
        color: $original-gray;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          margin-left: 10px;
          fill: $original-gray;
        }
      }
    }

    .profile-layout {
      flex-direction: column;
      .profile-sidebar {
        margin: 0 0 20px;
        padding: 0 20px;
        width: 100%;
        min-width: auto;
        max-width: initial;
        box-sizing: border-box;

        .profile-menu-payment-delay {
          order: 2;
        }
        .profile-menu-bar {
          padding: 25px 20px;
          border-bottom: 2px solid $blue-gray;
          box-sizing: border-box;
          order: 1;
          border-radius: 10px;
        }
      }
    }
  }

  @media (max-width: 1080px) {
    .profile-layout {
      flex-direction: column;
      .profile-sidebar {
        margin: 0 0 20px;
        width: 100%;
        min-width: auto;
        max-width: initial;

        .profile-menu-payment-delay {
          order: 2;
        }
        .profile-menu-bar {
          padding: 25px 20px;
          border-bottom: 2px solid $blue-gray;
          box-sizing: border-box;
          order: 1;
        }
      }
    }
  }
}
