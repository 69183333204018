$original-blue: #2051a3;
$original-blue-o2: rgba(
  $color: $original-blue,
  $alpha: 0.2,
);
$original-blue-o9: rgba(
  $color: $original-blue,
  $alpha: 0.9,
);
$original-blue-o005: rgba(
  $color: $original-blue,
  $alpha: 0.05,
);

$original-white: #fff;
$very-dark-blue: #0b1f35;
$original-gray: #9b9fb3;
$original-red: #dc282e;
$blue-gray: #e9eef6;
$gray-light: #d6d7e4;
$max-blue: #1f72f1;
$green: #1eb06a;
$green2: #27ae60;
$green-gray: #858f9a;
$original-red: #dc282e;
$orange: #f2994a;

$yellow: #f2c94c;
$blue3: #56ccf2;
$purlpe2: #bb6bd9;
$bg-color: #f4f7fd;
$new-bg-color: #f8f8fa;

$mobile-width: 768px;
$desktop-width: 1024px;
$header-container-width: 1140px;
$mobile-header-height: 139px;
$catalog-button-width: 128px;

$side-bar-width: 268px;
$side-bar-margin-right: 30px;

$business-color: $original-blue;
$private-color: $green;

$overlay-color: rgba(
  $color: $very-dark-blue,
  $alpha: 0.5,
);

@mixin phone {
  // @media (max-width: #{$mobile-width}), (min-width:  #{$mobile-width}) and (max-width: $desktop-width - 1px) and (orientation: landscape) {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin not-desktop {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

// @mixin phone {
//   @media (max-width: 599px) { @content; }
// }
// @mixin tablet {
//   @media (min-width: 600px) { @content; }
// }
// @mixin tablet-landscape {
//   @media (min-width: 900px) { @content; }
// }
// @mixin desktop {
//   @media (min-width: $max-tablet-width) { @content; }
// }

%common-white-link {
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: $original-white;
  cursor: pointer;
  &:hover,
  &:visited,
  &:active,
  &:focus {
    color: $original-white !important;
  }
}
%common-dark-link {
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: $very-dark-blue;
  cursor: pointer;
  &:hover,
  &.current,
  &:visited,
  &:active {
    color: $very-dark-blue !important;
  }
}

%common-green-link {
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: $green;
  cursor: pointer;
  &:hover,
  &.current,
  &:visited,
  &:active {
    color: $green !important;
  }
}

%common-gray-link {
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: #858f9a;
  cursor: pointer;
  &:hover,
  &.current {
    color: #dc282e;
  }
}

%common-blue-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  letter-spacing: -0.1px;
  color: $original-blue;
  cursor: pointer;
  &:hover,
  .current {
    color: #c82b31;
  }
}

.flex-centered {
  display: flex;
  align-items: center;
}

.header3 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: $very-dark-blue;
  margin: 0;
}

.subheader3 {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $original-gray;
}

.header4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.084px;
  color: $very-dark-blue;
}

.validation-text {
  color: #dc282e;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15px;
  padding-left: 17px;
  margin-top: 1px;
}

.font-montserrat {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.084px;
}
.empty-button {
  border: 1px solid $original-gray;
  color: $original-gray;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

$row-input-margin: 26px;
.row-line {
  display: flex;
  flex-direction: row;
  // margin-bottom: 25px;

  &.w100 {
    .Input,
    > div {
      width: 100%;
    }
  }
  &.w50 {
    &.single {
      .Input,
      .DatePicker {
        width: calc(50% - #{$row-input-margin/2});
      }
    }

    .Input,
    .DatePicker {
      width: 50%;
      &:first-child {
        margin-right: $row-input-margin;
      }
    }
  }
  &.w33 {
    .Input,
    .DatePicker {
      width: 33.3%;
      &:first-child,
      &:nth-child(2) {
        margin-right: $row-input-margin;
      }
    }
  }
  &.w25 {
    .Input,
    .DatePicker {
      width: 25%;
      margin-right: $row-input-margin;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.last {
    margin-bottom: 30px;
  }
}

.round-panel {
  background: rgba($color: $original-blue, $alpha: 0.05);
  border-radius: 10px;
  padding: 20px;
  h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.084px;
    margin: 0 0 10px;
  }
}
