@import "../../../../common.scss";

.delivery {
    background: rgba($color: $blue-gray, $alpha: 0.5);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .item {
        @extend .flex-centered;

        svg {
            margin-right: 18px;
        }
        .info {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
            .sub-info {
                font-size: 14px;
                line-height: 14px;
                color: $very-dark-blue;
                font-weight: 600;
            }
        }

        &:first-of-type {
            padding: 0 0 15px;
            border-bottom: 1px solid rgba(32, 81, 163, 0.1);
        }

        &:only-of-type {
            padding: 0;
            border-bottom: none;
        }
    }
}
