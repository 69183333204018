@import "../../../common.scss";

.icon-button {
    min-width: 50px;
    height: 50px;
    border-radius: 15px;
    border: 1px solid rgba(32, 81, 163, 0.1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;

    &:hover {
        svg {
            path {
                fill: $original-blue;
            }
        }
    }
}
