@import "../../../../common.scss";

$major-color: rgba(255, 255, 255, 0.8);

.item-edit {
  @extend .flex-centered;
  width: 161px;
  border: 1px solid rgba(#9b9fb3, 0.2);
  box-sizing: border-box;
  border-radius: 40px;
  .quantity-btn {
    border: none;
    border: 1px solid rgba(155, 159, 179, 0.2);
    background: transparent;
    color: $original-blue;
    box-sizing: border-box;
    min-width: 40px;
    min-height: 40px;
    border-radius: 40px;

    @extend .flex-centered;
    display: flex;
    justify-content: center;
    // margin: 0 5px;
    cursor: pointer;
    position: relative;
    svg {
      fill: #0b1f35;
    }
    .btn-tooltip {
      position: absolute;
      top: -55px;
      background: $blue-gray;
      padding: 8px 12px;
      width: 93px;
      border-radius: 5px;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: $very-dark-blue;
      font-family: Open Sans;
      display: none;
    }

    &:hover {
      border: 1px solid $original-blue;

      svg {
        fill: $original-blue;
      }

      .btn-tooltip {
        display: block;
      }
    }
  }

  .input-wrapper {
    position: relative;
    input {
      border: none;
      border-radius: 4px;
      width: 100%;
      padding: 0 5px;
      margin: 0;
      height: 32px;
      color: $very-dark-blue;
      font-size: 13px;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }

    .measure {
      border-radius: 4px;
      font-family: Open Sans;
      // font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $original-gray;
      text-align: center;
      width: 22px;
      // right: 15px;
      // position: absolute;
      // top: 5px;
    }
  }

  @include phone {
    .quantity-btn {
      // border-width: 2px;
      &:hover {
        // border-width: 2px;
        .btn-tooltip {
          display: none;
        }
      }
    }

    .input-wrapper {
      position: relative;
      input {
        height: 35px;
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        -webkit-appearance: none;
      }

      // .measure {
      //   top: 6px;
      // }
    }

    &.simple {
      .quantity-btn {
        &:hover {
          border: 1px solid $original-blue;

          svg {
            fill: $original-blue;
          }
        }
      }
    }
  }
}
