@import "../../../common.scss";

.orders-page {
  flex-direction: column;

  .main {
    width: 754px;
  }

  > .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 350px;

    h3 {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      letter-spacing: -0.084px;
      margin-bottom: 20px;
    }

    .icon {
      max-width: 320px;
      width: 80%;
      padding-top: 40px;
      img {
        height: 100%;
        width: 320px;
      }
    }

    .sub-header {
      font-family: Open Sans;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.084px;
      width: 408px;
      margin-bottom: 30px;
      color: $original-gray;
    }

    @include phone {
      padding-bottom: 0;
      padding-top: 0;
      .icon {
        margin-top: 95px;
        display: flex;
        justify-content: center;
        img {
          width: 275px;
        }
      }

      h3 {
        font-size: 14px;
      }
      .sub-header {
        font-size: 13px;
      }
    }
  }
}
