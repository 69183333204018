.loader,
.loader:after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.loader {
    //margin: 60px auto;
    font-size: 5px;
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    text-indent: -9999em;

    //border-top: 1.1em solid rgba(255, 255, 255, 0.6);
    //border-right: 1.1em solid rgba(255, 255, 255, 0.6);
    //border-bottom: 1.1em solid rgba(255, 255, 255, 0.6);
    border-left: 1.1em solid rgba(32, 81, 163, 1.0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
