@import "../../../common.scss";

.summary {
    width: 100%;
    border-radius: 10px;
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
    font-size: 12px;
    box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);
    .info {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: $very-dark-blue;

        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .quantity {
            padding: 5px 10px;
            box-sizing: border-box;

            background: rgba(32, 81, 163, 0.05);
            border-radius: 5px;
        }
        .weight {
            @extend .flex-centered;
            svg {
                margin: 5px;
            }
        }
    }
    .summary-divider {
        width: 100%;
        height: 1px;
        background-color: rgba(87, 125, 187, 0.1);
    }

    .delivery-info {
        padding: 15px;
        background: rgba(32, 81, 163, 0.05);
        border-radius: 5px;
        margin-bottom: 20px;
        .line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            color: $original-gray;
            &:first-child {
                margin-bottom: 15px;
            }
            .value {
                font-family: Montserrat;
                font-size: 14px;
                line-height: 17px;
                color: $original-blue;
            }
        }

        .price {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            color: $original-gray;
        }

        .free-info {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
            margin-bottom: 5px;
            .bold {
                font-weight: 600;
            }
        }
        .price-info {
            display: flex;
            align-items: center;
            flex-grow: 1;
            width: 100%;
            .delimeter {
                margin-right: 10px;
                flex: 1 1;
                border-radius: 5px;
                .inner {
                    display: flex;
                    border-radius: 5px;
                    height: 3px;
                    background-color: rgba($color: $original-blue, $alpha: 0.1);
                    .filled {
                        background-color: $original-blue;
                        height: 3px;
                        width: 50%;
                        border-radius: 5px;
                    }
                }

                &.full {
                    background-color: $green;
                    height: 3px;
                }
            }
        }
    }

    .sum-info-wrap {
        display: flex;
        flex-direction: column;
        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 8px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;

            letter-spacing: -0.084px;
            .caption {
                @extend .flex-centered;

                color: $very-dark-blue;
                svg {
                    margin-right: 8px;
                }
            }
            .label {
                font-family: Montserrat;
                font-size: 13px;
                line-height: 16px;
                color: $very-dark-blue;
                // &.free {
                //   color: $green;
                // }
            }
        }

        .sum-info-discount {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 8px;

            > span {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.084px;
                color: $very-dark-blue;

                &:last-of-type {
                  color: #ED5055
                }
            }
        }
    }

    .sum-info {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        font-family: Open Sans;
        line-height: 150%;

        .caption {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
        }
        .sub {
            font-size: 12px;
            color: $original-gray;
            width: 150px;
        }
        .total {
            .product-price {
                font-size: 18px;
                line-height: 22px;
                color: $very-dark-blue;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: center;
    }

    .cart-submit-btn {
        display: flex;
        flex-direction: column;
        .error-box {
            margin-top: 20px;
        }
    }

    @include not-desktop {
        padding: 20px;
        .sum-info {
            padding-bottom: 0;
        }

        .actions {
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 20px;
            width: calc(100% - 40px);
            background-color: #fff;
            box-shadow: 5px -20px 20px rgba(26, 49, 92, 0.05);
            border-radius: 15px 15px 0px 0px;
            transition: all 0.25s ease;

            .round-button {
                font-size: 12px;
                line-height: 16px;
                padding: 14px 0;
            }
        }
    }
}
