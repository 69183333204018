@import "../../common.scss";

.helpQARoot {
  padding-top: 30px;
  padding-bottom: 40px;

  .container {
    flex-direction: column;
  }

  .container .back-button a {
    font-size: 14px;
  }

  .container .back-button {
    padding: 20px 0 31px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2px;
  }

  &__navigation {
    max-width: 270px;
    width: 100%;
    margin-right: 30px;
    background: $original-white;
    box-shadow: 5px 20px 20px rgba(26, 49, 92, 0.03);
    padding: 30px 20px;
    box-sizing: border-box;
  }

  &__item-content {
    width: 100%;
    list-style: none;
    background: $original-white;
    box-shadow: 5px 20px 20px rgba(26, 49, 92, 0.03);
    padding: 40px 40px 40px;
    margin: 0;
    border-radius: 5px;
    overflow: scroll;

    h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      margin-top: 0;
      margin-bottom: 20px;
    }

    &.list {
      display: flex;
      flex-direction: column;
      list-style: disc;
      padding: 40px 40px 40px 60px;

      li {
        margin: 0 0 15px;
        &:last-of-type {
          margin: 0;
        }

        a {
          margin: 0 5px;
          cursor: pointer;
          color: $original-blue;
        }
      }

      h2 {
        margin-left: -20px;
      }
    }
  }

  &__item-content-link {
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.084px;
    color: #0b1f35;
  }

  &__navigation-block {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & > p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      font-family: Montserrat;
      position: relative;
      margin-top: 0;
      margin-bottom: 20px;
      cursor: pointer;
    }

    & > p svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &--mobile {
    .container {
      padding: 30px 20px;
      background-color: #fff;
      box-shadow: 5px 20px 20px rgba(26, 49, 92, 0.03);
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      min-width: initial;
    }

    .back-button {
      padding: 0 0 0 2px;

      a {
        font-size: 14px;
      }

      a svg {
        margin-right: 9px;
      }
    }

    &__item-content h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.084px;
      color: #0b1f35;
      margin-bottom: 20px;
      margin-top: 23px;
    }
  }

  &__title-mobile {
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.084px;
    color: #0b1f35;
    margin: 30px auto 20px;
    display: flex;
    max-width: 1140px;
  }

  &__answer {
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
  }

  &__answer-text {
    margin: 1px 0 0;

    &_answer-ancor,
    >a {
      margin: 0 5px;
      cursor: pointer;
      color: $original-blue;
    }
  }

  &__answer-link {
    color: $original-blue;
    &.phone {
      font-weight: bold;
      white-space: nowrap;
      margin: 0 0 0 5px;
    }

    &.underline {
      text-decoration: underline;
  }
  }
  &__answer-title {
    margin: 0 0 5px;
    font-weight: 600;
    font-size: 14.3px;
    line-height: 140%;
    letter-spacing: -0.084px;
    display: flex;
    align-items: end;
    
    a {
      margin: 0 5px;
    }
   
  }

  &__answer-logo {
    width: 30px;
    margin: 0 -3px 0 0;
  }

  &__answer-phone {
    white-space: nowrap;
  }
  &__answer-list {
    margin: 0;
    padding: 0 20px;
    list-style: disc;
    a {
      cursor: pointer;
      color: $original-blue;
    }

    &.numbers {
      list-style: decimal;
    }

    &.none-list-style {
      list-style: none;
    }
  }
  &__answer-item {
    margin: 0 0 15px;
    &:last-of-type {
      margin: 0;
    }
  }

  &__answer-imgs {
  display: flex;
  gap: 5px;
  
  }

  &__answer-img {
    margin: 0 0 30px;
    max-width: 100%;
    width: 100%;
    border-radius: 15px;

    &.half-width {
      max-width: 50%;
    }
    &.seventy-width {
      max-width: 70%;
      margin: 0;
    }
  }
  &__answer-iframe {
    border-radius: 15px;
  }
  &__answer-accent {
    font-weight: bold;
  }

  &__answer-contacts {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  &__answer-contact-icon {
    margin: 0 10px 0 0;
    @extend .flex-centered;
    justify-content: center;
    width: 60px;
    height: 60px;
    min-width: 60px;
    border: 1px solid rgba($color: $original-blue, $alpha: 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    line-height: 15px;
    color: $original-blue;

    &:last-of-type {
      margin: 0;
    }
  }

  &__answer-table-container {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    overflow: scroll;
  }

  &__answer-table {
    min-width: 100%;
    border: 1px solid $original-gray;
    border-spacing: 0;
    border-collapse: collapse;
    box-sizing: border-box;
  
  }
  &__answer-table-cell {
    border: 1px solid $original-gray;
    max-width: 80px;
    min-width: 80px;
    padding: 10px;
    box-sizing: border-box;

    &:first-of-type {
      max-width: 180px;
      min-width: 180px;
    }
  }
}
