@import "../../common.scss";
.supplier-landing {
    background: #fff;
    width: 100%;

    .supplier-landing-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: $very-dark-blue;
        margin: 0 0 25px;

        &.white {
          color: #fff;
        }
    }

    @media (max-width: 950px) {
        .supplier-landing-title {
            margin: 0 0 20px;
        }
    }
}
