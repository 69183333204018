@import "./common.scss";
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&subset=cyrillic");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F8F8FA !important;
    font-family: "Open Sans";
    word-break: break-word;
    margin: 0;

    .App {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    main {
        width: 100%;
        min-height: 200px;
    }

    .container {
        margin: 0 auto;
        display: flex;
        // min-width: $header-container-width;
        width: 100%;
        max-width: $header-container-width;
        box-sizing: border-box;
        h1 {
            font-weight: 600 !important;
            font-size: 24px;
            line-height: 20px;
            letter-spacing: -0.084px;
            margin-bottom: 40px;
        }

        .page-header {
            min-width: 945px;
            max-width: 945px;
            flex: 1;
            margin-left: auto;
        }
    }

    @include tablet {
        .container {
            padding: 0 20px;
            min-width: auto;
            max-width: 100%;
        }
    }

    @include phone {
        min-width: auto;
        max-width: 100%;
        .container {
            padding: 0 20px;
        }

        main {
            min-width: 0;
            max-width: 100%;
        }
    }
}

a:hover {
    color: #2051a3; // !important;
}

a {
    text-decoration: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// .disabled {
//   opacity: 0.3;
//   cursor: not-allowed;
// }

h1 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.084px;
}

.top-btn {
    display: flex;
    width: 170px;
    border-radius: 20px;
    height: 40px;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-left: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    color: #2051a3;
}

table {
    background: #fff;
    font-family: "Open Sans";

    th {
        background: #fff !important;
    }
}

.ScrollbarsCustom-Track {
    width: 5px !important;
    background: #fff !important;

    .ScrollbarsCustom-Thumb {
        background: #2051a3 !important;
    }
}

.b24-widget-button-wrapper {
    display: none !important;

    &.home-page {
        display: block !important;
    }
}
