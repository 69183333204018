@import "../../../common.scss";

.footer {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 20px 0;
    box-sizing: border-box;
    background: #181f29;
    margin-top: auto;

    > .container {
        flex-direction: column;
        box-sizing: border-box;
    }

    .block {
        padding: 0 0 20px;
        color: #fff;
        &.line {
            border-bottom: rgba($color: #fff, $alpha: 0.1) solid 1px;
        }
        &.rows {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .rows-content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        &.second {
            padding: 25px 0 30px 0;
            align-items: center;
        }

        .left-block {
            display: flex;
            padding: 0 0 20px;
            box-sizing: border-box;

            .left-block-menu {
                display: flex;
            }
        }

        .right-block {
            width: 280px;
        }

        h3 {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin: 0 0 20px;
        }

        a {
            font-family: Open Sans;
            font-size: 13px;
            line-height: 15px;
            color: rgba($color: #fff, $alpha: 0.69);
            &:hover,
            .current {
                color: #fff;
            }
        }
        .logos {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;

            .logo {
                &:first-of-type {
                    > img {
                      width: 120px;
                    }
                    margin: 0 0 65px;
                }
            }
        }

        .phone-info {
            font-family: Open Sans;
            font-size: 12px;
            line-height: 15px;
            color: #fff;
            padding-bottom: 10px;
            a {
                font-family: Montserrat;
                font-weight: 600;
                font-size: 15px;
                line-height: 15px;
                color: #fff;
                padding-bottom: 7px;
                display: block;
            }
        }

        .row1 {
            padding-right: 125px;
        }
        .row2 {
            padding-right: 105px;
            flex-direction: column;
            display: flex;
            a {
                margin: 0 0 15px;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
    .address {
        max-width: 280px;
        width: 100%;
        color: rgba($color: #fff, $alpha: 0.5);
    }
    .address,
    .copyrights {
        font-family: Open Sans;
        font-size: 11px;
        line-height: 180%;
        padding-bottom: 0;
        margin: 0;
    }
    .copyrights {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #fff;
        div {
            &:first-of-type {
                // width: 202px;
                // padding-right: 96px;
            }
            &:last-of-type {
                // width: 350px;
            }
        }
    }

    .social {
        a {
            padding-right: 15px;
        }
    }

    .policy {
        // padding-top: 30px;
        a {
            margin: 0 20px 0 0;
            font-family: Open Sans;
            font-size: 12px;
            line-height: 180%;
            color: rgba($color: #fff, $alpha: 0.5);
            &:hover,
            .current {
                color: #fff;
            }

            &:last-child {
                padding: 0;
            }
        }
    }

    .footer-credit-cards {
        display: flex;
        .credit-card-logo {
            height: 16px;
            margin: 0 15px 0 0;
            svg {
                height: 16px;
                path {
                    &#letters {
                        fill: #fff;
                    }
                }
            }
            &:last-of-type {
                margin: 0;
            }

            &.visa {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }

    .footer-messenger-icons {
        margin: 0 0 20px;
        display: flex;

        .footer-messenger-icon {
            margin: 0 10px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border: 1px solid rgba(255, 255, 255, 0.05);
            box-sizing: border-box;
            border-radius: 10px;

            svg {
                width: 20px;
                height: 20px;
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }

    @include phone {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        .left-block {
            flex-direction: column;
        }

        .block {
            padding-bottom: 0;
            .right-block {
                border-top: rgba($color: #fff, $alpha: 0.1) solid 1px;
                padding: 20px 0;
                width: 100%;
            }
            .logos {
                padding-bottom: 20px;
                .logo {
                    &:first-of-type {
                        margin: 0 0 20px;
                    }
                }
            }
            .row1 {
                padding-bottom: 30px;
            }
            .row2 {
                padding-bottom: 10px;
            }
            &.rows {
                flex-direction: column;

                .rows-content {
                    flex-direction: column;
                }
            }

            .copyrights {
                flex-direction: column;
                padding-bottom: 0;
            }

            &.second {
                padding-bottom: 0;
                align-items: flex-start;
                .address {
                    padding-bottom: 20px;
                }
            }
        }

        .policy {
            display: flex;
            flex-direction: column;
            a {
                padding: 0 0 20px;
            }
        }

        .copyrights {
            flex-direction: column;
            div {
                &:last-of-type {
                    width: fit-content;
                }
            }
        }

        .footer-credit-cards {
            margin: 0 0 20px;
        }
    }

    @include tablet {
        padding: 30px 0;
        display: flex;
        flex-direction: column;

        .left-block {
            justify-content: space-between;
            flex-direction: row;
        }

        .block {
            padding-bottom: 0;

            .right-block {
                border-top: rgba($color: #fff, $alpha: 0.1) solid 1px;
                padding: 20px 0 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .right-block-content {
                    display: flex;
                    flex-direction: column;
                }
                .address {
                    max-width: 340px;
                    width: 100%;
                    padding-bottom: 20px;
                }

                .phone-info {
                    padding-bottom: 10px;
                }
            }
            .logos {
                padding-bottom: 10px;

                .logo {
                    > svg {
                        width: 130px;
                    }

                    &.skolkovo {
                        > svg {
                            width: auto;
                        }
                    }
                }
            }

            .row1 {
                padding: 0 100px 25px 0;
            }
            .row2 {
                padding: 0 100px 10px 0;
                &:last-of-type {
                    padding: 0 0 10px;
                }
            }
            &.rows {
                flex-direction: column;

                .rows-content {
                    flex-direction: column;
                }
            }

            .copyrights {
                flex-direction: column;
                padding-bottom: 0;
            }

            &.second {
                padding: 20px 0 0;
                align-items: flex-start;
            }
        }

        .policy {
            margin: 0 20px 0 0;
            display: flex;
            flex-direction: column;
            a {
                padding: 0 0 20px;
                &:last-of-type {
                    padding: 0 0 30px;
                }
            }
        }

        .copyrights {
            flex-direction: column;
            div {
                &:last-of-type {
                    width: fit-content;
                }
            }
        }

        .footer-credit-cards {
            margin: 0 0 20px;
        }
    }

    @media (max-width: 440px) {
        .left-block {
            flex-direction: column;
            .left-block-menu {
                display: flex;
                flex-direction: column;
            }
        }
        .block {
            .right-block {
                width: 100%;
                padding: 20px 0;
            }
            .logos {
                padding-bottom: 0;
            }
            .row2 {
                padding-bottom: 30px;

                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }

        .footer-credit-cards {
            margin: 0 0 20px;
        }
    }
}
