@import "../../../common.scss";

.error-box {
    background: #ffffff;
    border-radius: 10px;
    padding: 40px;
    color: $very-dark-blue;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 15px 20px;
    background: rgba(237, 80, 85, 0.1);
    border-radius: 10px;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.084px;
    min-height: 0;
    -webkit-transition: min-height 1s ease-in-out;
    -moz-transition: min-height 1s ease-in-out;
    -ms-transition: min-height 1s ease-in-out;
    -o-transition: min-height 1s ease-in-out;
    transition: min-height 1s ease-in-out;
    box-sizing: border-box;
    &.visible {
        min-height: 54px;
    }

    .error-box-info {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #0b1f35;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .error-box-title {
            font-size: 14px;
        }
    }

    svg {
        width: 20px;
        height: 20px;
        path {
            fill: $very-dark-blue;
        }
    }
}
