@import '../../../../common.scss';

.item-status {
    background: rgba(155, 159, 179, 0.1);
    border-radius: 5px;
    padding: 8px 10px;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: $original-gray;
    @extend .flex-centered;
    width: fit-content;

    svg {
        margin-right: 8px;
    }

    &.NotApproved {
        svg {
            fill: $yellow;
        }
    }

    &.Waiting {
        svg {
            fill: $blue3;
        }
    }

    &.WaitingDelivery {
        svg {
            fill: $original-blue;
        }
    }
    &.Delivery {
        svg {
            fill: $original-gray;
        }
    } 
}