.notifications {
  position: relative;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  font-family: "Open Sans";
  font-style: normal;
  z-index: 3;

  &__item {
    padding: 15px 0 15px 15px;
    box-sizing: border-box;
    border-top: 1px solid #eeeff1;
    text-align: left;
    cursor: pointer;
  }

  &__item-header {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 5px;
  }

  &__item-indicator-wrap {
    position: absolute;
    top: 4px;
    left: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 38px;
    cursor: pointer;

    &:hover {
      background-color: rgba(32, 81, 163, 0.05);
    }
  }
  &__item-indicator {
    position: relative;
    width: 5px;
    height: 5px;
    background: #2051a3;
    border-radius: 38px;
  }

  &__item-title {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    color: #0b1f35;
  }

  &__item-order {
    color: #2051a3;
    margin: 0 5px 0 0;
  }

  &__item-text {
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 16px;
    color: #9b9fb3;
  }

  &__item-paydate {
    font-size: 12px;
    line-height: 16px;
    color: #0b1f35;
    &::before {
      content: "-";
      color: #9b9fb3;
      padding: 0 4px;
    }
  }

  &__item-date {
    margin: 0;
    font-size: 10px;
    line-height: 14px;
    color: #9b9fb3;
  }

  &__item-header-button {
    position: fixed;
    z-index: 10;
  }

  &__item-link {
    text-decoration: none;
  }
  &__button-wrap {
    padding: 0 0 35px;
    display: flex;
    justify-content: center;
  }
}


@media (max-width: 375px) {
  .Notifications {


    &__item {
     &:first-of-type{
      border-top:none;
     }


    }
}
}
