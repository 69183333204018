@import "../../../../common.scss";

.add-my-company {
    .validation-msg {
        @extend .validation-text;
    }
    .error-message-accent {
        font-weight: bold;
    }

    .dialog-modal .modal-content {
        .drop-down {
            > .Input .input-error {
                bottom: -28px;
                line-height: 12px;
            }
        }
    }
}
