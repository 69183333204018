@import "../../../common.scss";

.about {
    margin-top: 40px;
    padding: 0 20px 40px;
    overflow: hidden;

    h2 {
        margin: 0 0 30px;
    }

    .about-area {
        flex-direction: column;
    }

    .about-info {
        display: flex;
        gap: 20px;
        color: $very-dark-blue;
        padding-bottom: 40px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
        }

        .info-item {
            min-width: 366px;
            // height: 274px;
            display: flex;
            flex-direction: column;
            padding: 30px 25px;
            border: 1px solid rgba(32, 81, 163, 0.05);
            box-sizing: border-box;
            border-radius: 15px;
            background-color: #fff;

            .info-icon {
                width: 50px;
                height: 50px;
                padding: 13px;
                box-sizing: border-box;
                background: $original-blue-o005;
                border-radius: 20px;

                &.green {
                    background: rgba($color: $green, $alpha: 0.05);
                }

                &.maxblue {
                    background: rgba($color: $max-blue, $alpha: 0.05);
                }
            }

            h3 {
                font-family: Montserrat;
                margin: 0;
                padding: 20px 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.084px;
            }

            .desc {
                font-family: Open Sans;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.084px;
                color: $original-gray;
            }
        }
    }

    .faq {
        .faq-list {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .faq-title {
            margin: 0 0 30px;
        }
    }

    @include phone {
        padding: 0 20px 30px;
        margin: 30px 0 0;
        h2 {
            font-size: 18px;
            line-height: 22px;
            padding-left: 0;
            margin: 0 0 20px;
        }
        .about-info {
            gap: 10px;
            padding-bottom: 30px;

            .info-item {
                min-width: 310px;

                h3 {
                    font-size: 14px;
                }
            }
        }

        .faq {
            .faq-title {
                margin: 0 0 20px;
            }
        }
    }
    @include tablet {
        padding: 0 0 30px;
        margin: 30px 0 0;
        h2 {
            font-size: 18px;
            line-height: 22px;
            padding-left: 0;
            margin: 0 0 20px;
        }
        .about-info {
            gap: 10px;
            padding-bottom: 20px;

            .info-item {
                min-width: 310px;

                h3 {
                    font-size: 14px;
                }
            }
        }

        .faq {
            .faq-title {
                margin: 0 0 20px;
            }
        }
    }
}
