@import "../../../common.scss";

.verification-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 40px;
  color: $very-dark-blue;
  .verification-box-inner {
    border: 1px solid rgba(87, 125, 187, 0.1);
    border-radius: 10px;
    padding: 20px;
    .verification-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 15px;
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      letter-spacing: -0.084px;

      .icon {
        border-radius: 50%;
        padding: 8px;
        background: rgba($color: $original-blue, $alpha: 0.1);
        margin-right: 10px;
        display: flex;
      }
    }

    .verification-text {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
