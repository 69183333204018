@import "../../../../common.scss";

.product-supplier {
  padding: 20px 25px;
  background: #ffffff;
  border-radius: 10px;
  color: $very-dark-blue;

  .avatar {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    object-fit: cover;
    color: #fff;

    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Open Sans;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
    }
  }

  h4 {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.084px;
    margin: 10px 0;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .favorites-button {
      width: auto;
      height: auto;
    }
  }

  .part-bottom {
    border-top: 1px solid rgba(32, 81, 163, 0.1);
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .products-count {
      display: flex;
      flex-direction: row;
      svg {
        padding: 11px;
        border: 1px solid rgba(32, 81, 163, 0.1);
        border-radius: 50%;
      }
      .count-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        justify-content: center;
        letter-spacing: -0.084px;

        .count {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $very-dark-blue;
        }
        .label {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: $original-gray;
        }
      }
    }

    .rating {
      border-radius: 25px;
      padding: 10px 15px;
      border: 1px solid rgba(32, 81, 163, 0.1);
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;

      letter-spacing: -0.084px;
      color: $very-dark-blue;

      svg {
        width: 15px;
        margin-right: 5px;
      }
    }
  };

  @include phone {
    margin: 40px 0 0;
  };

  @include tablet {
    margin: 40px 0 0;
  };
}
