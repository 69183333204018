@import "../../../common.scss";

.cart-submit-btn {
  .error-box {
    margin-top: 14px;
  }
}

.disclaimer {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $original-gray;
  padding-top: 10px;
  a {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    @extend %common-blue-link;
  }
}
