@import "../../../common.scss";

.address-list-item {
    position: relative;
    max-width: 225px;
    min-width: 225px;
    height: 156px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: $very-dark-blue;
    border: 1px solid rgba(32, 81, 163, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    &.active {
        border: 1px solid $original-blue;
    };

    &:hover {
        border: 1px solid $original-blue;
    };

    &.add {
        background: rgba(32, 81, 163, 0.05);
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.084px;
        color: $original-blue;
        justify-content: center;

        span {
            font-size: 24px;
            font-weight: normal;
        }
    }

    .address-list-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 5px;

        h4 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
            margin: 0;
        }

        .address-list-item-edit {
            font-size: 20px;
            color: $original-gray;
            line-height: 2px;
            padding: 0 0 10px;
        }

        .address-list-item-edit-options {
            position: absolute;
            top: 35px;
            right: 10px;
            padding: 15px;
            box-sizing: border-box;
            background: #fff;
            box-shadow: 15px 0px 35px rgba(99, 99, 99, 0.2);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            list-style: none;

            .address-list-item-edit-option {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.084px;
                color: $original-blue;

                &:last-of-type {
                    font-weight: 400;
                    color: $original-gray;
                }
            }
        }
    }
    .address-list-item-street {
        font-size: 14px;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
        margin: 0 0 5px;
    }

    .address-list-item-entrance {
        font-weight: 600;
        margin: 0 ;
    }

    .address-list-item-contacts {
        color: $original-gray;
        margin: 0;
        padding: 5px 0 0;
        border-top: 1px solid rgba($original-gray, 0.05);
    }

    @include phone {
        max-width: 100%;
        height: 135px;

        &.add {
            height: 70px;
        }
    }
}
