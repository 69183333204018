@import "../../../../common.scss";

.category-tags {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;

  .category-tag {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: $very-dark-blue;
    text-align: center;
    border: 1px solid rgba(32, 81, 163, 0.1);
    border-radius: 25px;
    padding: 15px 20px;
    margin-right: 10px;
    min-width: fit-content;
    margin-bottom: 10px;
    &.active {
      border: $original-blue;
      background: $original-blue;
      color: #fff;
    }
  }
}
