@import "../../../common.scss";

.subsc-product-button {
    position: relative;

    &.no-title {
        .round-buttons {
            width: 40px;
            height: 40px;
            padding: 0;

            svg {
                margin: 0;
            }
        }

        .subsc-product-button-hint {
            left: -80px;
        }

        .loader {
            left: calc(50% - 11px);
        }
    }

    .loader,
    .loader:after {
        width: 20px;
        height: 20px;
    }
    .loader {
        font-size: 3px;
        position: absolute;
        left: calc(50% - 10px);
        top: 13px;
        border-left: 1.1em solid rgba(32, 81, 163, 1);
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    &:hover {
        .subsc-product-button-hint {
            display: block;
        }
    }

    .subsc-product-button-hint {
        position: absolute;
        top: -85px;
        left: auto;
        width: fit-content;
        width: -moz-fit-content;
        width: 170px;
        padding: 15px;
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        color: $very-dark-blue;
        font-family: Open Sans;
        display: none;
        background: #fff;
        border: 1px solid rgba(32, 81, 163, 0.05);
        box-shadow: 15px 15px 25px rgba(29, 30, 27, 0.05);
        border-radius: 10px;
        z-index: 10;

        &::before {
            content: "";
            border: solid transparent;
            position: absolute;
            top: 100%;
            left: calc(50% - 9px);
            border-top-color: #fff;
            border-width: 9px;
            margin-left: -1px;
        }
    }
}
