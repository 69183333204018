@import "/../../../../../common.scss";

.order-list-item {
    max-width: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;

    .order-list-item-content {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 0 15px;
    }

    .order-list-item-info-wrap {
        display: flex;
        gap: 30px;
    }

    .order-list-item-info {
        display: flex;
        flex-direction: column;

        .order-list-item-number {
            margin: 0 0 2px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
        }
        .order-list-item-delivery {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $original-gray;
        }
    }

    .order-list-item-status {
      min-width: 170px;
    }

    .order-list-item-sum-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .order-list-item-sum {
            margin: 0 0 2px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
        }
        .order-list-item-payment-status {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $original-blue;

            &.paid {
                color: $original-gray;
            }
        }
    }

    .order-list-item-actions {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .order-list-item-pics {
        max-width: 60%;
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
        }
        display: flex;
        align-items: center;
        gap: 10px;

        .order-list-item-pics-list {
            display: flex;
            gap: 10px;
            list-style: none;
            margin: 0;
            padding: 0;

            .order-list-item-pic {
                width: 38px;
                height: 38px;
                border-radius: 5px;
            }
        }

        .order-list-item-pics-more {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $original-gray;
            cursor: pointer;
        }
    }
    .order-list-item-button {
        position: relative;

        .loader,
        .loader:after {
            width: 18px;
            height: 18px;
            border-left: 2px solid rgba(32, 81, 163, 1);
        }
        .loader {
            font-size: 2px;
            position: absolute;
            left: calc(50% - 10px);
            top: calc(50% - 10px);
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;

            border-right: 2px solid #fff;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
        }
    }

    @include phone {
        padding: 15px;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        .order-list-item-content {
            max-width: 50%;
            flex-direction: column;
            gap: 20px;
            margin: 0;
        }
        .order-list-item-info-wrap {
            overflow: visible;
            flex-direction: column;
            gap: 12px;
        }

        .order-list-item-info {
            .order-list-item-number {
                font-size: 15px;
                white-space: nowrap;
            }

            .order-list-item-delivery {
                white-space: nowrap;
            }
        }

        .order-list-item-actions {
            width: auto;
        }

        .order-list-item-pics {
            max-width: 100%;
            width: auto;

            .order-list-item-pics-list {
                .order-list-item-pic {
                    width: 32px;
                    height: 32px;
                }
            }

            .order-list-item-pics-more {
                font-size: 12px;
            }
        }

        .order-list-item-sum-wrap {
            align-items: flex-start;
        }
    }
}
