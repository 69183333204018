@import "../../../common.scss";

.mini-cart {
  box-shadow: -5px -20px 20px rgba(26, 49, 92, 0.05), 5px 20px 20px rgba(26, 49, 92, 0.05);
  border-radius: 15px;
  width: 400px;
  background: #fff;
  display: none;
  position: absolute;
  z-index: 2;
  top: 58px;
  right: -24px;
  //   padding: 0 10px;
  box-sizing: border-box;

  //   left: -75px;

  //   //TODO: refactor
  //   @media (max-width: 1300px) {
  //     right: -40px;
  //   }

  &.visible {
    display: block;
  }

  .cart-items {
    width: 100%;
    .ScrollbarsCustom-Track,
    .ScrollbarsCustom-Thumb {
      display: none;
    }

    &:hover {
      .ScrollbarsCustom-Track,
      .ScrollbarsCustom-Thumb {
        display: none;
      }
    }
  }

  .bottom-panel {
    margin: 20px 30px;
    > .mini-cart-summary {
      margin-bottom: 10px;
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      display: flex;
      justify-content: space-between;
    }
  }
}
