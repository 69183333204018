@import "../../../common.scss";

.organisation {
  background: #fff;
  padding: 40px;

  .organisation-header {
    margin: 0 0 25px;
    display: flex;
    align-items: center;
    .organisation-title {
      margin: 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Open Sans;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.084px;
    color: $original-gray;
    margin-bottom: 20px;
    max-width: 610px;

    .description-icon {
      svg {
        margin-right: 16px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .action-buttons {
    &.active {
      margin-top: 10px;
    }
  }

  // .company-type {
  //   margin-bottom: 25px;
  //   .caption {
  //     font-family: Open Sans;
  //     font-weight: 600;
  //     font-size: 12px;
  //     line-height: 15px;
  //     color: $original-gray;
  //     margin-bottom: 10px;
  //   }
  //   .select-box {
  //     display: flex;
  //     flex-direction: row;
  //     .select-item {
  //       @extend .flex-centered;
  //       background: rgba(32, 81, 163, 0.05);
  //       padding: 10px 15px;
  //       border-radius: 5px;
  //       cursor: pointer;
  //       &:first-child {
  //         margin-right: 26px;
  //       }

  //       .select-box {
  //         border: 2px solid rgba(193, 207, 229, 0.5);
  //         box-sizing: border-box;
  //         border-radius: 19px;
  //         margin-right: 10px;
  //         width: 22px;
  //         height: 22px;
  //         display: inline-block;

  //         .bg {
  //           background-color: $original-blue;
  //           width: 100%;
  //           height: 100%;
  //           border-radius: 50%;
  //           display: flex;
  //           justify-content: center;
  //           align-items: center;
  //         }
  //         svg {
  //           width: 12px;
  //           height: 12px;
  //           fill: #fff;
  //         }
  //       }

  //       span {
  //         font-family: Open Sans;
  //         font-weight: 600;
  //         font-size: 12px;
  //         line-height: 15px;
  //         color: $original-blue;
  //       }
  //     }
  //   }
  // }
  .saved-label {
    font-size: 10px;
    line-height: 14px;
    color: $green;
    margin-left: 20px;
    display: inline-flex;
    align-items: center;

    svg {
      width: 12px;
      height: 12px;
      margin-right: 9px;
      fill: $green;
    }
  }

  .row-line {
    display: flex;
    flex-direction: row;
    // margin-bottom: 25px;

    &.w100 {
      > .Input,
      .edit-wrapper,
      .hint-input-wrap {
        width: 100%;
        
      }
    }
    &.w50 {
      
      .hint-input-wrap,
      .edit-wrapper {
        width: 50%;
        &:first-child {
          margin-right: 26px;
        }
        >.Input {
          width: 100%;
        }
        
      }
    }
    &.last {
      // padding-bottom: 30px;
    }

    .edit-wrapper {
      .fillable-dropdown-wrap,
      .hint-input-wrap {
        margin-bottom: 25px;
        width: 100%;
        >.Input {
          width: 100%;
        }
      }
    }
  }

  .row-line-title {
    font-family: Montserrat;
    margin: 5px 0 25px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.084px;
  }

  .attention {
    font-family: Open Sans;
    font-size: 13px;
    line-height: 160%;
    padding-right: 20px;
    letter-spacing: -0.084px;
    color: $original-gray;

    &.last {
      padding-top: 25px;
    }
  }

  @include phone {
    padding: 20px;

    .description {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      svg {
        margin-right: 16px;
        width: 22px;
        height: 22px;
      }
    }

    .organisation-header {
      flex-direction: column;
      align-items: flex-start;

      .organisation-title {
        margin: 0 0 5px;
      }
    }
    .saved-label {
      margin: 0;
    }

    .row-line {
      flex-direction: column;
      &.last {
        padding-bottom: 0;
      }
    }
  }
}
