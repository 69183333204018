@import "../../../common.scss";

.date-input-wrap {
  position: relative;
  margin: 0 0 25px;

  .validation-msg {
    position: absolute;
    top: 45px;
    left: 0;
    @extend .validation-text;
  }
}
