@import "../../../common.scss";

.dialog-modal {
  .modal-content {
    width: 460px;

    h2 {
      font-size: 20px;
      line-height: 24px;
    }

    .dialog-buttons {
      display: flex;
      flex-direction: row;
      margin: 30px 0 0;

      button {
        margin-right: 20px;
        &.primary {
          min-width: 162px;
          max-width: 162px;
        }
        &.subtle {
          min-width: 119px;
          max-width: 119px;
        }
      }
    }

    @include phone {
      max-width: 100%;
      width: 100%;
      bottom: 0;
      left: 0;

      opacity: 0;
      position: fixed;
      visibility: hidden;
      transition: all 0.25s ease;
      transform: translateY(100px);

      &.show {
        opacity: 1;
        visibility: visible;
        transition: all 0.25s ease;
        transform: translateX(0);
      }

      .round-buttons.subtle {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
