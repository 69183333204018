@import "../../../common.scss";
.suppliers-landing-banner {
    background-image: url("../../../images/suppliers-landing-banner.png");
    background-size: 100%;
    background-position: 100% 90%;
    background-repeat: no-repeat;
    padding: 80px 0;
    box-sizing: border-box;
    height: calc(100vw * 0.4271);
    max-height: 700px;    

    .suppliers-landing-banner-content {
        max-width: 1140px;
        font-family: "Montserrat";
        font-style: normal;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
        display: flex;
        flex-direction: column;
        margin: 0 auto;


        h1,
        span {
            max-width: 510px;
        }
    }

    .suppliers-landing-banner-title {
        font-weight: 600;
        font-size: 34px;
        line-height: 41px;
        letter-spacing: -0.084px;
        margin: 0;
    }

    .suppliers-landing-banner-description {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $very-dark-blue;
        margin: 0 0 30px;
    }

    .suppliers-landing-banner-accent {
        font-weight: 700;
        padding: 0 0 0 5px;
    }
}

@media (min-width: 1920px) {
    .suppliers-landing-banner {
        background-position: center;

        .suppliers-landing-banner-content {
            max-width: 1140px;
        
            h1,
            span {
                max-width: 700px;
            }
        }

        .suppliers-landing-banner-title {
            font-size: 50px;
            line-height: 60px;
        }

        .suppliers-landing-banner-description {
            font-size: 24px;
        }
    }
}

@media (max-width: 1200px) {
    .suppliers-landing-banner {
        padding: 40px 40px;
    }
}

@media (max-width: 950px) {
    .suppliers-landing-banner {
        padding: 30px 20px 130px;
        border-radius: 20px;
        margin: 0 20px 30px;
        background-position: bottom;
        height: auto;
        background-image: url("../../../images/suppliers-landing-banner-mob.png");

        .suppliers-landing-banner-title {
            font-size: 24px;
            line-height: 30px;
        }

        .suppliers-landing-banner-description {
            font-size: 15px;
            line-height: 20px;
            margin: 0 0 20px;
        }
    }
}
