@import "/../../../common.scss";

.items-unavailable-modal {
    .modal {
        .modal-content {
            max-width: 460px;
            width: 100%;
            // height: 280px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            color: $very-dark-blue;
            box-sizing: border-box;
        }
    }

    .items-unavailable-modal-title {
        max-width: 350px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.084px;
        margin: 0;
    }

    .items-unavailable-modal-text {
        max-width: 400px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.084px;
        margin: 0;
    }

    .items-unavailable-modal-buttons {
        display: flex;
        gap: 10px;
    }

    @include phone {
        .modal {
            .modal-content {
                position: fixed;
                bottom: 0;
                left: 0;
                max-width: 100%;
            }

             .close {
                right: 20px;
                top: 20px;
            }
        }

        .items-unavailable-modal-title {
            max-width: 100%;
        }

        .items-unavailable-modal-text {
            max-width: 100%;
        }
    }

    @media (max-width: 600px) {
        .items-unavailable-modal-title {
            max-width: 285px;
            font-size: 20px;
        }

        .items-unavailable-modal-text {
            max-width: 100%;
        }
    }
}
