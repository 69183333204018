@import "../../common.scss";

.site-header {
    // min-width: $header-container-width;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    box-shadow: 5px 20px 20px rgba(26, 49, 92, 0.05);
    position: relative;

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        transition-duration: 0.5s;
        opacity: 0;
        z-index: -1;
    }

    &.shown {
        opacity: 1;
        transition-duration: 0.5s;
        z-index: 20;
    }

    .header-container {
        padding: 0 20px 15px;
        width: 100%;
    }

    @include desktop {
        .header-container {
            max-width: $header-container-width;
            // min-width: $header-container-width;
            position: relative;
        }
    }

    @include phone {
        height: 111px;

        &.logged {
            height: 172px;
        }
    }
}
