@import "../../../common.scss";

.cart-notification {
  background: #fff;
  border: 1px solid rgba(87, 125, 187, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 0 20px;

  .cart-notification-content {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cart-notification-info {
    display: flex;
    align-items: center;
  }

  .cart-notification-icon {
    min-width: 30px;
    min-height: 30px;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($original-blue, 0.05);
    border-radius: 100px;

    svg {
      fill: $original-blue;
    }
  }

  .cart-notification-text {
    margin: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
  }

  .cart-notification-close {
    width: 18px;
    height: 18px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
      fill: $original-gray;
    }
  }
}
