@import "../../common.scss";

.contacts {
  padding: 40px 0;

  .contacts-content {
    display: flex;
    flex-direction: column;
  }
  .contacts-title {
    margin: 20px 0 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.084px;

    color: $very-dark-blue;
  }

  .contacts-tab {
    margin: 30px 0 40px;
    display: flex;
    .contacts-left {
      margin: 0 30px 0 0;
      display: flex;
      flex-direction: column;
    }

    .contacts-right {
      max-width: 100%;
      width: 100%;
      height: 519px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: rgba($original-gray, 0.1);
      border-radius: 15px;
      overflow: hidden;
    }

    .contacts-info {
      margin: 0 0 20px;
      width: 270px;
      padding: 20px;
      background: #fff;
      border: 1px solid rgba(32, 81, 163, 0.05);
      box-sizing: border-box;
      border-radius: 15px;
      font-family: Montserrat;
      font-style: normal;
      letter-spacing: -0.084px;
      color: $very-dark-blue;

      &:last-of-type {
        margin: 0;
      }
    }

    .contacts-info-icon {
      margin: 0 0 15px;
      width: 50px;
      height: 50px;
      @extend .flex-centered;
      justify-content: center;
      background: rgba(#1eb06a, 0.05);
      border-radius: 20px;

      &.location {
        background: rgba($original-blue, 0.05);
        svg {
          width: 24px;
          height: 24px;
          fill: $original-blue;
          path {
            fill: $original-blue;
          }
        }
      }
    }

    .contacts-info-caption {
      margin: 0 0 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    .contacts-info-text {
      margin: 0 0 15px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
    }

    .contacts-data {
      @extend .flex-centered;
      margin: 0 0 15px;
    }

    .contacts-data-texts {
      margin: 0 10px 0 0;
      display: flex;
      flex-direction: column;
      .contacts-phone {
        margin: 0 0 5px;
        text-decoration: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        color: $very-dark-blue;
      }
      .contacts-worktime {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;

        color: $original-gray;
      }
    }

    .contacts-data-icons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .contacts-data-icon {
        margin: 0 10px 0 0;
        @extend .flex-centered;
        justify-content: center;
        width: 40px;
        height: 40px;
        min-width: 40px;
        border: 1px solid rgba($color: $original-blue, $alpha: 0.1);
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }

    .contacts-info-email {
      text-decoration: none;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      color: $very-dark-blue;
    }
  }

  @include phone {
    .contacts-tab {
      margin: 30px 0 30px;
      display: flex;
      flex-direction: column;
      .contacts-left {
        margin: 0 0 15px 0;
        display: flex;
        flex-direction: row;
      }

      .contacts-info {
        margin: 0 25px 0 0;
        width: 100%;
        min-height: 250px;
      }
    }
  }
  @include tablet {
    .contacts-tab {
      margin: 30px 0 30px;
      display: flex;
      flex-direction: column;
      .contacts-left {
        margin: 0 0 15px 0;
        display: flex;
        flex-direction: row;
      }

      .contacts-info {
        margin: 0 25px 0 0;
        width: 100%;
        min-height: 250px;
      }
    }
  }

  @media (max-width: 500px) {
    .contacts-tab {
      margin: 30px 0 30px;
      display: flex;
      flex-direction: column;
      .contacts-left {
        margin: 0 0 30px 0;
        display: flex;
        flex-direction: column;
      }

      .contacts-info {
        margin: 0 0 15px 0;
        width: 100%;
        min-height: auto;
      }
    }
  }
}
