@import "../../../../common.scss";

.found-cheaper-modal {
    .modal .modal-content {
        width: 460px;
    }
    .modal {
        z-index: 12;
    }

    .found-cheaper-modal-content {
        .found-cheaper-modal-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.084px;

            color: $very-dark-blue;
            margin: 0;
        }

        .found-cheaper-modal-subtitle {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
        }

        .found-cheaper-modal-content-loader {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.7);
            height: 100%;
            width: 100%;

            .loader,
            .loader:after {
                width: 18px;
                height: 18px;
                border-left: 3px solid #fff;
            }

            .loader {
                font-size: 3px;
                left: calc(50% - 9px);
                top: calc(50% - 9px);
                border-right: 3px solid rgba(32, 81, 163, 1);
                border-top: 3px solid rgba(32, 81, 163, 1);
                border-bottom: 3px solid rgba(32, 81, 163, 1);

                width: 18px;
                height: 18px;
            }
        }
    }

    @include phone {
        .modal .modal-content {
            max-width: 100%;
            width: 100%;
            position: fixed;
            bottom: 0;
        }
    }
}
