@import "../../../common.scss";

.dashboard {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .client-card {
        padding: 25px;
        font-family: Montserrat;
        color: $very-dark-blue;
        background: #fff;
        background-repeat: no-repeat;
        background-position: right top;
        box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);
        border-radius: 5px;
        background-position-x: calc(100% + 28px);
        background-size: contain;
        // background-position: right -30px bottom 0px;
        .user-line {
            margin-bottom: 17px;
            @extend .flex-centered;
            flex-direction: row;

            .label {
                font-family: Montserrat;
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                margin-right: 15px;
            }

            .sub-label {
                font-family: Open Sans;
                font-size: 12px;
                line-height: 140%;
                color: $original-gray;
            }
        }

        .company-type {
            background: rgba(32, 81, 163, 0.1);
            border-radius: 5px;
            padding: 8px 15px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 140%;
            color: rgba($color: $original-blue, $alpha: 0.8);
            width: fit-content;
            margin-bottom: 24px;

            &.person {
                background: rgba(30, 176, 106, 0.1);
                color: $green;
            }
        }

        .created-at {
            font-family: Open Sans;
            font-size: 12px;
            line-height: 140%;
            color: $original-gray;
        }

        .avatar-wrap {
            position: relative;
            max-height: 36px;
            padding-right: 12px;
            .avatar {
                height: 36px;
                width: 36px;
                border-radius: 50%;
                border: 3px solid rgba(255, 255, 255, 0.2);
                object-fit: cover;
                &.empty {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: Open Sans;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: -0.3px;
                    color: #ffffff;
                }
            }
        }
    }

    .row {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        gap: 30px;

        .dashboard-box {
            max-width: 100%;
            width: 100%;
            background: #ffffff;
            box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);
            border-radius: 5px;
            padding: 25px;
            box-sizing: border-box;
            @extend .flex-centered;
            flex-direction: column;
            overflow: hidden;

            .empty {
                font-family: Montserrat;
                @extend .flex-centered;
                min-height: 160px;
                flex-direction: column;
                justify-content: center;
                width: 100%;

                .caption {
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.084px;
                    color: $original-gray;
                    padding: 0 50px;
                    padding-bottom: 19px;
                    text-align: center;
                }
                .info {
                    @extend %common-blue-link;
                    @extend .flex-centered;

                    svg {
                        margin-right: 12px;
                        fill: $original-blue;
                        width: 16px;
                        height: 16px;
                    }
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    color: $original-blue;
                }
            }
            .header {
                @extend .flex-centered;
                justify-content: space-between;
                padding-bottom: 20px;
                width: 100%;
                text-align: left;
                .caption {
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    color: $very-dark-blue;
                }

                .info {
                    font-family: Open Sans;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 140%;
                    color: $original-gray;
                }
            }

            .box-content {
                min-height: 150px;
                margin: 0 0 25px;
                width: 100%;

                .item {
                    padding: 11px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: Open Sans;
                    margin-bottom: 15px;
                    background: $new-bg-color;
                    border-radius: 5px;
                    box-sizing: border-box;

                    &:last-of-type {
                        margin: 0;
                    }
                    .info {
                        display: flex;
                        flex-direction: column;
                        font-size: 13px;
                        line-height: 150%;

                        &.light {
                            font-weight: normal;
                        }

                        a,
                        h4 {
                            @extend %common-dark-link;
                            font-family: Open Sans;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 150%;
                            color: $very-dark-blue;
                        }
                        .box-content-title {
                            margin: 0;
                            cursor: auto;
                        }
                        .sub {
                            color: $original-gray;
                        }
                    }
                    .status {
                        padding: 6px 15px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 140%;
                        color: #fff;
                        background: $original-blue;
                        border-radius: 5px;
                    }
                    .label {
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: $original-blue;
                    }

                    .order-status-wrapper {
                        .order-status {
                            background: $new-bg-color;
                        }
                    }
                }
            }

            .see-all {
                @extend %common-blue-link;
                font-family: Montserrat;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
            }

            .simple {
                max-width: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &.col {
                    flex-direction: column;
                }

                a {
                    font-weight: 600;
                    @extend %common-blue-link;
                }

                .simple-content {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 140%;
                    color: $original-gray;
                    max-width: 250px;
                    .bottom,
                    .dark {
                        color: $very-dark-blue;
                        width: 225px;
                    }
                }
                .dashboard-box-contacts {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;

                    .chat-icon {
                        margin-left: 10px;
                        @extend .flex-centered;
                        justify-content: center;
                        width: 60px;
                        height: 60px;
                        min-width: 60px;
                        border: 1px solid rgba($color: $original-blue, $alpha: 0.1);
                        box-sizing: border-box;
                        border-radius: 10px;
                        cursor: pointer;
                        font-family: Montserrat;
                        font-size: 14px;
                        line-height: 15px;
                        color: $original-blue;
                    }

                    .phone {
                        width: 100%;
                        padding: 22px;
                        svg {
                            fill: $original-blue;
                            margin-right: 12px;
                        }
                    }
                }

                img {
                    margin-top: -45px;
                    margin-right: -10px;
                }

                .bottom {
                    padding-top: 5px;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 15px;
                }

                &.full-width {
                    .simple-content {
                        min-width: 100%;
                    }
                }
            }
        }
    }

    @include phone {
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .row {
            flex-direction: column;
            padding-bottom: 0;
            gap: 20px;
            .dashboard-box {
                max-width: 100%;
                border-bottom: 2px solid $blue-gray;
                border-radius: 10px;
                width: 100%;
                box-sizing: border-box;
                padding: 25px 20px;
                align-items: flex-start;
                // width: calc(100% - 50px);

                &:first-child,
                &:last-child {
                    margin: 0;
                }

                .box-content {
                    min-height: 0;
                }

                .simple {
                    .simple-content {
                        max-width: inherit;
                    }

                    .dashboard-box-contacts {
                        padding-top: 13px;

                        .chat-icon {
                            margin-right: 10px;
                            margin-left: 0;
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
        .client-card {
            padding: 20px 25px;
            box-shadow: none;
            border-bottom: 2px solid $blue-gray;
            border-radius: 10px;
            margin: 0;

            // .user-line {
            //   margin-bottom: 10px;
            // }

            .company-type {
                margin-bottom: 12px;
            }
        }
        .help-img {
            max-height: 70px;
            align-self: center;
        }
    }

    @include tablet {
        padding: 0 20px;
    }
}
