@import "../../../common.scss";

.navigation-block {
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;

    & > p {
      margin-bottom: 0;
    }
  }

  & > p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
    font-family: Montserrat;
    position: relative;
    margin-top: 0;
    margin-bottom: 20px;
    cursor: pointer;
  }

  & > p svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(-180deg);
  }

  &__questions {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $original-gray;
      margin-bottom: 15px;

      &.active {
        color: $very-dark-blue;
        cursor: default;
      }
    }

    a:last-of-type {
      margin-bottom: 0;
    }
  }

  &--active-block {
    & > p svg {
      transform: translateY(-50%) rotate(0);
    }
  }
}
