@import '../../../common.scss';

.pagination {
    @extend .flex-centered;
    .arrow {
        .disabled {
            path {
                fill: $original-gray;
            }
        }
    }

    >a {
        height: 18px;
    }
    .pages-item {
        justify-content: center;
        @extend .flex-centered;
        font-size: 12px;
        color: $original-gray;
        width: 28px;
        height: 28px;
    }
    .pages {
        margin: 0 16px;
        display: flex;
        a {
            @extend .pages-item;
            &.selected {
                background: rgba($color:$original-blue, $alpha: 0.1);
                border-radius: 5px;
                color: $original-blue;
            }
        }

        .more {
            @extend .pages-item;
            color: $very-dark-blue;
        }
    }
}