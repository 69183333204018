@import "../../../common.scss";

.mobile-header {
    .top {
        @extend .flex-centered;
        justify-content: space-between;
        padding: 10px 0;
        .items {
            @extend .flex-centered;

            .logo {
                @extend .flex-centered;

                > img {
                    width: 120px;
                }
            }
        }
    }
    .sidebar-menu {
        h1 {
            margin: 30px 25px 20px;
        }
    }

    .login-button {
        @extend .flex-centered;
        height: 34px;

        .login-text {
            margin: 0 0 0 10px;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
    }

    &.business {
        .login-button {
            svg {
                fill: $business-color;
            }

            .login-text {
                color: $business-color;
            }
        }
    }
    &.private {
        .login-button {
            svg {
                fill: $private-color;
            }

            .login-text {
                color: $private-color;
            }
        }
    }

    .account-type-select-underlay {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $overlay-color;
        right: 0;
        display: none;
        z-index: 4;
        &.active {
            display: block;
        }
    }

    .notification-popup__underlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0b1f35;
        opacity: 0.3;
        z-index: 2;
    }

    .account-type-select {
        width: 100%;
        background: #fff;
        opacity: 0;
        position: fixed;
        visibility: hidden;
        transition: all 0.25s ease;
        transform: translateY(100px);
        bottom: 0;
        left: 0;
        background: #fff;
        box-shadow: 50px 50px 50px rgba(26, 47, 82, 0.1);
        border-radius: 20px 20px 0px 0px;
        z-index: 5;
        padding: 40px 20px;
        box-sizing: border-box;

        &.active {
            opacity: 1;
            visibility: visible;
            transition: all 0.25s ease;
            transform: translateX(0);
        }

        .account-type-title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
            margin: 0 0 20px;
        }

        .account-type-items {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            .account-type-item {
                display: flex;
                flex-direction: row;
                border: 1px solid #edf4f8;
                border-radius: 15px;
                padding: 15px;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                .account-type-icon {
                    @extend .flex-centered;
                    justify-content: center;
                    min-width: 50px;
                    height: 50px;
                    border-radius: 10px;
                    background-color: rgba($color: $green, $alpha: 0.1);
                    margin-right: 10px;

                    svg {
                        fill: $green;
                    }

                    &.business {
                        background-color: rgba($color: $original-blue, $alpha: 0.1);
                        svg {
                            fill: $business-color;
                        }
                    }
                }

                .account-type-description {
                    .account-type-subtitle {
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 135.84%;
                        letter-spacing: -0.3px;
                        color: #000;
                        margin: 0 0 5px;
                    }

                    .info {
                        font-family: Open Sans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.084px;
                        color: $original-gray;
                    }
                }
            }
        }
    }

    @include phone {
        .notification-popup__underlay {
            display: flex;
        }

        .sidebar-menu-header {
            display: flex;
            align-items: center;
            margin: 30px 0 25px 20px;
            .sidebar-menu-title {
                margin: 0;
            }
        }

        .top {
            .items {
                .logo {
                    svg {
                        width: 143px;
                    }

                    > img {
                      width: 120px;
                    }
                }
            }
        }
    }

    @include tablet {
        .top {
            .items {
                .logo {
                    svg {
                        width: 143px;
                    }

                    > img {
                      width: 120px;
                    }
                }
            }
        }
    }
}
