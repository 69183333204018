@import "../../../../common.scss";

.side-bar-filter {
    width: 268px;
    position: relative;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    transition-duration: 0.3s;
    border: 1.5px solid #fff;

    &.opened {
      border: 1.5px solid #2051A3;
      box-sizing: border-box;
      transition-duration: 0.3s;
    }

    .side-bar-filter-content {
        padding: 13px 15px;
        box-sizing: border-box;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        
        .side-bar-filter-icon {
            svg {
                transition-duration: 0.3s;
                fill: $original-gray;
                transform: rotate(180deg);
                path {
                    fill: $original-gray;
                }
            }

            &.rotated {
                svg {
                    transform: rotate(0deg);
                    transition-duration: 0.3s;
                }
            }
        }
    }

    .side-bar-filter-options {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;

        list-style: none;
        margin: 0;
        padding: 0;
        background: #ffffff;
        box-shadow: 3px 3px 50px rgba(12, 51, 117, 0.1);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        z-index: 2;

        .side-bar-filter-option {
            padding: 15px 20px;
            box-sizing: border-box;
            cursor: pointer;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            letter-spacing: -0.084px;
            color: $very-dark-blue;

            &:hover {
                background: #e9eef6;
                color: $original-blue;
            }
        }
    }
}
