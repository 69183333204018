@import "../../../../common.scss";

.payment-success {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  @extend .flex-centered;
  justify-content: center;
  background-color: #fff;
  z-index: 4;

  &__underlay {
    width: 335px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 23px;
      width: 100%;
      a {
        @extend .flex-centered;
        @extend %common-blue-link;
        font-size: 12px;
        line-height: 15px;
        svg {
          margin-right: 11px;
        }
      }

      .payment-success-logo {
        width: 90px;
      }
    }
  }

  &__content {
    position: relative;
    width: 335px;
    padding: 30px 20px;
    margin: 0 0 30px;
    @extend .flex-centered;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid rgba($color: $original-blue, $alpha: 0.1);

    &:before {
      content: "";
      position: absolute;
      top: 55%;
      left: -10px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 100%;
      border: 1px solid rgba($color: $original-blue, $alpha: 0.1);
    }
    &::after {
      content: "";
      position: absolute;
      top: 55%;
      right: -10px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 100%;
      border: 1px solid rgba($color: $original-blue, $alpha: 0.1);
    }
  }

  &__icon {
    width: 60px;
    height: 60px;
    background: rgba($color: $green, $alpha: 0.05);
    border-radius: 20px;
    @extend .flex-centered;
    justify-content: center;
    margin: 0 0 15px;
    svg {
      width: 24px;
      height: 24px;
      fill: $green;
    }
  }

  &__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.084px;
    color: $green;
    margin: 0 0 30px;
  }

  &__divider {
    width: 335px;
    margin: 0 0 20px;
    border-bottom: 1px dashed rgba($color: $original-blue, $alpha: 0.1);
  }

  &__info {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $original-gray;
  }

  &__info-item {
    display: flex;
    justify-content: space-between;
    margin: 0 0 13px;
    &:last-of-type {
      margin: 0;
    }
  }

  &__info-accent {
    color: $very-dark-blue;
  }

  &__info-accent-currency {
    padding: 0 0 0 3px;
    color: $very-dark-blue;
  }

  &__logos {
    display: flex;
    gap: 30px;
  }

  @include phone {
    &__underlay {
      width: 335px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    &__logos {
      position: absolute;
      bottom: 30px;

      display: flex;
      gap: 30px;
    }
  }
}
