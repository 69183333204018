@import "../../../common.scss";

.popular-products {
    padding: 40px 0 0;

    .popular-products-header {
        padding: 0 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .popular-products-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
    }

    .popular-products-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(4, 270px);
        gap: 20px;
    }

    .popular-products-button {
      width: 116px;
      margin: 9px 0;
      > .round-buttons {
          background-color: transparent;
          height: 28px;
          padding: 6px 15px;
          font-size: 12px;
      }
    }
}
