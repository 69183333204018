@import "../../../common.scss";

.supplier-landing-application {
    display: flex;
    gap: 30px;
    max-width: 1140px;
    margin: 0 auto 40px;

    .supplier-landing-application-left {
        max-width: 440px;
        height: 753px;
        width: 100%;
        padding: 40px 40px 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        border: 1px solid rgba(32, 81, 163, 0.1);
        border-radius: 20px;

        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 160%;
        color: $very-dark-blue;

        .supplier-landing-application-description {
            margin: 0 0 30px;
        }

        .supplier-landing-application-accent {
            font-weight: 600;
        }

        .supplier-landing-application-form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0 0 20px;

            .fillable-dropdown-wrap {
                margin: 0;
            }
        }

        .supplier-landing-application-checkbox {
            display: flex;
            margin: 0 0 30px;
            font-size: 12px;

            > .check-box-item {
                border-radius: 5px;
                .bg {
                    border-radius: 5px;
                }
            }
        }

        .supplier-landing-application-button {
            .round-buttons {
                width: auto;
            }
        }
    }

    .supplier-landing-application-right {
        width: 100%;
        border-radius: 20px;
        background-image: url("../../../images/analitics_background.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    @media (max-width: 950px) {
        margin: 0 20px 30px;
        gap: 0;

        .supplier-landing-application-left {
            max-width: 100%;
            .supplier-landing-application-button {
                .round-buttons {
                    width: 100%;
                }
            }
        }

        .supplier-landing-application-right {
            width: 0;
        }
    }
}
