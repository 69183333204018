@import "../../../common.scss";
.success-modal {
  .modal .modal-content {
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 290px;
    width: 100%;

    .close {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  .success-modal-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($green, 0.05);
    border-radius: 20px;

    svg {
      path {
        fill: $green;
      }
    }
  }

  .success-modal-title {
    margin: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.084px;
    color: $green;
  }

  @media (max-width: 440px) {
    .modal .modal-content {
      max-width: 100%;
      width: 100%;
    }
  }
}
