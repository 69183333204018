@import "../../../common.scss";

.product-page {
    margin-bottom: 150px;

    .label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $original-gray;
        &.text {
            color: $very-dark-blue;
        }
        &.bold {
            font-weight: 600;
            letter-spacing: -0.084px;
        }
        &.small {
            font-size: 12px;
        }
    }
    .main {
        background: #fff;
        margin: 0;
        padding: 10px 20px 40px;
        box-sizing: border-box;
        z-index: 0;
        .container {
            flex-direction: column;
        }
        // .top {
        //   padding: 12px 0;
        // }
        .notes {
            font-size: 12px;
            line-height: 16px;
            color: $original-gray;
            display: flex;

            &.temp {
                letter-spacing: -0.084px;
            }

            svg {
                margin-right: 10px;
            }
            span {
                width: 240px;
                display: inline-block;
            }
        }

        .inner {
            display: flex;
            flex-direction: row;
            max-width: 1140px;
            width: 100%;
            margin: 0 auto;
            box-sizing: border-box;

            .product-page-gallery {
                position: relative;

                .product-page-gallery-tag {
                    position: absolute;
                    top: 0;
                    right: 50px;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    background: #2f80ed;
                    border-radius: 25px;
                    cursor: pointer;

                    .product-page-gallery-tag-content {
                        position: relative;
                        padding: 9px 20px;
                        box-sizing: border-box;
                        position: relative;
                        display: flex;
                        align-items: center;
                    }

                    .product-page-gallery-tag-text {
                        margin: 0 10px 0 0;
                        font-family: Open Sans;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 140%;
                        letter-spacing: -0.084px;
                        color: #fff;
                    }

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                .product-page-coming-soon-tag {
                    position: absolute;
                    top: 0;
                    left: 65px;
                    z-index: 2;
                }

                .product-page-tags-column {
                    position: absolute;
                    top: 0;
                    left: 65px;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .card-tag {
                        margin: 0;
                        cursor: pointer;
                    }
                }
            }

            .desc {
                max-width: 370px;
                width: 100%;
                .title {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 34px;
                    letter-spacing: -0.084px;
                    color: $very-dark-blue;

                    padding: 21px 0 20px;
                }
                .article {
                    padding-bottom: 22px;
                    .label {
                        font-size: 12px;
                    }

                    span {
                        padding-right: 11px;
                    }
                }

                .product-price {
                    font-size: 24px;
                    line-height: 29px;
                    margin-bottom: 20px;
                    .measurement {
                        font-size: 18px;
                    }

                    .decimal {
                        font-size: 18px;
                    }
                }
                .package {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    margin: 0 0 20px;

                    .unit::before {
                        font-size: 22px;
                        position: relative;
                        top: 4px;
                    }
                }
                .actions {
                    margin-bottom: 20px;
                    display: flex;
                }
            }

            .side {
                max-width: 100%;
                width: 100%;
                padding: 0 0 0 30px;

                .temp {
                    @extend .flex-centered;
                    padding-top: 20px;
                    padding-left: 10px;
                    padding-right: 20px;
                }

                .owner {
                    padding-top: 20px;

                    font-family: Open Sans;
                    font-size: 14px;
                    line-height: 19px;
                    color: $original-gray;
                    a {
                        margin-left: 5px;
                        @extend %common-blue-link;
                        font-weight: 600;
                    }
                }

                .round-buttons {
                    width: 100%;
                }
            }
        }
    }

    .details {
        background-color: #fff;
        padding: 0 20px;
    }
    .details-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0 0 40px;
        background-color: #fff;
    }
    .product-reviews {
        margin: 0 0 100px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 10px;

        .product-reviews-header {
            display: flex;
            justify-content: space-between;
            margin: 0 0 30px;
            padding: 0 0 20px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(10, 31, 54, 0.05);

            .product-reviews-button {
                svg {
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .product-reviews-list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 25px;
        }

        .product-reviews-empty {
            display: flex;
            flex-direction: column;
            align-items: center;

            .product-reviews-empty-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 60px;
                border-radius: 20px;
                margin: 0 0 15px;
                background: $original-blue-o2;
                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            .product-reviews-text {
                max-width: 250px;
                width: 100%;
                margin: 0;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                letter-spacing: -0.084px;
                color: $original-gray;
            }
        }
    }

    .product-certificates {
        margin: 0 0 100px;
        padding: 0;

        .product-certificate {
            display: flex;
            padding: 17px 20px;
            background-color: #fff;
            border-bottom: 1px solid $original-blue-o005;
            cursor: pointer;

            &:hover {
                background-color: rgba(196, 196, 196, 0.1);
            }

            &:first-of-type {
                border-radius: 7px 7px 0 0;
            }
            &:last-of-type {
                border-radius: 0 0 7px 7px;
            }

            .product-certificate-image {
                width: 36px;
                height: 36px;
                border-radius: 10px;
                margin: 0 15px 0 0;
                object-fit: cover;
            }
            .product-certificate-icon-wrapper {
                position: relative;
                width: 36px;
                height: 36px;
                border-radius: 10px;
                margin: 0 15px 0 0;
                background: $original-blue-o2;

                z-index: 0;
                svg {
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    transform: translate(50%, -50%);
                    fill: $original-blue;
                    width: 16px;
                    height: 16px;
                    z-index: 1;
                }
            }

            .product-certificate-info {
                margin: 0 5px 0 0;

                .product-certificate-title {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    margin: 0;
                }
                .product-certificate-date {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    color: $original-gray;
                    margin: 0;
                    text-align: left;
                }
            }
            .product-certificate-label {
                width: 36px;
                height: 15px;
                margin: 0;

                background: rgba(155, 159, 179, 0.1);
                border-radius: 4px;
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 14px;
                text-align: center;
                color: $original-gray;
                text-transform: uppercase;
            }
        }
    }

    .product-page-buttons {
        height: 70px;

        &.flex {
            display: flex;
        }
    }

    @media (max-width: 460px) {
        .inner {
            .product-page-gallery {
                min-width: 100%;
            }
        }
    }

    @include phone {
        max-width: 100%;
        margin-bottom: 96px;

        .label {
            font-size: 12px;
            line-height: 16px;
        }

        .main {
            padding: 30px 20px;
            .top {
                font-size: 12px;
                line-height: 15px;
            }

            .inner {
                padding: 0;
                flex-direction: column;

                .product-page-gallery {
                    max-width: 100%;
                    width: fit-content;
                    width: -moz-fit-content;
                    .product-page-gallery-tag {
                        right: 20px;
                    }

                    .product-page-tags-column {
                        left: 0;
                    }

                    .product-page-coming-soon-tag {
                        left: 0;
                    }
                }

                .desc {
                    max-width: 100%;
                    .title {
                        padding: 10px 0 20px;
                        font-size: 16px;
                        line-height: 20px;
                    }

                    .article {
                        padding-bottom: 20px;
                    }

                    .product-price {
                        margin-bottom: 10px;
                    }

                    .actions {
                        margin: 0;
                        .round-button {
                            padding: 15px 20px;
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 16px;
                            border-radius: 35px;
                        }
                    }

                    .package {
                        margin: 0 0 20px;
                    }
                    .notes {
                        margin: 20px 0 30px;
                        span {
                            width: 228px;
                        }
                    }
                    .temp {
                        margin: 0;
                    }
                }

                .side {
                    max-width: 100%;
                    margin: 0;
                    padding: 0;

                    .round-buttons {
                       font-size: 14px;
                    }
                }

                .tabs {
                    padding: 0;
                    padding-top: 40px;
                    .tab-body {
                        h2 {
                            margin: 0;
                            padding-top: 8px;
                        }
                        .info {
                            flex-direction: column;

                            .right,
                            .left {
                                padding: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .details {
            padding: 0 20px;
        }
        .details-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 0 30px;
            background-color: #fff;
        }

        .fixed-button {
            position: fixed;
            bottom: 0;
            right: 0;
            width: 100%;
            padding: 20px;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 15px 15px 0px 0px;
            box-shadow: 5px -20px 20px rgba(26, 49, 92, 0.05);
            z-index: 11;

            .actions {
                width: 100%;

                .action-buttons {
                    width: 100%;
                    .action-button {
                        width: 100%;
                        .round-buttons {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .product-certificates {
            margin: 0 0 0;
        }

        .product-reviews {
            margin: 0 0 50px;

            .product-reviews-header {
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: space-between;
                margin: 0 0 30px;
                padding: 0 0 20px;
            }
        }
    }

    .product-supplier {
        padding-top: 25px;
        border: 1px solid rgba(32, 81, 163, 0.1);
        border-radius: 10px;
        min-width: 270px;
        box-sizing: border-box;
    }

    @include tablet {
        margin-bottom: 96px;

        .label {
            font-size: 12px;
            line-height: 16px;
        }

        .main {
            padding: 30px 20px;
            .product-page-breadcrumbs {
                padding: 0 20px;
            }

            .top {
                font-size: 12px;
                line-height: 15px;
            }

            .inner {
                max-width: 100%;
                flex-direction: row;
                padding: 0;

                .product-page-gallery {
                    .product-page-gallery-tag {
                        right: 40px;
                    }
                }

                .desc {
                    .title {
                        padding: 20px 0;

                        font-size: 16px;
                        line-height: 20px;
                    }

                    .article {
                        padding-bottom: 20px;
                    }

                    .product-price {
                        margin-bottom: 10px;
                    }

                    .actions {
                        margin-bottom: 20px;
                        .round-button {
                            padding: 15px 20px;
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 16px;
                            border-radius: 35px;
                        }
                    }

                    .product-page-buttons {
                        height: 70px;
                    }

                    .package {
                        margin: 0 0 20px;
                    }
                    .notes {
                        margin: 0 0 30px;
                        span {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                    .temp {
                        margin: 0;
                    }
                }

                .side {
                    margin: 0;
                    padding: 0;
                }

                .tabs {
                    padding: 0;
                    padding-top: 40px;
                    .tab-body {
                        h2 {
                            margin: 0;
                            padding-top: 8px;
                        }
                        .info {
                            flex-direction: column;

                            .right,
                            .left {
                                padding: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .details {
            padding: 0 20px;
        }
        .details-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 0 30px;
            background-color: #fff;
        }

        .product-certificates {
            margin: 0 0 0;
        }
    }
}
