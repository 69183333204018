@import "../../../common.scss";

.feedback {
  width: 100%;
  height: 536px;
  background: $original-blue;
  border-radius: 20px;
  padding: 50px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 524px 391px;
  background-position: 14px 145px;
  background-image: url("../../../images/vegetable-man.png");

  .feedback-content {
    display: flex;
    justify-content: space-between;
  }
  .feedback-desc {
    max-width: 430px;
    width: 100%;
    color: #fff;
    padding: 0 10px 0 0;
    .feedback-title {
      margin: 0 0 20px;
      font-family: Montserrat;
      font-style: normal;
      letter-spacing: -0.084px;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    .feedback-text {
      margin: 0;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.084px;
      color: #ffffff;
    }
  }

  .feedback-form {
    max-width: 380px;
    width: 100%;
    background: #fff;
    border: 1px solid rgba(32, 81, 163, 0.05);
    padding: 40px 30px;
    box-sizing: border-box;
    border-radius: 15px;

    svg {
      fill: $original-gray;
      path {
        fill: $original-gray;
      }
    }

    .feedback-checkbox-text {
      display: flex;
      flex-direction: column;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: -0.05px;
      color: $very-dark-blue;
    }

    .feedback-checkbox-link {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: -0.05px;
      color: $original-blue;
    }
  }

  @include phone {
    padding: 50px 20px;
    background-image: url("../../../images/vegetable-man-768.png");
    background-size: 352px 338px;
    background-position: 14px 198px;


    .feedback-desc {
      max-width: 310px;
    }
    .feedback-form {
      max-width: 100%;
      width: 100%;
    }
  }
  @include tablet {
    padding: 50px 20px;
    background-image: url("../../../images/vegetable-man-768.png");
    background-size: 352px 338px;
    background-position: 14px 198px;

    .feedback-desc {
      max-width: 310px;
    }
    .feedback-form {
      max-width: 330px;
      padding: 40px 20px;
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    height: auto;
    padding: 30px 20px;
    background-image: none;

    .feedback-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .feedback-title {
      margin: 0 0 15px;
      font-size: 18px;
      line-height: 22px;
    }
    .feedback-desc {
      margin: 0 0 30px;
      padding: 0;
      max-width: 100%;
    }
    .feedback-form {
      max-width: 100%;
      width: 100%;
    }
  }
}
