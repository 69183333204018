@import "../../../common.scss";

.noresident-modal {
  .modal .modal-content {
    padding: 40px;
    box-sizing: border-box;
    max-width: 520px;
    width: 100%;
    border-radius: 20px;
  }

  .noresident-modal-title {
    margin: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
  }

  .noresident-modal-text {
    margin: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.084px;
    color: $original-gray;
  }

  .noresident-modal-content {
    background: rgba(32, 81, 163, 0.05);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
  }

  .noresident-modal-product {
    display: flex;
    align-items: center;
  }

  .noresident-modal-product-pic {
    margin: 0 10px 0 0;
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }

  .noresident-modal-product-data {
    .product-price {
      font-size: 14px;
      line-height: 17px;

      .decimal {
        font-size: 12px;
        color: $original-gray;
      }
    }
  }

  .noresident-modal-product-name {
    margin: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: $very-dark-blue;
  }

  .noresident-modal-product-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .noresident-modal-product-total {
      margin: 0;
      font-size: 13px;
      line-height: 18px;
      color: $original-gray;
    }

    .noresident-modal-product-price {
      margin: 0 0 0 5px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: $very-dark-blue;
    }
  }

  > .item-edit {
    .input-wrapper {
      input {
        width: 100%;
        padding: 0 5px;
        margin: 0;
        height: 32px;
        text-align: center;
      }
    }
  }

  .noresident-modal-button {
    position: relative;
    width: fit-content;
    width: -moz-fit-content;
    min-width: 162px;

    .loader,
    .loader:after {
      width: 20px;
      height: 20px;
    }
    .loader {
      font-size: 3px;
      position: absolute;
      left: calc(50% - 10px);
      top: 11px;
      border-left: 1.1em solid #fff;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
  }

  @media (max-width: 440px) {
    .modal .modal-content {
      padding: 20px 20px 40px;
      position: absolute;
      bottom: 20px;
      left: 0;
      border-radius: 20px 20px 0px 0px;
    }

    .noresident-modal-button {
      width: 100%;
    }
  }

  @include phone {
    .modal {
      padding-top: 5%;
    }

    .modal-content {
      border-radius: 20px;
    }
  }
}
