@import '../../../../common.scss';

// .divider {
//     margin-right: 8px;
//     border-top: 1px solid $original-gray;
//     min-width: 15px;
//     display: inline-block;
//     position: relative;
//     top: 9px;
//   }

  .sidebar-categories {
    .categories-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        div, a, li
        {
            font-family: Open Sans;
            font-size: 14px;
            line-height: 17px;
            color: $original-gray;
            display: flex;
            padding: 0 0 15px 0;
            cursor: pointer;

            &.active {
                font-weight: 600;
                color: $original-blue;
                .dvd {
                    border-top-color: $original-blue;
                }
            }

            &.fixed {
                padding-bottom: 15px;
            }
          }

        .level1{
            padding: 0 0 15px 0;
        }
        .level2{
            padding: 0 0 15px 20px;
        }

        .level3{
            padding: 0 0 15px 40px;
        }
    }
}