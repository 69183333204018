@import "../../../common.scss";

.products-banner {
    .title-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 40px 0 0;

        .title-bar-left {
            display: flex;
            align-items: center;
            gap: 15px;

            .products-banner-title {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.084px;
                color: $very-dark-blue;

                min-width: fit-content;
                min-width: -moz-fit-content;
                width: 100%;
                margin: 0;

                display: flex;
                align-items: center;
                gap: 5px;

                > svg {
                    height: 24px;
                    width: 24px;
                    path {
                        fill: $very-dark-blue;
                    }
                }
            }

            .products-banner-bonus-tag {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.084px;
                background: rgba(237, 80, 85, 0.05);
                border-radius: 36px;
                padding: 8px 15px;
                box-sizing: border-box;
                display: flex;
                gap: 5px;
                color: $very-dark-blue;
                min-width: 144px;
                width: 100%;
            }

            .products-banner-bonus-icon {
                width: 14px;
                height: 14px;
            }
        }

        .buttons {
            display: flex;
            align-items: flex-end;
            cursor: pointer;
            gap: 15px;
        }

        .products-banner-button {
            width: 117px;
            min-width: 117px;
            > .round-buttons {
                background: transparent;
                height: 28px;
                padding: 6px 15px;
                font-size: 12px;
                &:hover {
                    background: $original-blue;
                    color: #fff;
                }
            }
        }
    }
    .products-banner-items {
        position: relative;

        .products-card {
            width: 220px;
        }

        .products-banner-items-button {
            position: absolute;
            top: calc(50% - 16px);
            left: -22px;
            display: flex;
            align-items: flex-end;
            cursor: pointer;
            gap: 15px;
            z-index: 2;
            display: none;

            &.next {
                left: auto;
                right: -16px;
            }

            &.showed {
                display: flex;
            }
        }

        .swiper-container {
            padding-top: 30px;
        }

        &.bonus-products {
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        transform: none !important;
                    }
                }
            }
        }
    }

    @include tablet {
        .products-banner-items {
            .swiper-container {
                padding-top: 0;
                .swiper-slide {
                    max-width: 236px;
                }
            }
        }
        .title-bar {
            padding: 0 20px 0 0;
            margin: 30px 0 20px 20px;

            .title-bar-left {
                .products-banner-title {
                    font-size: 18px;
                    line-height: 20px;
                    padding: 0;
                }
            }
        }
    }

    .lazy-item {
        min-height: 100px;
        min-width: 100px;
        background-color: blueviolet;
    }

    @media (max-width: 1140px) {
        .title-bar {
            margin-left: 20px;
        }

        .products-banner-items {
            padding: 0 0 0 20px;
        }
    }
    @include phone {
        .products-banner-items {
            .swiper-container {
                padding-top: 0;
                .swiper-slide {
                    max-width: 165px;
                }
            }
        }
        .title-bar {
            padding: 0 20px 0 0;
            margin: 30px 0 20px 20px;

            .title-bar-left {
                .products-banner-title {
                    font-size: 18px;
                    line-height: 20px;
                    padding: 0;
                }
            }
        }

        .products-banner-link {
            min-width: 75px;
        }
    }
}
