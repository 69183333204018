@import "../../../../common.scss";

.orders-list {
    flex-direction: column;

    .orders-list-content {
        list-style: none;
        margin: 0 0 30px;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .orders-list-content-title {
        margin: 0 0 15px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.084px;
        color: $original-gray;
        &::first-letter {
            text-transform: uppercase;
        }
    }

    .orders-list-navigation {
        display: flex;
        justify-content: space-between;

        .orders-list-navigation-button {
            min-width: 198px;
            margin-right: 20px;
        }

        &.mobile {
            justify-content: center;
        }
    }

    .order-item {
        padding: 20px 18px;
        background: #fff;
        border-radius: 5px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        @extend .flex-centered;
        .order-info {
            width: 50%;
            display: flex;
            flex-direction: row;

            .order-number-col {
                width: 60%;

                .number {
                    // font-family: Montserrat;
                    // font-weight: 600;
                    // font-size: 16px;
                    // line-height: 140%;
                    // color: $original-blue;
                    @extend %common-blue-link;
                }
                .date {
                    font-family: Open Sans;
                    font-size: 12px;
                    line-height: 16px;
                    color: $original-gray;
                }
            }

            .sum-col {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-family: Montserrat;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                color: $very-dark-blue;
                width: 40%;
            }
            .status-col {
                width: 42%;
            }

            .items-col {
                display: flex;
                flex-direction: row;
                width: 58%;
                justify-content: flex-end;
                // overflow: hidden;
                font-size: 11px;

                img {
                    width: 38px;
                    height: 38px;
                    margin-left: 10px;
                    object-fit: cover;
                    border-radius: 5px;
                }

                .more {
                    margin-left: 10px;
                    @extend .flex-centered;
                    // width: 40px;
                    font-family: Open Sans;
                    font-size: 14px;
                    line-height: 17px;
                    color: $original-gray;
                    white-space: nowrap;
                }
            }
        }

        @include phone {
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;
            margin: 0 0 2px;

            .order-info {
                width: 100%;

                &:first-child {
                    padding-bottom: 17px;
                }

                .sum-col {
                    text-align: right;
                }

                .items-col {
                    img {
                        width: 30px;
                        height: 30px;
                        margin-left: 8px;
                    }

                    .more {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    @include phone {
        .orders-list-content {
            padding: 0 20px;
        }

        .orders-list-content-title {
            margin: 0 0 12px;
            padding: 0 20px;
            box-sizing: border-box;
        }
    }

    .order-status-wrapper {
        .order-status {
            background: transparent;
            width: 100%;
            padding: 8px 10px 8px 0;
        }
    }
}
