@import "../../../../../common.scss";

.edit-order-status-info {
  background: #f4f6fa;
  border-radius: 6px;
  font-family: Open Sans;
  font-size: 12px;
  line-height: 16px;
  color: $very-dark-blue;
  padding: 22px 16px;
  display: flex;
  flex-grow: 1;
  svg {
      padding-right: 11px;
      fill: $original-blue;
  }

  .green {
      color: $green;
      margin: 0 0 0 5px;
  }

  &.warning {
      background: #fefaf6;
      color: $orange;
      svg {
          fill: $orange;
      }
  }
}