@import "../../../common.scss";

.favorites-button {
  //   padding: 8px;
  border-radius: 50%;
  background: #fff;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  

  &.disabled {
    cursor: auto;
    .hint:hover {
      svg {
        fill: $original-gray;
        cursor: auto;
      }
    }
  }

  .hint {
    svg {
      position: relative;
      top: 3px;
      fill: $original-gray;
    }
    &:hover {
      svg {
        fill: $original-blue;
      }
    }
  }

  .hint.top .hint-wrap {
    bottom: 27px;
    left: -74px;

    .hint-content {
      box-shadow: 15px 15px 25px rgba(29, 30, 27, 0.05);
      background: #fff;
      border: 1px solid rgba($original-blue, 0.05);
      color: $very-dark-blue;
      width: 168px;

      &:after {
        border-width: 11px 8px 0;
        bottom: -10px;
        left: 50%;
        margin-left: -10px;
        // border-color: transparent transparent $blue-gray transparent;
        border-color: #fff transparent;
        box-shadow: 15px 15px 25px rgba(29, 30, 27, 0.05);
      }
    }
  }

  &.squared {
    margin-left: 20px;
    border: 1px solid rgba($original-blue, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 15px;
    svg {
      top: 0px;
      width: 20px;
      height: 20px;
    }

    .hint.top .hint-wrap {
      bottom: 54px;
      left: -72px;
    }
  }
}
