@import "../../common.scss";

.filter-box {
  .filter-input {
    padding-bottom: 20px;
    .Input {
      svg {
        fill: $original-gray;
      }
    }
  }

  .content {
    // padding-bottom: 36px;
    // transition: all 0.5s ease;

    .parameter-group:not(:last-child) {
      border-bottom: 1px solid #e9e9e9;
    }

    .parameter-group {
      margin-bottom: 20px;

      .group-title {
        font-weight: bold;
        margin-bottom: 0;
      }

      .filter-parameter {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .checkbox-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;

      .checkbox {
        width: 18px;
        height: 18px;
        display: inline-block;
        border: 2px solid rgba(193, 207, 229, 0.5);
        box-sizing: border-box;
        border-radius: 5px;
      }

      .checkbox-value {
        margin-left: 8px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #0b1f35;
      }
    }
  }
}
