@import "../../../../../common.scss";

.order-modal {
  .modal .modal-content {
    width: 460px;
    padding: 40px;
    box-sizing: border-box;

    .order-modal-title {
      width: 310px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      margin: 0 0 20px;
      padding: 0;
    }

    .sub-header {
      margin: 0 0 20px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
    }

    .content {
      padding-bottom: 20px;

      div {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .dialog-buttons {
      @extend .flex-centered;
      gap: 20px;
    }
  }

  @include phone {
    .modal .modal-content {
      position: fixed;
      bottom: 0;
      left: 0;
      max-width: 100%;
      width: 100%;
      padding: 40px 20px;
      border-radius: 20px 20px 0px 0px;

      .order-modal-title {
        width: calc(100% - 40px);
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
