@import "../../../common.scss";

.my-companies-page {
  .item-status {
    background: none;
    &.Active {
      svg {
        fill: $green;
      }
    }
    &.Draft,
    &.AwaitingConfirmation {
      svg {
        fill: $yellow;
      }
    }

    &.Blocked {
      svg {
        fill: $original-red;
      }
    }
  }

  .my-companies-list {
    margin-top: 20px;
    .my-company-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      background: #ffffff;
      border-radius: 10px;
      padding: 20px;
      justify-content: space-between;
      align-items: flex-start;

      .myc-data {
        width: 100%;
        .myc-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          margin-bottom: 20px;
          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .myc-name {
            @extend .subheader3;
            display: flex;
            flex-direction: column;
            margin-left: 15px;

            h3 {
              @extend .header3;
            }
          }
        }

        .myc-info {
          display: flex;
          flex-direction: row;
          margin-top: 15px;
          .edit-wrapper {
            margin-right: 30px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .edit-my-company {
    background: #ffffff;
    border-radius: 10px;
    padding: 30px 40px;

    .top {
      margin-bottom: 10px;
      a {
        @extend .flex-centered;
        @extend %common-blue-link;
        svg {
          margin-right: 11px;
        }
      }
    }
    .emc-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .emc-name {
        @extend .subheader3;
        display: flex;
        flex-direction: row;
        h3 {
          @extend .header3;
        }
      }
    }

    .emc-info {
      display: grid;
      grid-template-columns: 50% 50%;
      .edit-wrapper {
        margin-bottom: 18px;
      }
    }

    h4 {
      @extend .header4;
      height: 40px;
      align-items: center;
      display: flex;
    }
  }

  @include phone {
    padding: 0 20px;
    .round-buttons.ghost {
      min-width: 100%;
    }

    .item-status {
      margin-top: 10px;
    }

    .my-companies-list {
      .my-company-item {
        .myc-data {
          .myc-header {
            align-items: flex-start;
            flex-direction: column;
          }
        }
      }
    }

    .edit-my-company {
      padding: 20px;
      background: none;
      // border-radius: 10px;
      .top {
        padding-bottom: 25px;
      }
      .emc-header {
        //   flex-direction: column;
        //   align-items: flex-start;
        .emc-name {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
        }
      }

      .company-info-wrapper {
        background: #ffffff;
        border-radius: 10px;
        padding: 20px;
        .item-status {
          margin: 0 0 20px;
          padding-left: 0;
        }

        h4 {
          margin: 25px 0 10px;
        }

        .edit-wrapper {
          margin-bottom: 18px;
        }
        .double-col {
          display: grid;
          grid-template-columns: 50% 50%;
        }
      }
    }
  }
}
