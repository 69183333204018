@import "../../../../common.scss";

.item-unavailable {
    position: relative;
    min-width: 255px;
    padding: 15px;
    @extend .flex-centered;
    background: #fff;
    border: 1px solid rgba(87, 125, 187, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 10;

    .item-unavailable-icon {
        margin: 0 10px 0 0;
        width: 30px;
        height: 30px;
        @extend .flex-centered;
        justify-content: center;
        background: rgba(#f2994a, 0.05);
        border-radius: 100px;

        svg {
            fill: #f2994a;
        }
    }

    .item-unavailable-text {
        margin: 0;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
       
        
        .recount {
            font-family: Montserrat;
            color: $original-blue;
            font-weight: 600;
            margin: 0 5px;
            cursor: pointer;
                       

            svg {
                position: relative;
                top: 3px;
                path {
                    fill: $original-blue;
                }
            }
        }
    }

    .item-unavailable-overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.7);
        height: 100%;
        width: 100%;

        .loader,
        .loader:after {
            width: 18px;
            height: 18px;
            border-left: 3px solid #fff;
        }

        .loader {
            font-size: 3px;
            left: calc(50% - 9px);
            top: calc(50% - 9px);
            border-right: 3px solid rgba(32, 81, 163, 1);
            border-top: 3px solid rgba(32, 81, 163, 1);
            border-bottom: 3px solid rgba(32, 81, 163, 1);

            width: 18px;
            height: 18px;
        }
    }

    @media (max-width: 440px) {
        max-width: 100%;
        width: 100%;
    }
}
