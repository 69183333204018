@import "../../../common.scss";

.pick-up-points-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .pick-up-point {
        display: flex;
        flex-direction: column;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid;
        border: 1px solid rgba(32, 81, 163, 0.1);
        transition-duration: 0.3s;
        cursor: pointer;

        &.selected {
            border: 1px solid $original-blue;
            transition-duration: 0.3s;
        }

        .pick-up-point-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.084px;

            color: $very-dark-blue;
            margin: 0;
        }

        .pick-up-point-address {
            max-width: 380px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;

            letter-spacing: -0.084px;
            color: $very-dark-blue;
        }

        .pick-up-point-link {
            text-decoration: none;
            border-top: 1px solid rgba(32, 81, 163, 0.1);
            padding: 10px 0 0;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: $original-blue;
        }
    }
}
