@import "../../../../common.scss";

.products-gallery {
  display: flex;
  flex-direction: column;

  h2 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
    margin: 0;

    a {
      color: #2051A3;
    }
  }

  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 25px;

    .buttons {
      display: flex;
      align-items: flex-end;
      cursor: pointer;
      gap: 15px;
      // div[class^="swiper-btn-"] {


      //   .swiper-bnt {
      //     background: #fff;

      //     border-radius: 15px;
      //     height: 45px;
      //     width: 45px;
      //     box-sizing: border-box;
      //     @extend .flex-centered;
      //     justify-content: center;

      //     cursor: pointer;
      //     transition: 0.2s ease;

      //     svg {
      //       width: 18px;
      //       height: 18px;
      //       fill: $original-gray;
      //     }

      //     &:hover {
      //       background: $original-blue;
      //       svg {
      //         fill: #fff;
      //       }
      //     }
      //   }
      // }
      // div[class^="swiper-btn-prev"] {
      //   margin-right: 15px;
      //   .swiper-bnt {
      //     transform: scaleX(-1);
      //   }
      // }
    }
  }

  .products-gallery-items {
    .products-card {
      width: 220px;
    }


  }

  @include not-desktop {
    background: #f8f8fa;
    h2 {
      font-size: 18px;
      line-height: 22px;

    }

    .products-gallery-items {
      margin: 0 -20px;
      padding: 0 0 0 20px;
    }
  }
}
