@import "../../../common.scss";

.categories-menu-mobile {
  .scroll-wrapper {
    display: flex;
    background-color: white;
    width: 100%;

    height: calc(var(--vh) - 67px - 82px);

    .categories {
      min-width: auto;
      min-height: auto;
      border-right: none;
      display: flex;
      flex: 1 1;
      flex-direction: column;

      .menu-item {
        @extend .flex-centered;
        flex-direction: row;
        padding-right: 20px;
        padding: 0px 13px 0px 20px;
        min-height: 67px;
        color: $very-dark-blue;
        font-size: 13px;
        line-height: 18px;
        @extend .flex-centered;
        justify-content: space-between;
        svg {
          path {
            fill: $original-gray;
          }
        }

        .title {
          @extend .flex-centered;
          .category-img {
            margin-right: 10px;
            width: 40px;
            height: 40px;
          }
          > a {
            @extend .flex-centered;
          }

        }

        a {
          @extend %common-dark-link;
          width: 100%;
          padding: 20px 0;
        }

        &.active {
          // font-weight: 600;
          // border-right: 3px solid $original-blue;
          background-color: $blue-gray;
          // color: $original-blue;
          .back  {
            margin-right: 28px;
            @extend .flex-centered;
            align-items: center;
          }
        }
      }
    }

    .info {
      border: 1px solid $blue-gray;
      background-color: $new-bg-color;
      flex-grow: 1;
    }
    
    &.full-height {
      height: calc(var(--vh));
    }
  }

  .bottom-bar {
    @extend .flex-centered;
    padding: 25px 0;
    justify-content: center;
  }
}
