@import "../../../../common.scss";

.actions {
    display: flex;
    .action-buttons {
        display: flex;
        flex-direction: row;
    }

    .action-button {
        position: relative;
        width: fit-content;
        width: -moz-fit-content;

        .loader,
        .loader:after {
            width: 12px;
            height: 12px;
            border-left: 2px solid rgba(32, 81, 163, 1);
        }
        .loader {
            font-size: 2px;
            position: absolute;
            left: calc(50% - 8px);
            top: calc(50% - 8px);
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;

            border-right: 2px solid #fff;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
        }
    }

    .action-button-add {
        position: relative;
        .round-buttons {
            width: 40px;
            height: 40px;
            padding: 0;
            font-size: 24px;
            font-weight: normal;

            &.secondary-disabled {
                border: 1px solid $original-gray;
                opacity: 0.5;
                color: $original-gray;
                background: transparent;
                // cursor: not-allowed;

                &:hover {
                    border: 1px solid $original-gray;
                    color: $original-gray;
                    opacity: 0.5;
                    background: transparent;
                }
            }
        }

        &:hover {
            .action-button-tooltip {
                display: flex;
            }
        }
    }

    .action-button-cart {
        &:hover {
            .action-button-tooltip {
                display: flex;
            }
        }
    }

    .action-button-tooltip {
        display: none;
        position: absolute;
        top: -62px;
        left: -76px;
        width: fit-content;
        width: -moz-fit-content;
        white-space: nowrap;
        padding: 15px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: $very-dark-blue;
        font-family: Open Sans;
        background: #fff;
        border: 1px solid rgba(32, 81, 163, 0.05);
        box-shadow: 15px 15px 25px rgba(29, 30, 27, 0.05);
        border-radius: 10px;
        z-index: 100;

        &::before {
            content: "";
            border: solid transparent;
            position: absolute;
            top: 100%;
            left: calc(50% - 9px);
            border-top-color: #fff;
            border-width: 9px;
            margin-left: -1px;
        }

        &.pickup {
            width: 190px;
            height: auto;
            top: -80px;
            left: -160px;
            z-index: 10;
            padding: 15px 10px;
            white-space: pre-wrap;

            &::before {
                left: 80%;
            }
        }

        &.pickup-cart {
            top: -80px;
            left: 0;
            width: 190px;
            white-space: pre-wrap;
            padding: 15px 10px;
        }
    }

    @include not-desktop {
        .round-button {
            font-size: 10px;
            line-height: 14px;
            padding: 10px 15px;
            &.small {
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.084px;
                padding: 5px 15px;
            }
        }
    }
}
