@import "../../../common.scss";
.supplier-landing-become {
    background: linear-gradient(106.48deg, #0b1f35 19.32%, rgba(11, 31, 53, 0.85) 131.13%);
    border-radius: 20px;
    padding: 40px 0 40px 40px;
    box-sizing: border-box;
    max-width: 1140px;
    margin: 0 auto 40px;

    .supplier-landing-become-list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, 250px);
        gap: 33px;

        .supplier-landing-become-item {
            width: 250px;
            display: flex;
            flex-direction: column;

            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            color: #fff;
            padding: 0 40px 0 0;
            box-sizing: border-box;

            .supplier-landing-become-number {
                width: 54px;
                height: 54px;
                box-sizing: border-box;
                border: 1px solid #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                margin: 0 0 10px;
                border-radius: 100px;
            }

            .supplier-landing-become-link {
                text-decoration: underline;
                cursor: pointer;
                color: #fff;
            }

            .supplier-landing-become-check {
                width: 54px;
                height: 54px;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $green;
                margin: 0 0 10px;

                > svg {
                    width: 24px;
                    height: 24px;
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }

    @media (max-width: 950px) {
        margin: 0 20px 30px;
        padding: 30px 20px;

        .supplier-landing-become-list {
            gap: 20px;
            grid-template-columns: repeat(auto-fill, 180px);
            .supplier-landing-become-item {
                max-width: 180px;
                font-size: 14px;

                .supplier-landing-become-number {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
    @media (max-width: 600px) {
        .supplier-landing-become-list {
            grid-template-columns: repeat(2, 136px);
            .supplier-landing-become-item {
                &:last-of-type {
                    display: none;
                }
            }
        }
    }
}
