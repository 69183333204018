@import "../../../../common.scss";

.order-list-pagination {
  max-width: 100%;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  .order-list-pagination-content {
    display: flex;
    align-items: center;
  }

  .order-list-pagination-icon {
    height: 18px;
    cursor: pointer;
    &.prev {
      transform: rotate(180deg);
    }

    &.disabled {
      svg {
        path {
          fill: $original-gray;
        }
      }
    }
  }

  .order-list-pagination-list {
    display: flex;
    justify-content: space-between;
    margin: 0 25px;
  }

  .order-list-pagination-item {
    width: 28px;
    height: 28px;
    border-radius: 16px;
    @extend .flex-centered;
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: $original-gray;
    cursor: pointer;

    &.selected {
      color: $original-blue;
      background-color: rgba($color: $original-blue, $alpha: 0.1);
    }

    &.more {
      color: #000;
      align-items: flex-end;
      padding-bottom: 3px;
      box-sizing: border-box;
    }
  }
}
