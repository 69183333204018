.checkbox-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    width: fit-content;

    .checkbox-button {
        min-width: 22px;
        height: 22px;
        border-radius: 5px;
        border: 2px solid rgba(193, 207, 229, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        box-sizing: border-box;

        &.checked {
            background: #2051A3;
            border: 2px solid #C1CFE5;
        }

        svg path {
            fill: #FFF;
        }
    }
    .checkbox-title {
        font-family: 'Open Sans';
        font-size: 12px;
        line-height: 16px;
        color: #000;
    }
}
.checkbox-group {

    .checkbox-wrapper:not(:last-child) {
        margin-bottom: 15px;
    }
}
