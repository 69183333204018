@import "../../../common.scss";

.suppliers-landing-about {
    max-width: 1140px;
    width: 100%;
    background: #f8f9fc;
    border-radius: 20px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    margin: -150px auto 40px;
    background-image: url("../../../images/dc_map.png");
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 578px, 477px;

    .suppliers-landing-about-left {
        max-width: 48%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .suppliers-landing-about-description-list {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            color: $very-dark-blue;
        }

        .suppliers-landing-about-accent {
            font-weight: 600;
        }

        .suppliers-landing-about-info-list {
            display: flex;
            justify-content: space-between;
            list-style: none;
            padding: 0;
            margin: 0;

            .suppliers-landing-about-info-item {
                max-width: 50%;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .suppliers-landing-about-info-caption {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 35px;
                    line-height: 50%;
                    color: $very-dark-blue;

                    > span {
                        font-size: 18px;
                    }
                }

                .suppliers-landing-about-info-text {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 160%;
                    color: $very-dark-blue;
                    opacity: 0.7;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        margin-top: -75px;
    }

    @media (max-width: 950px) {
        margin: 0 20px 30px;
        width: auto;
        flex-direction: column;
        background-image: none;
        .suppliers-landing-about-left {
            max-width: 100%;

            .suppliers-landing-about-description-list {
                font-size: 14px;
                line-height: 22px;
            }

            .suppliers-landing-about-info-list {
                .suppliers-landing-about-info-item {
                    .suppliers-landing-about-info-caption {
                        font-size: 28px;
                    }
                }
            }
        }
    }

    @media (max-width: 600px) {
        padding: 20px 20px 90% 20px;
        background-position: bottom;
        background-size: 120%;
        background-image: url("../../../images/dc_map.png");
    }
}
