@import "../../../common.scss";

.suppliers-landing-faq {
    max-width: 1140px;
    margin: 0 auto 40px;

    > .supplier-landing-title {
        max-width: 240px;
    }

    .suppliers-landing-faq-content {
        display: flex;
        gap: 30px;

        .suppliers-landing-faq-content-left {
            max-width: 440px;
            height: max-content;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;

            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;

            color: $very-dark-blue;
            background: rgba(32, 81, 163, 0.05);
            border-radius: 20px;

            .suppliers-landing-faq-link {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 15px;
                color: #000;
            }

            .suppliers-landing-faq-socials {
                list-style: none;
                margin: 0;
                padding: 0;

                display: flex;
                align-items: center;
                gap: 10px;

                .suppliers-landing-faq-social {
                    padding: 6px 15px;
                    border: 1px solid $original-blue;
                    border-radius: 20px;

                    &.green {
                        border: 1px solid $green;

                        > a {
                            color: $green;
                        }
                    }

                    > a {
                        display: flex;
                        gap: 5px;
                        font-family: "Open Sans";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: -0.084px;
                        color: $original-blue;
                        text-decoration: none;

                        > svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }

    .faq-item {
        list-style: none;
        padding-top: 15px;
        border-top: rgba($color: $very-dark-blue, $alpha: 0.07) 1px solid;

        &:last-of-type {
            border-bottom: rgba($color: $very-dark-blue, $alpha: 0.07) 1px solid;
        }

        .faq-header {
            padding-bottom: 15px;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
            cursor: pointer;

            .state-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 11px;
                    height: 11px;
                    fill: $original-gray;
                }
                background-color: rgba($color: $original-blue, $alpha: 0.05);
                border-radius: 50%;
                width: 40px;
                height: 40px;
                min-width: 40px;
                margin-left: 10px;
            }
        }

        .content {
            padding-right: 170px;
            display: block;
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.15s ease-out;

            &.active {
                max-height: 100%;
                transition: max-height 0.15s ease-in;
                padding-bottom: 30px;
            }
        }
    }

    @media (max-width: 950px) {
        margin: 0 20px 30px;
        .suppliers-landing-faq-content {
            flex-direction: column;

            .suppliers-landing-faq-content-left {
                max-width: 100%;
            }
        }

        .faq-item {
            .content {
                padding-right: 50px;
            }
        }
    }
}
