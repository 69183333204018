@import "../../../common.scss";

.radio-group {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  width: 100%;

  .radio-item {
    display: flex;
    align-items: center;
    border: 1px solid rgba(32, 81, 163, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px;
    // margin-right: 20px;
    // min-width: 190px;
    cursor: pointer;
    width: 100%;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: $very-dark-blue;

    // &:last-of-type {
    //   margin-right: 0;
    // }

    &.selected {
      border: 1px solid #2051a3;
    }
  }
}
