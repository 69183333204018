@import "../../../common.scss";

.suppliers-landing-features {
    max-width: 1140px;
    margin: 0 auto 40px;
  
    .suppliers-landing-features-content {
        overflow: hidden;
    }

    .suppliers-landing-features-list {
        width: 100%;
        margin: 0 -3px -3px 2px;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        .suppliers-landing-features-item {
            background-color: #fff;
            border-bottom: 1px solid rgba(#9b9fb3, 0.2);
            border-right: 1px solid rgba(#9b9fb3, 0.2);
            flex: 1 0 380px;
            height: 270px;
            display: flex;
            flex-direction: column;
            padding: 30px;
            box-sizing: border-box;

            .suppliers-landing-features-image {
                width: 80px;
                height: 80px;
                margin: 0 0 20px;
            }

            .suppliers-landing-features-caption {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 130%;
                color: $very-dark-blue;
                letter-spacing: -0.084px;
                margin: 0 0 15px;
            }

            .suppliers-landing-features-text {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 160%;
                color: $very-dark-blue;
                margin: 0;
            }

            &:nth-child(1) {
                padding: 0 30px 30px 0;
            }
            &:nth-child(2) {
                padding: 0 30px 30px 30px;
            }
            &:nth-child(3) {
                padding: 0 30px 30px 30px;
            }
            &:nth-child(4) {
                padding: 30px 30px 30px 0;
            }
            &:nth-child(5) {
                padding: 30px;
            }

            &:empty {
                height: 0;

                border: none;
            }
        }
    }

    @media (max-width: 1140px) {
        margin: 0 20px 40px;
        .suppliers-landing-features-content {
            overflow: scroll;
            &::-webkit-scrollbar {
                width: 0;
            }
            scrollbar-width: none;
            justify-content: flex-start;
        }

        .suppliers-landing-features-list {
            .suppliers-landing-features-item {
                flex: 1 0 300px;
                border-bottom: none;

                &:nth-child(5) {
                    border-right: none;
                }

                &:empty {
                    height: 0;

                    border: none;
                }
            }
        }
    }

    @media (max-width: 950px) {
        margin: 0 20px 30px;
        .suppliers-landing-features-list {
            flex-wrap: nowrap;

            .suppliers-landing-features-item {
                flex: 1 0 380px;

                &:nth-child(1) {
                    padding: 0 20px 20px 0;
                }
                &:nth-child(2) {
                    padding: 0 20px 20px 20px;
                }
                &:nth-child(3) {
                    padding: 0 20px 20px 20px;
                }
                &:nth-child(4) {
                    padding: 0 20px 20px 20px;
                }
                &:nth-child(5) {
                    padding: 0 20px 20px 20px;
                }

                &:empty {
                    display: none;
                }
            }
        }
    }
}
