@import "../../../../common.scss";

.package {
    font-size: 12px;
    line-height: 16px;
    color: $original-gray;

    .unit {
        &::before {
            padding: 0 8px;
            color: $original-blue;
            content: "\2022";
        }
    }
}
