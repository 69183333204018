@import "../../../../common.scss";

.fixed-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    box-shadow: 5px -20px 20px rgba(26, 49, 92, 0.05);
    border-radius: 15px 15px 0px 0px;
    display: flex;
    z-index: 3;

    .fixed-buttons-sort {
        margin: 0 0 0 20px;
       
    }
}
