@import "../../common.scss";

.cart-page {
    flex-direction: column;

    .main {
        width: 754px;
    }

    .summary-wrap {
        position: relative;
        max-width: 100%;
        width: 100%;

        .summary-wrap-overlay {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.7);
            height: 100%;
            width: 100%;

            .loader,
            .loader:after {
                width: 18px;
                height: 18px;
                border-left: 3px solid #fff;
            }

            .loader {
                font-size: 3px;
                left: calc(50% - 9px);
                top: calc(50% - 9px);
                border-right: 3px solid rgba(32, 81, 163, 1);
                border-top: 3px solid rgba(32, 81, 163, 1);
                border-bottom: 3px solid rgba(32, 81, 163, 1);

                width: 18px;
                height: 18px;
            }
        }
    }
    .header-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 40px 0 25px;
        box-sizing: border-box;

        h2 {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 24px;
            line-height: 20px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
            margin: 0;
        }

        .delete-btn {
            @extend .flex-centered;
            font-size: 13px;
            color: $original-gray;
            cursor: pointer;
            svg {
                margin-right: 13px;
            }
        }

        &.horizontal {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            .back-button {
                padding: 0 0 16px;
            }
        }

        @include phone {
            margin: 20px 0 20px;
            width: 100%;
            h2 {
                font-size: 18px;
                line-height: 22px;
            }
            &.horizontal {
                .back-button {
                    padding: 0 0 20px;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .main {
            min-width: 754px;
            max-width: 100%;
        }

        .content-side {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin: 0 0 40px 30px;
        }

        @include phone {
            flex-direction: column;
            .main {
                width: 100%;
                min-width: auto;
                order: 2;
            }

            .content-side {
                margin: 0 0 20px;
                order: 1;

                .payment-delay-wrap {
                    order: 2;
                }

                .summary-wrap {
                    width: 100%;
                    order: 1;
                }
            }
        }

        @include tablet {
            flex-direction: column;
            .main {
                width: 100%;
                min-width: auto;
                order: 2;
            }

            .content-side {
                margin: 0 0 20px;
                order: 1;

                .payment-delay-wrap {
                    order: 2;
                }

                .summary-wrap {
                    width: 100%;
                    order: 1;
                }
            }
        }
    }
    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 350px;

        h2 {
            font-family: Montserrat;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: -0.084px;
            margin: 0 0 15px;
            color: $very-dark-blue;
        }

        .icon {
            margin: 30px 0;
            max-width: 360px;
            width: 100%;
        }

        .sub-header {
            font-family: Open Sans;
            font-size: 14px;
            line-height: 150%;
            text-align: center;
            letter-spacing: -0.084px;

            max-width: 380px;
            width: 100%;
            margin: 0 0 25px;
            color: $original-gray;
        }
    }

    @media (max-width: 1140px) {
        .header-bar {
            padding: 0 20px;
        }
        .content {
            padding: 0 20px;
        }
    }

    @include phone {
        .header-bar {
            padding: 0;
            width: 100%;
        }
        .content {
            padding: 0;
        }

        .empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-bottom: 100px;

            h2 {
                font-family: Montserrat;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                letter-spacing: -0.084px;
                margin: 0 0 15px;
                color: $very-dark-blue;
            }

            .icon {
                margin: 30px 0;
                max-width: 280px;
                width: 100%;
            }

            .sub-header {
                font-family: Open Sans;
                font-size: 14px;
                line-height: 150%;
                text-align: center;
                letter-spacing: -0.084px;

                max-width: 380px;
                width: 100%;
                margin: 0 0 25px;
                color: $original-gray;
            }
        }
    }

    @include tablet {
        .header-bar {
            padding: 0;
            width: 100%;
        }
        .content {
            padding: 0;
        }
    }
}
