@import "../../../../common.scss";

.mobile-sorting {
    .modal {
        z-index: 5;

        .close {
            right: 20px;
            z-index: 2;
        }
    }
    .modal .modal-content {
        position: fixed;
        bottom: 0;
        max-width: 100%;
        width: 100%;
        height: 335px;
        padding: 40px 20px 110px 20px;
        box-sizing: border-box;
        animation-name: animatebottom;
        border-radius: 0;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;

        .caption {
            font-size: 20px;
            line-height: 24px;
            background: #fff;
            z-index: 1;
            width: 100%;
            margin: 0 0 20px;
        }
        .checkbox-wrapper {
            margin: 0 0 15px;

            .checkbox-button {
                border: 2px solid rgba(193, 207, 229, 0.5);
                box-sizing: border-box;
                border-radius: 19px;
                width: 24px;
                height: 24px;
            }
            .checkbox-title {
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;

                letter-spacing: -0.084px;
                color: $very-dark-blue;
            }
        }

        .mobile-sorting-list {
          height: 100%;
          overflow-y: scroll;
          margin: 0;
          padding: 0;          
        }

        .mobile-sorting-footer {
            @extend .flex-centered;
            justify-content: space-between;
            gap: 20px;
            position: fixed;
            bottom: 0;
            padding: 20px 20px 40px 20px;
            max-width: 100%;
            width: 100%;
            left: 0;
            box-sizing: border-box;
            background-color: #fff;
        }
    }
}
