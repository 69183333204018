@import '../../../common.scss';

.paging-bar {
    @extend .flex-centered;
    margin-top: 30px;
    display: flex;
    .paging {
        margin-left: 30px;
        background-color: white;
        justify-content: space-between;
        padding: 16px 25px;
        border-radius: 5px;
        flex: 1;
    }

    @include phone {
        justify-content: center;
    }
}