@import "../../../common.scss";
.address-list-modal {
    .modal {
        .address-list-modal-title {
            font-size: 24px;
            line-height: 29px;
            margin: 10px 0 20px;
        }
        .modal-content {
            position: relative;
            width: 520px;
            height: 617px;
            box-sizing: border-box;
            padding: 30px;
            overflow: hidden;
        }
    }
    .address-list {
        margin: 0;
        padding: 0 0 130px;
        box-sizing: border-box;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 225px);
        grid-template-rows: repeat(auto-fill, 156px);
        gap: 10px;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 0;
        }
        scrollbar-width: none;
        height: inherit;
    }

    .address-list-modal-loader {
        position: absolute;
        top: 0;
        left: 0;
        height: inherit;
        width: 100%;
        background-color: rgba($color: #fff, $alpha: 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .address-list-modal-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 520px;
        height: 30px;
        background-color: #fff;
    }

    @include phone {
        .address-list {
            grid-template-columns: repeat(1, 100%);
            grid-template-rows: none;
            gap: 15px;
        }

        .modal {
            .address-list-modal-title {
                font-size: 20px;
                line-height: 24px;
                margin: 20px 0 20px;
            }
            .modal-content {
                position: relative;
                max-width: 100%;
                width: 100%;
                height: 100vh;
                box-sizing: border-box;
                padding: 20px;
                overflow: hidden;
            }
        }
    }
}
