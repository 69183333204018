@import "../../../../common.scss";

.supplier-badge {
  color: $very-dark-blue;
  font-family: Open Sans;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: -0.084px;
  border-radius: 25px;
  padding: 7px 18px;
  box-sizing: border-box;
  width: max-content;
  @extend .flex-centered;

  background-color: rgba(32, 81, 163, 0.05);

  &.blue {
    background-color: $max-blue;
  }
  &.green {
    background-color: $green;
  }
  &.gray {
    background-color: $original-gray;
  }
  &.ghost {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    border: 1px solid rgba(11, 31, 53, 0.05);

    padding: 10px 22px;
    background-color: transparent;

    svg {
      fill: $original-blue;
      margin: 0 10px 0 0;
      width: 16px;
      height: 16px;
    }
  }
}
