@import "../../../common.scss";

.back-button {
  padding: 23px 0;
  > div {
    @extend .flex-centered;
    @extend %common-blue-link;
    font-size: 12px;
    line-height: 15px;
    svg {
      margin-right: 11px;
      width: 17px;
      height: 17px;
    }
  }
}
