@import "../../../common.scss";

.filter-box {
  margin-top: 30px;
  padding: 0;
  background-color: #ffffff;

  .title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2px;
    font-style: normal;
    color: #0b1f35;
    cursor: pointer;

    .filter-box-header {
      display: flex;
      align-items: center;
      svg {
        width: 6px;
        height: 6px;
        margin-left: 10px;
        fill: $original-blue;
      }
    }
    svg {
      transform: rotate(90deg);
      fill: $original-gray;
    }
  }
  .content-wrapper {
    padding-top: 18px;
    height: 0;
    min-height: 0;
    transform: scaleY(0);
    // transform-origin: top;
    overflow: hidden;
    transition: transform 0.26s ease;
    transition: min-height 0.26s ease;
  }

  .selected-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: -0.084px;

    color: $original-gray;
    visibility: visible;
  }

  &.opened {
    .title {
      svg {
        transform: rotate(-90deg);
      }
    }
    .selected-items {
      visibility: hidden;
    }
    .content-wrapper {
      height: 100%;
      min-height: 58px;
      transform: scaleY(1);
    }
  }
}
