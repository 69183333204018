@import "../../../common.scss";

.catalog-categories {
    .catalog-categories-title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
        margin: 40px 0 25px;
        padding: 0;
    }

    .catalog {
        display: grid;
        gap: 20px;
        // grid-template-columns: repeat(4, minmax(236px, 1fr));
        grid-template-columns: repeat(3, minmax(336px, 1fr));

        .catalog-item {
            height: 108px;
            padding: 10px 0 0 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            border-radius: 15px;

            .catalog-item-caption {
                max-width: 216px;
                margin: 0;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: -0.084px;
                color: $very-dark-blue;
            }

            .catalog-item-counter {
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                text-align: center;
                letter-spacing: -0.084px;
                color: $very-dark-blue;
                opacity: 0.7;
            }

            .icon {
                background: rgba($color: $original-gray, $alpha: 0.05);
                border-radius: 50%;
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
            }

            &.bread {
                background: #faedd3;
                background-image: url("../../../images/bread_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }

            &.frozen {
                background: #e1ebfb;
                background-image: url("../../../images/frost_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }

            &.veget {
                background: #e5f8ed;
                background-image: url("../../../images/vegetable_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }

            &.meat {
                background: #fbe9dd;
                background-image: url("../../../images/meat_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }

            &.dairy {
                background: #f2f2f7;
                background-image: url("../../../images/dairy_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }

            &.fish {
                background: #f9ecec;
                background-image: url("../../../images/fish_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }

            &.fruits {
                background: #fff8d9;
                background-image: url("../../../images/fruits_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }

            &.greens {
                background: #e9f7d0;
                background-image: url("../../../images/greens_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }
            &.vegets {
                background: #f2f2f7;
                background-image: url("../../../images/vegs_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }
            &.salads {
                background: #e5f8ed;
                background-image: url("../../../images/salads_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }

            &.berries {
                background: #fff9f9;
                background-image: url("../../../images/berries_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }
            &.mushrooms {
                background: #f1f9fd;
                background-image: url("../../../images/mushrooms_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }
            &.nuts {
                background: #e1ebfb;
                background-image: url("../../../images/nuts_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }
            &.poultry {
                background: #f1f9fd;
                background-image: url("../../../images/poultry_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }
            &.meat {
                background: #fbe9dd;
                background-image: url("../../../images/meat_category.png");
                background-repeat: no-repeat;
                background-size: 168px 170px;
                background-position: 198px -35px;
            }
        }

        .catalog-item-skeleton {
            padding: 60px 25px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 2px;

            .catalog-item-skeleton-caption {
                width: 100px;
                height: 30px;
                background-color: $original-blue-o005;
                border-radius: 5px;
            }

            .catalog-item-skeleton-text {
                width: 66px;
                height: 20px;
                background-color: $original-blue-o005;
                border-radius: 5px;
            }
        }
    }

    @include phone {
        .catalog-categories-title {
            font-size: 18px;
            margin: 30px 0 10px;
        }

        .catalog {
            gap: 10px;
            grid-template-columns: repeat(2, 1fr);
            .catalog-item {
                height: 102px;
                padding: 15px 0 0 15px;

                &.bread {
                    background-size: 98px 108px;
                    background-position: calc(100% + 19px) 4px;
                }

                &.frozen {
                    background-size: 98px 108px;
                    background-position: calc(100% + 19px) 4px;
                }

                &.veget {
                    background-size: 98px 108px;
                    background-position: calc(100% + 19px) 10px;
                }

                &.meat {
                    background-size: 98px 108px;
                    background-position: calc(100% + 19px) 4px;
                }

                &.dairy {
                    background-size: 98px 108px;
                    background-position: calc(100% + 19px) 4px;
                }

                &.fish {
                    background-size: 98px 108px;
                    background-position: calc(100% + 27px) 10px;
                }

                &.fruits {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }

                &.greens {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }
                &.vegets {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }
                &.salads {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }
                &.berries {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }
                &.mushrooms {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }
                &.nuts {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }
                &.poultry {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }
                &.meat {
                    background-size: 108px 108px;
                    background-position: calc(100% + 20px) 0;
                }

                .catalog-item-info {
                    .catalog-item-counter {
                        font-size: 12px;
                    }
                    .catalog-item-caption {
                        max-width: 115px;
                        font-size: 13px;
                        line-height: 16px;
                    }
                }
            }

            .catalog-item-skeleton {
                padding: 42px 20px;
            }
        }
    }

    @include tablet {
        .catalog-categories-title {
            font-size: 18px;
            margin: 30px 0 20px;
        }
        .catalog {
            gap: 10px;
            // grid-template-columns: repeat(4, minmax(174px, 1fr));
            grid-template-columns: repeat(3, minmax(236px, 1fr));

            .catalog-item {
                height: 90px;

                &.bread {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }

                &.frozen {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }

                &.veget {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }

                &.meat {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }

                &.dairy {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }

                &.fish {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }

                &.fruits {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }

                &.greens {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }
                &.vegets {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }
                &.salads {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }
                &.berries {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }
                &.mushrooms {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }
                &.nuts {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }
                &.poultry {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }
                &.meat {
                    background-size: 110px 122px;
                    background-position: calc(100% + 10px) -25px;
                }

                .catalog-item-info {
                    .catalog-item-caption {
                        max-width: 150px;
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
            .catalog-item-skeleton {
                padding: 15px 15px 50px;
            }
        }
    }

    @media (max-width: 440px) {
        padding: 0 20px;

        .catalog-categories-title {
            font-size: 18px;
        }

        .catalog {
            grid-template-columns: repeat(2, minmax(162px, 1fr));

            .catalog-item {
                align-items: flex-start;
                height: 90px;

                &.fruits {
                    background-position: calc(100% + 18px) -1px;
                }

                &.greens {
                    background-position: calc(100% + 18px) -1px;
                }
                &.veget {
                    background-position: calc(100% + 18px) -1px;
                }
                &.salads {
                    background-position: calc(100% + 18px) -1px;
                }
                &.berries {
                    background-position: calc(100% + 18px) -1px;
                }
                &.mushrooms {
                    background-position: calc(100% + 18px) -1px;
                }
                &.nuts {
                    background-position: calc(100% + 18px) -1px;
                }
                &.poultry {
                    background-position: calc(100% + 18px) -1px;
                }
                &.meat {
                    background-position: calc(100% + 18px) -1px;
                }
            }
            .catalog-item-skeleton {
                padding: 15px 15px 50px;
            }
        }
    }
    @media (max-width: 1140px) {
        padding: 0 20px;
    }
}
