@import "../../../common.scss";

.check-box-item {
  border: 2px solid rgba(193, 207, 229, 0.5);
  box-sizing: border-box;
  border-radius: 19px;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  min-width: 22px;
  display: inline-block;
  cursor: pointer;

  .bg {
    background-color: $original-blue;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }
}
