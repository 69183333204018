
@import '../../../common.scss';

.code-input-form {

    .code-inputs {
        display: flex;

        input {
            outline: none;
            background: none;
            text-align: center;
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #D6D7E4;
            border-radius: 4px;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.15px;
            color: #9B9FB3;

            &:not(:first-child) {
                margin-left: 20px;
            }
            &:focus, &.not-empty {
                border: 1px solid #2051A3;
                color: #2051A3;
                outline: none;
            }
        }
    }
    .send-code-again-timer {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: #9B9FB3;
        margin-top: 20px;

        span {
            color: #0B1F35;
            margin-left: 5px;
        }
    }
    .send-again-btn {
        @extend %common-blue-link;
        @extend .flex-centered;
        display: flex;
        margin-top: 20px;
        font-size: 12px;
        line-height: 15px;
        svg {
            margin-right: 11px;
            fill: $original-blue;
        }
    }
    .invalid-code {
        font-size: 10px;
        line-height: 14px;
        letter-spacing: -0.016em;
        color: #DC282E;
        margin-top: 10px;
    }
}
