@import "../../../../common.scss";
.review-rating {
  display: flex;
  align-items: center;
  letter-spacing: -0.084px;
  font-family: Montserrat;
  font-weight: 600;

  .review-rating-stars {
    margin: 0 18px 0 0;
  }

  .rating-txt {
    font-size: 14px;
    line-height: 17px;
    color: $very-dark-blue;
    margin-right: 10px;
  }
  .reviews {
    color: $original-gray;
    font-size: 12px;
    padding: 1px 0 0;
    // line-height: 15px;
  }
}
