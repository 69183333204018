@import "../../../common.scss";

$row-input-margin: 26px;

.registration {
    .header {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-top: 30px;
        margin-bottom: 20px;

        &.mb20 {
            margin-bottom: 20px;
        }

        &.first {
            margin-top: 0;
        }

        &.with-sub {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 5px;
        }

        .header-title {
            font-family: Montserrat;
            margin-right: 11px;
            margin-bottom: 0;
            margin-top: 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
        }

        .sub-title {
            font-family: "Open Sans";
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            margin-top: 5px;
            color: $original-gray;
        }

        > svg {
            fill: $original-blue;
        }
    }

    .address-delivery-types {
        display: flex;
        gap: 20px;

        .address-delivery-type {
            display: flex;
            align-items: center;
            padding: 15px;
            width: 250px;
            min-width: 250px;
            box-sizing: border-box;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: $very-dark-blue;
            cursor: pointer;

            border: 1px solid rgba(32, 81, 163, 0.1);
            border-radius: 8px;
            transition-duration: 0.3s;

            &.selected {
                border: 1px solid $original-blue;
                transition-duration: 0.3s;
            }
        }
    }
    .block {
        background: #fff;
        border-radius: 10px;
        margin-bottom: 30px;
        padding: 40px;

        .hint-text {
            flex-direction: row;
            display: flex;
            align-items: flex-start;

            font-family: Open Sans;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.15px;
            color: $original-gray;
            margin-bottom: 30px;
            svg {
                margin-right: 9px;
                margin-top: 4px;
            }
            span {
                max-width: 80%;
            }
        }
        .row-line {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            // svg {
            //   path {
            //     fill: $original-gray;
            //   }
            // }

            &.w100 {
                .Input,
                .PhoneInput,
                > div {
                    width: 100%;
                }
            }
            &.w50 {
                &.single {
                    .Input,
                    .PhoneInput,
                    .DatePicker {
                        width: calc(50% - #{$row-input-margin/2});
                    }
                }

                .Input,
                .PhoneInput,
                .DatePicker {
                    width: 50%;
                    &:first-child {
                        margin-right: $row-input-margin;
                    }
                }
            }
            &.w33 {
                .Input,
                .PhoneInput,
                .DatePicker {
                    width: 33.3%;
                    &:first-child,
                    &:nth-child(2) {
                        margin-right: $row-input-margin;
                    }
                }
            }
            &.w25 {
                .Input,
                .PhoneInput,
                .DatePicker {
                    width: 25%;
                    margin-right: $row-input-margin;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &.last {
                margin-bottom: 30px;
            }

            .addr-wrap {
                position: relative;

                .validation-msg {
                    position: absolute;
                    bottom: -17px;
                    left: 0;
                    @extend .validation-text;
                }
                .fillable-dropdown-wrap {
                    margin: 0;
                }
            }
            .Input,
            .PhoneInput {
                width: 50%;
                &.details {
                    height: 100px;
                }
            }
        }

        .delivery-box {
            @extend .round-panel;
            display: flex;
            flex-direction: row;
            align-items: center;
            box-sizing: border-box;
            margin-bottom: 30px;
            width: 100%;
            .icon {
                border: 1px solid rgba($color: $original-blue, $alpha: 0.1);
                border-radius: 15px;
                padding: 14px 13px;
                margin-right: 10px;
                display: flex;
            }
            .date-time {
                div {
                    &:first-child {
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 16px;
                        color: $very-dark-blue;
                    }
                    &:last-child {
                        font-family: Open Sans;
                        color: $original-gray;
                        font-size: 13px;
                        line-height: 18px;
                    }
                }
            }
        }

        .shadow-box {
            border: 1px solid rgba(32, 81, 163, 0.1);
            border-radius: 8px;
            box-sizing: border-box;
            padding: 15px;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: $very-dark-blue;

            &.active {
                border: 1px solid #2051a3;
            }

            .gray {
                color: $very-dark-blue;
                font-weight: 400;
                font-family: Open Sans;
            }
            .green {
                color: $green;
                font-weight: 600;
            }

            .payments {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 5px;
                }
            }
        }

        .email {
            margin-bottom: 30px;
        }

        .credit-cards {
            height: 18px;
            opacity: 1;
            // transition: all 0.15s ease;
            &.hidden {
                opacity: 0;
                // transition: all 0.15s ease;
            }
            svg {
                margin-right: 15px;
            }
        }

        .pay-methods {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 20px;

            .pay-method {
                width: 250px;
                height: 54px;
                margin: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;

                &:last-of-type {
                    margin: 0;
                }

                .pay-method-captions {
                    display: flex;
                    flex-direction: column;
                }

                &.disabled {
                    background: rgba(32, 81, 163, 0.05);
                    border: 1px solid rgba(32, 81, 163, 0.1);

                    .caption {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 16px;

                        color: $very-dark-blue;
                    }

                    .text {
                        font-family: "Open Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: $very-dark-blue;
                    }
                }

                .pay-method-text-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    svg {
                        path {
                            fill: $original-gray;
                        }
                    }
                }

                &:hover {
                    .payment-delay-hint {
                        .payment-delay-hint-text {
                            display: block;
                        }
                    }
                }

                .payment-delay-hint {
                    position: relative;
                    padding: 5px 0 0;
                    .payment-delay-hint-text {
                        position: absolute;
                        top: -136px;
                        right: -100px;
                        display: none;
                        width: 200px;
                        padding: 15px;
                        box-sizing: border-box;
                        background: #ffffff;
                        border: 1px solid rgba(32, 81, 163, 0.05);
                        box-shadow: 15px 15px 25px rgba(29, 30, 27, 0.05);
                        border-radius: 10px;

                        font-family: "Open Sans";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: $very-dark-blue;

                        .payment-delay-hint-phone {
                            margin: 0 0 0 5px;
                            font-weight: 600;
                            color: $original-blue;
                        }

                        &::before {
                            content: "";
                            border: solid transparent;
                            position: absolute;
                            top: 100%;
                            left: calc(50% - 10px);
                            border-top-color: #fff;
                            border-width: 10px;
                            margin-left: -8px;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }

    .company-info {
        @extend .round-panel;
        margin-bottom: 25px;
        .row {
            display: flex;
            flex-direction: row;
            margin-bottom: 13px;

            .label-data {
                display: flex;
                flex-direction: column;
                font-family: Open Sans;
                letter-spacing: 0.15px;
                min-width: 50%;

                .label {
                    font-size: 10px;
                    line-height: 14px;
                    color: $original-gray;
                    margin-bottom: 5px;
                }

                .data {
                    font-style: normal;
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }
    }

    .organisation-warning-content {
        border: 1px solid rgba(87, 125, 187, 0.1);
        border-radius: 10px;
        padding: 20px;

        .error-header {
            margin-bottom: 15px;
            @extend .flex-centered;

            .error-header-title {
                margin: 0;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.084px;
                color: $very-dark-blue;
            }

            .error-header-icon {
                width: 30px;
                height: 30px;
                margin: 0 0 0 10px;
                @extend .flex-centered;
                justify-content: center;
                background: rgba($original-blue, 0.05);
                border-radius: 100px;
                svg {
                    fill: $original-blue;
                }
            }
        }

        .error-msg {
            margin-bottom: 15px;
            font-family: Open Sans;
            font-size: 13px;
            line-height: 18px;
            color: $very-dark-blue;
        }
    }

    @include phone {
        .block {
            padding: 20px;
            margin-bottom: 0;

            .hint-text {
                margin-bottom: 20px;
            }

            .pay-methods {
                flex-direction: column;
                gap: 10px;
                .pay-method {
                    width: 100%;
                    &:nth-child(2) {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }

            .address-delivery-types {
                flex-direction: column;
                gap: 10px;

                .address-delivery-type {
                    width: 100%;
                }
            }
            .row-line.email {
                > .Input {
                    width: 100%;
                }
            }
        }
    }
}
