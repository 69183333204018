@import "../../../../common.scss";

.product-price {
    color: $very-dark-blue;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 5px;

    .measurement {
        padding-left: 5px;
        font-weight: 600;

        &.decimal {
            font-size: 14px;
            color: $very-dark-blue;

            &.galleryView {
                font-size: 14px;
            }
        }

        .product-price-bonus-icon {
            width: 14px;
            height: 14px;
        }
    }
   
    @include phone {
        font-size: 14px;
        line-height: 17px;
    }
}
.product-old-price {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.084px;
    color: $original-gray;
    text-decoration: line-through;
}
