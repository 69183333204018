@import "../../../common.scss";

.register-banner {
  max-width: 1140px;
  height: 335px;
  position: relative;

  .register-banner-slide {
    min-width: 365px;
    max-width: 365px;
    height: 335px;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    border-radius: 20px;
    cursor: pointer;
  }

  .register-banner-button {
    position: absolute;
    left: 50px;
    bottom: 50px;
    z-index: 2;

    .round-buttons {
      background: transparent;

      &:hover {
        background: $original-blue;
        color: #fff;

        svg {
          fill: #fff;
        }
      }
    }
  }

  .slider-btn-prev-banner {
    position: absolute;
    left: -22px;
    bottom: calc(50% - 22px);
    z-index: 2;
    transform: rotate(180deg);
  }

  .slider-btn-next-banner {
    position: absolute;
    right: -22px;
    bottom: calc(50% - 22px);
    z-index: 2;
  }

  .swiper-slide {
    border-radius: 20px;
    overflow: hidden;
  }

  .swiper-pagination {
    position: absolute;
    bottom: -23px;
    display: none;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 2;

    .swiper-pagination-bullet {
      background: $original-gray;
      width: 6px;
      height: 6px;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: $green;
        width: 12px;
        height: 12px;
      }
    }
  }
}
@media (max-width: 1140px) {
  .register-banner {
    .slider-btn-prev-banner {
      position: absolute;
      left: 2px;
    }

    .slider-btn-next-banner {
      position: absolute;
      right: 2px;
    }
  }
}

@include phone {
  .register-banner {
    max-width: 728px;
    height: auto;

    .register-banner-button {
      position: absolute;
      left: 30px;
      bottom: 30px;
    }

    .register-banner-slide {
      max-width: 265px;
      min-width: 265px;
      height: 240px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 10% 100%;
      border-radius: 20px;
    }

    .swiper-slide {
      max-width: 265px;
      min-width: 265px;
      height: 240px;
    }
  }
}

@include tablet {
  .register-banner {
    max-width: 728px;
    height: auto;

    .register-banner-button {
      position: absolute;
      left: 40px;
      bottom: 20px;
    }

    // .register-banner-slide {
    //   max-width: 305px;
    //   min-width: 305px;
    //   height: 280px;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   background-position: 100% 35%;
    //   border-radius: 20px;
    // }
  }
}

@media (max-width: 1068px) {
  .register-banner {
    .register-banner-button {
      position: absolute;
      left: 30px;
      bottom: 30px;
    }

     .register-banner-slide {
      max-width: 305px;
      min-width: 305px;
      height: 280px;
    }

    .swiper-slide {
      max-width: 305px;
      min-width: 305px;
      height: 280px;
    }
  }
}

@media (max-width: 699px) {
  .register-banner {
    width: 335px;
    height: auto;

    .swiper-pagination {
      display: flex;
    }

    .register-banner-slide {
      max-width: 265px;
      min-width: 265px;
      height: 240px;
    }

    .register-banner-button {
      position: absolute;
      left: 35px;
      bottom: 30px;
    }

    .swiper-slide {
      max-width: 265px;
      min-width: 265px;
      height: auto;
    }
  }
}
@media (max-width: 400px) {
  .register-banner {
    width: 335px;
    height: auto;

    .register-banner-button {
      position: absolute;
      left: 20px;
      bottom: 30px;
    }

    .swiper-slide {
      // width: 335px;
      height: auto;
    }
  }
}
