@import "../../../../common.scss";
.card-tag {
    width: fit-content;
    width: -moz-fit-content;
    height: 20px;

    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    margin: 0 0 15px;

    &.coming-soon {
        background: #f8f8fa;
    }

    .card-tag-text {
        width: fit-content;
        width: -moz-fit-content;
        padding: 2px 5px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.084px;
        color: #fff;

        border-radius: 30px;
        text-align: center;
        background: $original-blue;

        &.discount {
            background: $original-red;
        }

        &.bulk {
            background: $green;
        }

        &.promotion {
            background: $original-red;
            font-size: 11px;
        }

        &.pickup {
            background: $original-gray;
        }
    }

    .card-tag-date {
        display: flex;
        padding: 1px 10px 0 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.084px;
        color: $original-gray;
    }

    @include phone {
        max-width: 144px;
        margin: 0 0 10px;

        &:first-of-type {
            .card-tag-date {
                display: flex;
            }
        }
        .card-tag-date {
            display: none;
        }

        .card-tag-text {
            &.promotion {
                font-size: 10px;
            }

            &.pickup {
                font-size: 10px;
                margin: 0 0 5px;
            }
        }
    }
}
