@import "../../../../common.scss";

.stock-info {
    background: rgba($color: $blue-gray, $alpha: 0.5);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .stock-info-content {
        @extend .flex-centered;

        svg {
            margin-right: 18px;
        }
        .stock-info-title {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
            display: flex;
            flex-direction: column;
            .stock-info-text {
                font-size: 14px;
                line-height: 14px;
                color: $very-dark-blue;
                font-weight: 600;
            }
        }
    }

    .stock-info-warehouse {
        margin: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.084px;
        color: $original-gray;
    }

    .stock-info-map-link {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.084px;

        color: $original-blue;
    }
}
