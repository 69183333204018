@import "../../../common.scss";

.cart-block {
    margin-bottom: 30px;

    .item {
        .part {
            @extend .flex-centered;
            &.left {
                width: 500px;
            }

            &.right {
                padding: 0 0 0 20px;
                flex-grow: 1;
                display: flex;
                justify-content: space-between;

                &.business-only,
                &.unavailable {
                    justify-content: flex-end;
                }

                .price-container {
                    display: flex;
                    flex-direction: column;
                    max-width: 120px;
                    width: 100%;
                    padding: 0;
                }

                .price-change {
                    margin: -5px 0 0;
                    font-size: 12px;
                    font-weight: 400;
                    color: $green;
                    display: flex;
                    align-items: flex-start;
                    gap: 2px;

                    svg {
                        width: 14px;
                        height: 14px;
                        padding: 2px 0 0;
                        transform: rotate(90deg);
                        path {
                            fill: $green;
                        }
                    }

                    &.inc {
                        color: $original-red;

                        svg {
                            width: 14px;
                            height: 14px;
                            transform: rotate(90deg);
                            transform: rotate(270deg);
                            path {
                                fill: $original-red;
                            }
                        }
                    }
                }
            }

            .supplier-total {
                .supplier-total--item {
                    .product-price,
                    .decimal {
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 17px;
                        color: $very-dark-blue;
                    }
                }
            }
        }

        margin-bottom: 1px;
        &.supplier {
            padding: 17px 20px;
            background: #fff;
            font-size: 13px;
            line-height: 150%;
            border-radius: 10px 10px 0px 0px;
            height: 54px;
            display: flex;
            .name {
                font-family: Open Sans;
                line-height: 18px;
                color: $original-gray;
                .suppl-name {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    color: $original-blue;
                    padding-bottom: 8px;
                }
                .suppl-info {
                    @extend .flex-centered;
                    .supplier-status {
                        margin-left: 10px;
                        background: rgba(233, 238, 246, 0.4);
                        border-radius: 5px;
                        font-family: Open Sans;
                        font-size: 13px;
                        line-height: 18px;
                        color: $original-gray;
                        display: flex;
                        align-items: center;
                        padding: 4px 8px;
                        svg {
                            margin-right: 8px;
                        }

                        &.Paid {
                            svg {
                                fill: $green;
                            }
                        }

                        &.Processing {
                            svg {
                                fill: $original-gray;
                            }
                        }
                    }
                }
            }
            .total {
                // width: 70px;
                justify-content: left;
                display: flex;
                flex-direction: column;
                padding-right: 10px;
                .label {
                    color: $original-gray;
                    line-height: 150%;
                    padding-bottom: 8px;
                }

                .product-price {
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: $very-dark-blue;
                }
            }

            @include phone {
                margin-top: 20px;
                padding: 15px 20px;
                align-items: flex-start;
                margin: 0 0 2px;

                .total {
                    flex-grow: 1;
                    padding: 0;

                    .label,
                    .product-price {
                        text-align: right;
                        padding: 0;
                    }
                    &.business-only {
                        display: none;
                    }
                }

                .name {
                    .suppl-info {
                        justify-content: space-between;
                    }
                }

                .part {
                    &.left {
                        width: 70%;
                    }

                    &.right {
                        padding-left: 0px;
                        flex-grow: 1;
                        display: flex;
                        justify-content: right;
                    }
                }
            }
        }

        &.product {
            position: relative;
            display: flex;
            padding: 15px 20px;
            background-color: #fff;
            &:last-child {
                border-radius: 0px 0px 5px 5px;
            }

            &.canceled {
                opacity: 0.6;
                background: rgba(255, 255, 255, 0.4);
            }

            .product-overlay {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.4);
            }

            .info-area-icon {
                min-width: 80px;
                min-height: 80px;
                max-width: 80px;
                max-height: 80px;
                margin-right: 15px;
                border-radius: 5px;
                object-fit: cover;
                background-color: #f4f7fd;
            }
            .weight-info {
                min-width: 60px;
                max-width: 100%;
                // width: 100%;
                font-family: Open Sans;
                font-size: 13px;
                line-height: 18px;
                letter-spacing: 0.15px;
                color: $very-dark-blue;
                list-style: none;
                margin: 0;
                padding: 0;

                .weight-info-arrow {
                    margin: 0 0 0 3px;
                }

                .weight-info-item {
                    svg {
                        fill: $original-gray;
                        path {
                            fill: $original-gray;
                        }
                    }

                    &.original {
                        color: $original-gray;
                    }
                }
            }

            .info-area {
                display: flex;
                flex-direction: row;
            }

            .title {
                min-width: 200px;
                max-width: 242px;
                padding-right: 31px;
                font-family: Montserrat;
                font-weight: 500;
                font-size: 13px;
                line-height: 140%;
                color: $very-dark-blue;
                display: flex;
                flex-direction: column;
                gap: 5px;

                &.canceled {
                    min-width: auto;
                }

                a {
                    @extend %common-dark-link;
                    font-size: 13px;
                    width: 100%;
                    white-space: pre-line;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 140%;
                }

                .product-price {
                    padding: 0;
                    font-size: 14px;
                    line-height: 17px;
                }
            }

            .item-edit {
                .quantity-btn {
                    .btn-tooltip {
                        top: -54px;
                    }
                }

                .input-wrapper {
                    input {
                        height: 36px;
                        color: $very-dark-blue;
                        box-sizing: border-box;
                        width: 100%;
                        padding: 0 5px;
                        margin: 0;
                    }
                }
            }
            .total {
                // min-width: 100px;
                // max-width: 100%;
                // width: 100%;
                text-align: left;
                list-style: none;
                padding: 0;
                margin: 0;

                .total-item {
                    // display: flex;
                    align-items: center;

                    .total-item-arrow {
                        font-family: Open Sans;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 18px;
                        margin: 0 0 0 5px;
                        color: $original-gray;
                    }
                    .product-price,
                    .decimal {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: $very-dark-blue;
                    }

                    &.original {
                        .product-price,
                        .decimal {
                            color: $original-gray;
                        }
                    }
                }
            }

            .delete-btn {
                cursor: pointer;
            }

            &.mobile {
                flex-direction: column;
                box-sizing: border-box;
            }

            @media (max-width: 440px) {
                .info-area {
                    .title {
                        min-width: auto;
                        max-width: 100%;
                        padding: 0;
                    }
                }
                .edit-area {
                    .price-container {
                        &.unavailable {
                            display: none;
                        }
                    }
                }
            }

            @include phone {
                margin: 0;
                padding: 15px 20px;
                border-bottom: 1px solid $blue-gray;
                border-radius: 0;
                min-width: 100%;
                box-sizing: border-box;

                &:last-of-type {
                    border-bottom: none;
                }

                .info-area {
                    width: 100%;
                    margin-bottom: 18px;

                    .product-price {
                        padding: 0;
                    }

                    .title {
                        min-width: auto;
                        max-width: 100%;
                    }

                    .info-area-icon {
                        min-width: 60px;
                        min-height: 60px;
                        max-width: 60px;
                        max-height: 60px;
                        margin-right: 10px;
                        border-radius: 5px;
                    }

                    .delete-btn {
                        margin-left: auto;
                    }
                }

                .edit-area {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .total {
                        width: 100%;
                        // padding-left: 12px;
                        flex-grow: 1;
                        text-align: right;

                        &.business-only {
                            display: none;
                        }
                    }

                    .item-edit {
                        .quantity-btn {
                            width: 31px;
                            height: 31px;
                        }

                        .input-wrapper {
                            position: relative;
                            input {
                                width: 100%;
                                height: 35px;
                                -webkit-appearance: none;
                            }

                            // .measure {
                            //   top: 6px;
                            // }
                        }
                    }
                    .price-container {
                        display: flex;
                        flex-direction: column;
                        padding: 7px 0 0 12px;
                        width: fit-content;
                    }

                    .price-change {
                        margin: -5px 0 0;
                        font-size: 12px;
                        font-weight: 400;
                        color: $green;
                        display: flex;
                        align-items: flex-start;
                        gap: 2px;

                        svg {
                            width: 14px;
                            height: 14px;
                            padding: 2px 0 0;
                            transform: rotate(90deg);
                            path {
                                fill: $green;
                            }
                        }

                        &.inc {
                            color: $original-red;

                            svg {
                                width: 14px;
                                height: 14px;
                                transform: rotate(90deg);
                                transform: rotate(270deg);
                                path {
                                    fill: $original-red;
                                }
                            }
                        }
                    }
                }
            }

            .right-part-status {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .review-button {
                    margin: 10px 0 0;
                }
            }

            @include tablet {
                .title {
                    min-width: 200px;
                }
                .info-area {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .product-wrap {
        overflow: hidden;
        min-width: 100%;

        .product-content {
            @extend .flex-centered;
            transition-duration: 0.6s;

            &.next {
                transform: translateX(-60px);
            }

            &.prev {
                transform: translateX(0);
            }
        }

        .product-delete {
            min-width: 50px;
            height: 125px;
            margin: 0 10px;
            @extend .flex-centered;
            justify-content: center;
            background: rgba(#dc282e, 0.1);
            border-radius: 10px;
            .delete-btn {
                svg {
                    circle {
                        fill: none;
                    }
                    path {
                        fill: #dc282e;
                    }
                }
            }
        }
    }

    @include phone {
        margin-bottom: 20px;
    }

    @include tablet {
        .item {
            .part {
                &.right {
                    padding: 0 0 0 20px;
                    flex-grow: 1;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                }

                &.left {
                    margin-bottom: 15px;
                }
            }
        }
    }

    &.mini {
        border-bottom: 1px solid rgba($original-blue, 0.1);
        margin: 0;
        padding: 0 10px 15px;
        &:last-of-type {
            border-bottom: none;
        }

        .item {
            &.supplier {
                justify-content: space-between;
                font-size: 12px;
                line-height: 16px;
                height: auto;
                padding: 15px 20px;
                .part.left {
                    width: auto;
                }
                .part.right {
                    flex-grow: 0;
                    .product-price {
                        font-size: 13px;
                        line-height: 150%;
                    }
                }
            }
            &.product {
                padding: 0 20px;
                flex-direction: column;
                margin-bottom: 20px;

                &:nth-last-child(2) {
                    margin-bottom: 0px;
                }

                .info-area {
                    justify-content: space-between;
                }
                .title {
                    min-width: auto;
                    max-width: auto;
                }
                .part.left {
                    margin-bottom: 15px;
                    width: auto;
                    justify-content: space-between;
                    .info-area-icon {
                        min-width: 60px;
                        min-height: 60px;
                        max-width: 60px;
                        max-height: 60px;
                    }
                }
                .part.right {
                    justify-content: space-between;
                    padding: 0;

                    .price-container {
                        max-width: fit-content;
                        max-width: -moz-fit-content;
                    }

                    .item-unavailable {
                        min-width: auto;
                    }
                }
            }
        }
    }
}
