@import "../../../../common.scss";

.edit-order {
    .order-info {
        background: #ffffff;
        box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);
        border-radius: 5px;
        padding: 40px;
        .top {
            padding-bottom: 30px;
            a {
                @extend .flex-centered;
                @extend %common-blue-link;
                svg {
                    margin-right: 11px;
                }
            }
        }

        .line {
            padding-bottom: 28px;
            @extend .flex-centered;
            width: 100%;
            &.justified {
                justify-content: space-between;
            }

            &.last {
                padding-bottom: 0;
                align-items: flex-start;
            }

            .left-side {
                display: flex;
                font-family: Open Sans;

                &.column {
                    flex-direction: column;
                    width: 45%;
                }

                .order-number {
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.084px;
                    color: $very-dark-blue;
                }

                .order-date {
                    font-size: 14px;
                    line-height: 140%;
                    color: $very-dark-blue;

                    &::before {
                        padding: 0 8px;
                        color: $gray-light;
                        content: "\2022";
                        font-size: 16px;
                    }
                }

                .data-label {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: -0.084px;
                    color: $very-dark-blue;
                    margin: 0;
                }

                .data-value {
                    font-size: 13px;
                    line-height: 140%;
                    color: $very-dark-blue;
                    display: flex;
                    align-items: center;
                    max-width: 95%;
                    padding: 0 0 5px;
                }

                .data-divider {
                    width: 4px;
                    height: 4px;
                    background: $original-gray;
                    border-radius: 100%;
                }

                .left-side-header {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin: 0 0 5px;

                    .left-side-icon {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 26px;
                        height: 26px;
                        background: rgba(32, 81, 163, 0.05);
                        border-radius: 18px;
                        cursor: pointer;

                        svg {
                            path {
                                fill: $original-blue;
                            }
                        }
                    }
                }

                .left-side-address {
                    max-width: 95%;
                    padding: 0 0 10px;
                    box-sizing: border-box;
                    border-bottom: 1px solid rgba($original-gray, 0.05);
                    margin: 0 0 15px;
                }

                .left-side-contacts {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    padding: 0 0 20px;
                }

                .left-side-buttons {
                    display: flex;
                    gap: 20px;

                    .add-to-cart-button {
                        position: relative;
                        .loader,
                        .loader:after {
                            width: 18px;
                            height: 18px;
                            border-left: 2px solid rgba(32, 81, 163, 1);
                        }
                        .loader {
                            font-size: 2px;
                            position: absolute;
                            left: calc(50% - 10px);
                            top: calc(50% - 10px);
                            -webkit-transform: translateZ(0);
                            -ms-transform: translateZ(0);
                            transform: translateZ(0);
                            -webkit-animation: load8 1.1s infinite linear;
                            animation: load8 1.1s infinite linear;

                            border-right: 2px solid #fff;
                            border-top: 2px solid #fff;
                            border-bottom: 2px solid #fff;
                        }
                    }
                }

                .change-button {
                    @extend %common-blue-link;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    padding-bottom: 30px;
                }

                .cancel-button {
                    @extend %common-gray-link;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                }
            }

            .right-side {
                color: $original-gray;
                font-family: Open Sans;
                font-size: 14px;
                line-height: 20px;

                &.w50 {
                    width: 55%;
                    display: flex;
                }

                .payment {
                    position: relative;
                    border: 1px solid $blue-gray;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    font-family: Open Sans;
                    padding: 20px;
                    width: 100%;

                    .payment-method {
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 18px;
                        color: $original-blue;
                        padding-bottom: 15px;
                        @extend .flex-centered;

                        svg {
                            margin-right: 6px;
                        }
                    }
                    .pay-line {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 0 5px;

                        &.with-border {
                            padding: 10px 0 10px;
                            border-bottom: 1px solid rgba($original-blue, 0.05);
                        }
                        &.more-padding {
                            padding: 10px 0 0;
                        }

                        .pay-line-timer {
                            padding: 5px 10px;
                            font-family: "Open Sans";
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 150%;
                            text-align: right;
                            color: $original-blue;
                            background: rgba(32, 81, 163, 0.05);
                            border-radius: 5px;
                            box-sizing: border-box;
                        }

                        .pay-line-caption {
                            font-size: 14px;
                            line-height: 150%;
                            color: $original-gray;
                            margin: 0;

                            &.total {
                                font-family: "Montserrat";
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 20px;
                                letter-spacing: -0.084px;
                                color: $very-dark-blue;
                            }
                        }

                        .pay-line-sum {
                            font-family: "Montserrat";
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 150%;
                            text-align: right;
                            color: $very-dark-blue;

                            &.total {
                                font-family: "Montserrat";
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 20px;
                                letter-spacing: -0.084px;
                                color: $very-dark-blue;
                            }

                            &.discount {
                                color: #ed5055;
                            }
                        }
                    }

                    // .round-button {
                    //     color: white;
                    // }

                    .return-desc {
                        .return-title {
                            margin: 0 0 10px;
                            font-family: Montserrat;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 150%;
                            color: $very-dark-blue;
                        }

                        .return-desc-row {
                            display: flex;
                            justify-content: space-between;
                            margin: 0 0 10px;

                            font-family: Open Sans;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 150%;
                            color: $original-gray;
                            .return-accent {
                                font-weight: 600;
                            }
                        }
                    }

                    .other-status {
                        background: rgba(30, 176, 106, 0.05);
                        border-radius: 5px;
                        padding: 12.5px 15px;
                        font-family: Montserrat;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        text-align: center;
                        color: $green;
                        @extend .flex-centered;
                        width: fit-content;

                        svg {
                            margin-right: 13px;
                            fill: $green;
                        }

                        &.return {
                            color: $original-gray;
                            background: rgba(155, 159, 179, 0.1);
                            margin: 20px 0;
                            svg {
                                fill: $original-gray;
                            }
                        }
                    }

                    .payment-agreement {
                        padding: 10px 0 0;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .payment-disclaimer {
                            margin: 0;
                            font-family: "Open Sans";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 150%;
                            color: $very-dark-blue;
                        }

                        .loader,
                        .loader:after {
                            width: 18px;
                            height: 18px;
                            border-left: 2px solid rgba(32, 81, 163, 1);
                        }
                        .loader {
                            font-size: 2px;
                            position: absolute;
                            left: calc(50% - 10px);
                            top: calc(50% - 10px);
                            -webkit-transform: translateZ(0);
                            -ms-transform: translateZ(0);
                            transform: translateZ(0);
                            -webkit-animation: load8 1.1s infinite linear;
                            animation: load8 1.1s infinite linear;

                            border-right: 2px solid #fff;
                            border-top: 2px solid #fff;
                            border-bottom: 2px solid #fff;
                        }
                    }

                    .payment-overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: rgba(255, 255, 255, 0.7);
                        height: 100%;
                        width: 100%;

                        .loader,
                        .loader:after {
                            width: 18px;
                            height: 18px;
                            border-left: 3px solid #fff;
                        }

                        .loader {
                            font-size: 3px;
                            left: calc(50% - 9px);
                            top: calc(50% - 9px);
                            border-right: 3px solid rgba(32, 81, 163, 1);
                            border-top: 3px solid rgba(32, 81, 163, 1);
                            border-bottom: 3px solid rgba(32, 81, 163, 1);

                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .payment-docs {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 20px 0 0;
                    margin: 10px 0 0;
                    list-style: none;
                    border-top: 1px solid rgba($original-blue, 0.05);
                }

                .payment-docs-button {
                    margin: 20px 0 0;
                }

                .payment-button {
                    padding: 10px 0 0;
                    position: relative;
                    width: fit-content;
                    width: -moz-fit-content;

                    .loader,
                    .loader:after {
                        width: 18px;
                        height: 18px;
                        border-left: 2px solid rgba(32, 81, 163, 1);
                    }
                    .loader {
                        font-size: 2px;
                        position: absolute;
                        left: calc(50% - 10px);
                        top: calc(50% - 10px);
                        -webkit-transform: translateZ(0);
                        -ms-transform: translateZ(0);
                        transform: translateZ(0);
                        -webkit-animation: load8 1.1s infinite linear;
                        animation: load8 1.1s infinite linear;

                        border-right: 2px solid #fff;
                        border-top: 2px solid #fff;
                        border-bottom: 2px solid #fff;
                    }
                }
            }
        }

        .status-info {
            display: flex;
            flex-direction: column;
            padding-right: 15px;
            .label {
                font-family: Open Sans;
                font-size: 12px;
                line-height: 16px;
                color: $original-gray;
                padding-bottom: 10px;
            }
        }
    }

    h2 {
        font-family: Montserrat;
        font-style: normal;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
        padding: 40px 0 30px;
        margin: 0;
    }

    @include phone {
        .order-info {
            padding: 20px;
            .line {
                flex-direction: column;
                align-items: flex-start;
                padding-bottom: 15px;
                .left-side {
                    order: 2;
                    margin-bottom: 0;
                    &.column {
                        width: 100%;
                    }
                }

                .status-info {
                    margin-bottom: 15px;
                }

                .delivery-info {
                    margin-bottom: 5px;
                    width: 100%;
                    box-sizing: border-box;
                }
                .right-side {
                    order: 1;
                    margin-bottom: 20px;
                    &.w50 {
                        width: 100%;
                    }
                }
            }
        }
        h2 {
            padding: 32px 20px 18px;
            margin: 0;
        }

        .supplier-items {
            padding: 0 20px;
        }

        .cart-block .item.product .edit-area .total {
            text-align: center;
        }
    }
}
