@import "../../../common.scss";

@keyframes slide {
  0% {
    height: 0;
  }
  50% {
    min-height: 0;
  }
  100% {
    height: inherit;
    min-height: 50px;
  }
}

.search-bar {
  @extend .flex-centered;
  justify-content: space-between;
  flex-grow: 1;
  // margin: 0 18px;
  transition: margin-left 0.3s;
  padding: 16px 15px;
  background-color: white;
  height: 62px;
  box-sizing: border-box;

  ::placeholder {
    color: #9b9fb3;
  }
  &.active {
    margin-left: -$catalog-button-width - 1px;
    border-radius: 10px;
    border-left: 1px solid rgba(32, 81, 163, 0.2);
  }

  @include phone {
    padding: 10px 16px;
    margin: 0;
    border-radius: 25px;
    border: 1px solid rgba($color: $original-blue, $alpha: 0.2);
    height: 42px;

    &.active {
      border-radius: 25px;
      border-left: 1px solid rgba(32, 81, 163, 0.2);
      margin-left: -$catalog-button-width - 2px;
    }
  }

  input {
    margin-left: 12px;
    width: 100%;
    border: none;
    font-weight: normal;
    font-size: 15px;
    // line-height: 15px;

    &:focus {
      border: none;
      outline: none;
      // padding-left: 0;
    }

    @include phone {
      padding: 0;
    }
  }

  > svg {
    min-width: 18px;
    min-height: 18px;
    path {
      fill: $original-gray;
    }
  }

  .close-btn {
    @extend .flex-centered;
    font-size: 12px;
    font-weight: 300;
    margin-right: 25%;
    cursor: pointer;
    svg {
      path {
        fill: $very-dark-blue;
      }
    }

    @include phone {
      margin-right: 0px;
    }
  }
  .dropdown-content-wrapper {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 100px;
    background: #fff;
    box-shadow: 5px 20px 20px rgba(26, 49, 92, 0.05);
    border-top: 1px solid $bg-color;
    z-index: 3;

    .dropdown-content {
      width: 100%;
      color: $original-gray;
      display: none;
      height: 0;

      left: 0;
      overflow: hidden;

      &.active {
        display: block;
        animation: slide 1s linear;
        height: inherit;
      }
      .arrow {
        height: 18px;
      }
      .dropdown-item {
        @extend %common-dark-link;
        @extend .flex-centered;
        padding: 10px 20px;

        &:hover {
          background-color: $blue-gray;
          border-radius: 5px;

          .arrow {
            svg {
              path {
                fill: $original-blue;
              }
            }
          }
        }
        .description {
          width: 70%;
          padding-right: 10px;
          .name {
            font-size: 13px;
            font-family: Montserrat;
            color: $very-dark-blue;
          }
          .owner {
            margin-top: 1px;
            color: $original-gray;
            font-size: 12px;
          }
        }
        .price {
          color: $very-dark-blue;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          font-family: Montserrat;
          width: 27%;
          .decimal {
            font-size: 10px;
          }
        }
        .product-image {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          margin-right: 15px;
        }
        .arrow {
          // padding-right: 5px;
          svg {
            path {
              fill: $original-gray;
            }
          }
        }
      }
      .more-results {
        @extend .flex-centered;
        @extend %common-blue-link;
        font-size: 14px;
        padding: 20px 20px 30px;
        font-weight: 500;
        font-family: Montserrat;
        line-height: 17px;
        > svg {
          margin-left: 10px;
          path {
            fill: $original-blue;
          }
        }
      }
      .category-title {
        font-size: 14px;
        color: $original-gray;
        padding-top: 25px;
        padding-bottom: 21px;
        margin-top: 10px;
        padding: 0px 20px 10px;
      }
      .categories {
        padding-bottom: 10px;
        // padding-top: 5px;

        .dropdown-category-item {
          @extend .flex-centered;
          padding: 10px 20px 10px 10px;
          font-weight: 300;
          font-size: 13px;

          @include phone {
            border-bottom: 1px solid $blue-gray;
          }

          .description {
            @extend .flex-centered;
            margin-right: 55px;
            width: 70%;
            flex-wrap: wrap;
            cursor: pointer;

            @include phone {
              margin-right: 0;
              width: 100%;
            }

            a {
              white-space: nowrap;
              padding: 5px 0;
              margin: 0 10px;
              // &:first {
              //   margin-left: 0;
              // }
              // &:last {
              //   margin-right: 0;
              // }
            }

            b {
              font-weight: 600;
            }

            svg {
              fill: $original-gray;
            }
          }
          .info {
            @extend .flex-centered;
            width: 27%;
            font-family: Montserrat;
            font-weight: 500;
            line-height: 16px;
          }
          .sub-category {
            @extend %common-dark-link;
            font-size: 13px;
            pointer-events: none;

            &:hover {
              color: $original-blue;
            }

            &:last-of-type {
              pointer-events: all;
            }
          }

          &:hover {
            background-color: $blue-gray;
            border-radius: 5px;
          }

          svg {
            path {
              fill: $original-gray;
            }
          }
        }
      }
    }

    @include phone {
      .dropdown-content {
        &.container {
          padding: 0;
        }
      }
    }
  }
}
