@import '../../../common.scss';

.edit-wrapper {
    font-family: Open Sans;
    letter-spacing: 0.15px;
    
    margin-bottom: 28px;
    
    &.active {
        margin-bottom: 0;
    }

    &.last{
        margin-bottom: 0;
    }
    .caption {
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 5px;
        color: $original-gray;
    }
    .value {
        font-size: 13px;
        line-height: 18px;
        color: $very-dark-blue;
    }
}