@import "../../../common.scss";

.popup-modal {
  position: absolute;
  top: 54px;
  max-width: 390px;
  width: 100%;
  padding: 30px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: -5px -20px 20px rgba(26, 49, 92, 0.05), 5px 20px 20px rgba(26, 49, 92, 0.05);
  border-radius: 15px;
  z-index: 3;
  &.empty {
    height: 390px;
    overflow: hidden;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 20px;
    box-sizing: border-box;
  }
  &__header-content {
    position: relative;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  }
  &__title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.084px;
    margin: 0;
    color: #0b1f35;
  }
  &__button {
    display: flex;
    border: none;
    outline: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    cursor: pointer;
    color: #2051a3;
    background-color: transparent;
  }
  &__closeIcon {
    display: none;
  }
  &__content {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    overflow: scroll;
    background-color: #fff;
    &::-webkit-scrollbar {
      width: 0;
    }
    scrollbar-width: none;
  }
  &__overlay {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 17px;
    width: 355px;
    height: 35px;
    background: linear-gradient(0deg, #ffffff 54.95%, rgba(255, 255, 255, 0) 111.63%);
    z-index: 5;
  }

  @include phone {
    right: 0;
    top: 70px;
    max-width: 100%;
    min-height: 100vh;
    padding: 25px 20px 0;
    border-radius: 25px 25px 0px 0px;
    &__content {
      min-height: 100%;
    }
    &__header {
      align-items: flex-start;
      padding: 0 0 25px;
    }
    &__title {
      margin: 0 0 10px;
    }
    &__closeIcon {
      display: flex;
    }
    &__header-content {
      max-width: 100%;
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__overlay {
      display: none;
    }
  }
}
