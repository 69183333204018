@import "../../common.scss";
$card-width: 272px;
$card-space: 20px;

.search-page-container {
    width: 100%;
}

.card-container {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 20px;
    column-gap: $card-space;
    grid-template-columns: repeat(3, 270px);

    &.single-card {
        grid-template-columns: repeat(1, 270px);
    }

    @include tablet {
        row-gap: 10px;
        column-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(236px, 1fr));

        &.single-card {
            grid-template-columns: repeat(1, 236px);
        }
    }

    @include phone {
        padding: 20px 20px 0 20px;
        row-gap: 5px;
        column-gap: 5px;
        grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));

        &.single-card {
            grid-template-columns: repeat(1, 165px);
        }
    }
}

.product-list {
    margin-bottom: 55px;

    .product-list-title {
        margin: 0 0 30px;
    }

    .empty-list-msg {
        line-height: 50px;
        background: #fff;
        padding-left: 16px;
        font-size: 14px;
    }

    .product-count {
        font-size: 13px;
        padding-left: 10px;
        color: $original-gray;
    }

    .products-content {
        align-items: flex-start;

        .products-content-left {
          width: 268px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-right: 30px;
        }
    }

    .container {
        > .top {
            padding: 23px 0;
        }

        &.column {
            flex-direction: column;
            .product-list-title {
                margin-bottom: 20px;
            }
            margin-bottom: 10px;
        }
    }

    @include phone {
        .product-list-title {
            font-size: 18px;
            line-height: 22px;
            margin: 0;
        }

        .product-count {
            font-size: 10px;
            line-height: 12px;
        }

        .products-content {
            padding: 0;

            main {
                min-width: auto;
                width: 100%;
            }
        }
        .empty-list-msg {
            margin: 20px 0 0;
        }
    }
}
