@import "../../../../common.scss";

.faq {
  flex-direction: column;

  .faq-item {
    padding-top: 15px;
    border-top: rgba($color: $very-dark-blue, $alpha: 0.07) 1px solid;

    &:last-of-type {
      border-bottom: rgba($color: $very-dark-blue, $alpha: 0.07) 1px solid;
    }

    .faq-header {
      padding-bottom: 15px;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      cursor: pointer;

      .state-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 11px;
          height: 11px;
          fill: $original-gray;
        }
        background-color: rgba($color: $original-blue, $alpha: 0.05);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        min-width: 40px;
        margin-left: 10px;
      }
    }

    .content {
      padding-right: 170px;
      display: block;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.15s ease-out;

      &.active {
        max-height: 100%;
        transition: max-height 0.15s ease-in;
        padding-bottom: 30px;
      }
    }
  }

  @include phone {
    &.container {
      padding: 0;
    }
    .faq-item {
      .content {
        padding-right: 40px;
      }
    }
  }
}
