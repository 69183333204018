@import "../../../../common.scss";

.delivery-time-slots-wrap {
    position: relative;
    max-width: 100%;
    width: 100%;

    .delivery-time-slots-dates {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 20px;
        overflow: scroll;

        .delivery-time-slots-date {
            min-width: 85px;
            padding: 10px;
            box-sizing: border-box;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            color: $original-gray;
            display: flex;
            flex-direction: column;
            gap: 5px;
            text-align: center;
            cursor: pointer;

            border-bottom: 2px solid #fff;
            transition-duration: 0.3s;

            > span {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
            }

            &.selected {
                border-bottom: 2px solid $original-blue;
                transition-duration: 0.3s;
                color: $original-blue;
            }
        }

        .delivery-time-dummy-date {
            min-width: 85px;
            padding: 10px;
            box-sizing: border-box;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            color: rgba($original-gray, 0.5);
            display: flex;
            flex-direction: column;
            gap: 5px;
            text-align: center;
            cursor: pointer;
            border: 1px solid rgba($original-gray, 0.1);
            border-radius: 10px;

            > span {
                width: 50%;
                height: 10px;
                background: rgba($original-gray, 0.1);
                border-radius: 10px;
                
                &:last-of-type {
                    width: 100%;
                }
            }
        }
    }

    .delivery-time-slots {
        position: relative;
        display: flex;
        gap: 10px;
        list-style: none;
        padding: 0;
        margin: 0;
        transition-duration: 0.3s;

        .delivery-time-slot {
            min-width: 105px;
            max-width: 105px;
            height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.084px;
            border: 1px solid rgba(11, 31, 53, 0.05);
            border-radius: 10px;
            color: $original-gray;
            cursor: pointer;

            .delivery-time-slot-text {
                font-weight: 600;
                color: $very-dark-blue;
            }

            &.selected {
                background: $original-blue;
                border: 1px solid $original-blue;
                color: #fff;

                .delivery-time-slot-text {
                    color: #fff;
                }
            }
        }

        .delivery-dummy-slot {
            min-width: 105px;
            max-width: 105px;
            padding: 10px;
            box-sizing: border-box;
            height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.084px;
            border: 1px solid rgba(11, 31, 53, 0.05);
            border-radius: 10px;
            color: $original-gray;
            cursor: pointer;

            .delivery-dummy-slot-plug {
                width: 100%;
                height: 15px;
                background: rgba($original-gray, 0.5);
                border-radius: 10px;
            }
        }

        .delivery-time-slots-loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#fff, 0.7);
            z-index: 1;

            .loader,
            .loader:after {
                width: 18px;
                height: 18px;
                border-left: 3px solid #fff;
            }

            .loader {
                font-size: 3px;
                left: calc(50% - 9px);
                top: calc(50% - 9px);
                border-right: 3px solid rgba(32, 81, 163, 1);
                border-top: 3px solid rgba(32, 81, 163, 1);
                border-bottom: 3px solid rgba(32, 81, 163, 1);

                width: 18px;
                height: 18px;
            }
        }

        > .swiper-container {
            width: 100%;
            > .swiper-wrapper {
                > .swiper-slide {
                    max-width: 105px;
                    min-width: 105px;
                }
            }
        }
    }

    .delivery-time-slot-buttons {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        gap: 10px;

        &.hidden {
            display: none;
        }

        .delivery-time-slot-button {
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(32, 81, 163, 0.1);
            box-shadow: 15px 10px 15px rgba(149, 162, 188, 0.05);
            border-radius: 15px;
            transition-duration: 0.3s;
            cursor: pointer;

            > svg {
                width: 22px;
                height: 22px;
            }

            &:hover {
                border: 1px solid $original-blue;

                > svg {
                    transition-duration: 0.3s;
                    path {
                        fill: $original-blue;
                    }
                }
            }

            > svg {
                transform: rotate(90deg);
            }

            &.next {
                > svg {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    @include phone {
        .delivery-time-slot-buttons {
            display: none;
        }
    }
}
