@import "../../../../common.scss";

.delivery-area {
  padding-bottom: 25px;
  .delivery-area-title {
    margin: 0 0 25px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }

  .modal {
    .modal-content {
      width: 80%;
      max-width: 944px;
      padding: 40px 30px 40px 40px;
      h2 {
        padding: 0;
      }
    }
  }

  @include phone {
    .modal {
      padding-top: 8%;
      backdrop-filter: blur(20px);
      .modal-content {
        padding: 30px 20px 20px;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
