.price-filter {
  height: 100px;
  .inputs {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    .Input {
      width: 50%;
      &:first-child {
        margin-right: 9px;
      }
      &:last-child {
        margin-left: 9px;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  .range {
    position: relative;
    margin: 0 10px;
    width: calc(100% - 20px);
  }
}
