.notification-popup-button {
  padding: 9px 25px;
  margin: 0;
  outline: none;
  border: 1px solid #2051a3;
  box-sizing: border-box;
  border-radius: 35px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: none;
  color: #2051a3;
  transition-duration: 0.6s;
  cursor: pointer;

  &.more {
    &:hover {
      background: #2051a3;
      color: #fff;
    }
  }

  &.read {
    position: fixed;
    z-index: 10;
    padding: 9px 20px;
    margin: 0;
    border: none;
    background: #2051a3;
    color: #fff;
    &:after {
      content: "";
      position: absolute;
      left: 18%;
      bottom: -15px;
      transform: translateX(-50%);
      border: 8px solid transparent;
      border-top: 10px solid #2051a3;
    }
  }
}
