
@import '../../../common.scss';

.drop-down {
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    svg {
        fill: $gray-light;
    }
    .drop-down-content {
      position: absolute;
      background: #FFFFFF;
      z-index: 1;
      width: 100%;
      cursor: pointer;
      box-shadow: 3px 3px 50px rgba(12, 51, 117, 0.1);
      border-radius: 5px;
      margin-top: 10px;
        .drop-down-item{
            color: $original-gray;
            padding: 15px 20px;
            font-family: Open Sans;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: -0.084px;
            &:hover{
                background-color: $blue-gray;
            }
        }
    }
    .Input {
        cursor: pointer;
        input {
            cursor: pointer;
        }
    }
}