@import "../../../common.scss";

.notification-btn {
  @extend .flex-centered;
  height: 34px;
  width: 34px;
  margin-right: 30px;
  min-width: 34px;
  position: relative;
  text-decoration: none;

  .notification-btn-underlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.5em;
    right: 0;
    height: 100%;
    width: 100%;

    transition: 0.5s ease;
    border-radius: 50%;
  }

  &:hover {
    .notification-btn-underlay {
      display: flex;
    }
  }

  sub {
    position: absolute;
    top: -7px;
    right: 3px;
    width: 16px;
    height: 16px;
    background: $original-red;
    line-height: 16px;
    font-size: 11px;
    color: #fff;
    border: 2px solid #fff;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
  }

  &.private {
    svg {
      fill: $private-color;
    }
    .notification-btn-underlay {
      background: rgba(30, 176, 106, 0.1);
    }
  }

  &.business {
    svg {
      fill: $business-color;
    }
    .notification-btn-underlay {
      background: rgba(32, 81, 163, 0.1);
    }
  }
}
