@import "../../../common.scss";

.slider-btn {
  background: #fff;
  border-radius: 15px;
  height: 45px;
  width: 45px;
  box-sizing: border-box;
  @extend .flex-centered;
  justify-content: center;

  cursor: pointer;
  transition: 0.2s ease;
  border: 1px solid rgba(32, 81, 163, 0.1);
  svg {
    width: 18px;
    height: 18px;
  }



  &.gray {
    svg {
      fill: $original-gray;
    }

    &:hover {
      background: $original-blue;
      svg {
        fill: #fff;
      }
    }
  }

  &.blue {
    svg {
      fill: $original-blue;
    }

    &:hover {
      background: $original-blue;
      svg {
        fill: #fff;
      }
    }
  }
}

.slider-btn-prev {
  transform: rotate(180deg);
}
.swiper-btn-hidden {
  display: none;
}