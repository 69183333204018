@import "../../common.scss";
.home-page {
  h2 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.084px;
    margin: 0;
    padding: 0;
  }

  .block {
    flex-direction: column;
    display: flex;
  }

  .home-page-loader-wrap {
    position: relative;
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: center;
      
    >.loader {
      top: 0;
      width: 50px;
      height: 50px;
    }
  }

  @include tablet {
    h2 {
      margin: 30px 0 20px;
      padding-left: 20px;
      font-size: 18px;
      line-height: 22px;
    }

    .block {
      padding: 0 0;
    }
  }

  @include phone {
    h2 {
      margin: 30px 0 20px;
      padding-left: 20px;
      font-size: 18px;
      line-height: 22px;
    }

    .block {
      padding: 0 0;
    }
  }
}
