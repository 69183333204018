@import "../../../common.scss";

.account-type {
  margin-left: 22px;
  height: 29px;

  .dropdown {
    position: relative;
    display: inline-block;

    .button {
      @extend .flex-centered;
      padding: 4px 12px 5px;
      padding-right: 6px;
      border: 1px solid #2051a3;
      box-sizing: border-box;
      border-radius: 20px;
      font-family: Open Sans;
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: $original-blue;
      cursor: pointer;

      svg {
        padding-left: 8px;
      }

      // &:hover {
      //   color: $original-white;
      // }
    }

    .dropdown-content {
      position: absolute;
      z-index: 5;
      display: none;

      .inner {
        margin-top: 10px;
        background: #ffffff;
        box-shadow: 15px 0px 35px rgba(21, 65, 139, 0.2);
        border-radius: 10px;
        padding: 15px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.084px;
        text-align: right;
        color: $original-gray;
        width: 130px;
      }

      .dropdown-item {
        min-height: 15px;
        @extend .flex-centered;
        justify-content: flex-start;
        margin-bottom: 15px;
        font-weight: normal;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        .icon-wrapper {
          @extend .flex-centered;
          justify-content: center;
          width: 15px;
          height: 15px;
          margin-right: 12px;
          svg {
            display: none;
          }
        }

        &:hover {
          color: $original-blue;
          font-weight: 600;

          svg {
            fill: $original-blue;
          }
        }

        &.selected {
          color: $original-blue;
          font-weight: 600;
          .icon-wrapper {
            svg {
              display: block;
              fill: $original-blue;
            }
          }
        }
      }

      a {
        border-bottom: dashed 1px $original-white;
        color: inherit;
        text-decoration: none;
        display: block;
      }
    }

    &:hover {
      .dropdown-content {
        display: block;
      }
    }
  }

  &.business {
    .dropdown {
      .button {
        color: $business-color;
        border-color: $business-color;
        svg {
          fill: $business-color;
        }
      }

      &:hover {
        .button {
          color: $original-white;
          background-color: $business-color;
          svg {
            fill: $original-white;
          }
        }
      }
    }
  }

  &.private {
    .dropdown {
      .button {
        color: $private-color;
        border-color: $private-color;
        svg {
          fill: $private-color;
        }
      }

      &:hover {
        .button {
          color: $original-white;
          background-color: $private-color;
          svg {
            fill: $original-white;
          }
        }
      }
    }
  }

  &.label {
    .dropdown {
      .button {
        cursor: default;
      }
    }
  }

  &.label.business {
    .dropdown {
      .button {
        padding-right: 12px;
      }
      &:hover {
        .button {
          color: $business-color;
          background-color: $original-white;
        }
      }
    }
  }

  &.label.private {
    .dropdown {
      .button {
        padding-right: 12px;
      }
      &:hover {
        .button {
          color: $private-color;
          background-color: $original-white;
        }
      }
    }
  }
}
