@import "../../../common.scss";

.profile-menu-bar {
  padding: 30px 25px 25px 0;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);

  h3 {
    padding: 0;
    margin: 0 0 20px;
    padding-left: 25px;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
  }

  .menu-item {
    @extend .flex-centered;
    margin-bottom: 20px;
    padding-left: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    .btn-link {
      font-family: Open Sans;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.084px;
      color: $original-gray;
      display: block;
      cursor: pointer;
      &:hover {
        font-weight: 600;
        color: $original-blue;
      }
    }

    &.active {
      border-left: solid 2px $original-blue;
      .btn-link {
        font-weight: 600;
        color: $original-blue;
      }
    }
  }
}
