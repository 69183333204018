@import "../../../../common.scss";

.reviews-modal {
  .modal .modal-content {
    max-width: 520px;
    border-radius: 20px;

    .reviews-modal-title {
      margin: 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
    }

    .reviews-modal-subtitle {
      margin: 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
    }
  }

  .reviews-modal-rating {
    display: flex;
    justify-content: space-between;
  }

  .reviews-modal-rating-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
  }

  .reviews-modal-button {
    position: relative;
    width: fit-content;
    width: -moz-fit-content;
    min-width: 162px;
    .loader,
    .loader:after {
      width: 20px;
      height: 20px;
    }
    .loader {
      font-size: 3px;
      position: absolute;
      left: calc(50% - 10px);
      top: 11px;
      border-left: 1.1em solid #fff;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
  }

  @media (max-width: 440px) {
    .modal .modal-content {
      max-width: 100%;
      width: 100%;
      padding: 20px 20px 40px;
      position: absolute;
      bottom: 20px;
      left: 0;
      border-radius: 20px 20px 0px 0px;
    }

    .reviews-modal-button {
      width: 100%;
    }
  }

  @include phone {
    .modal {
      padding-top: 5%;
    }

    .modal-content {
      border-radius: 20px;
    }
  }
}
