@import "../../../common.scss";

$btn-padding-size: 30px;

.active-bar {
  @extend .flex-centered;
  background-color: $original-white;
  border: 1px solid rgba($color: $original-blue, $alpha: 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 22px;

  position: relative;
  .collapsable {
    @extend .flex-centered;
    flex-grow: 1;
  }

  .user-bar {
    @extend .flex-centered;
    position: relative;
    // width: 148px;
    height: 49px;
    .login-button {
      @extend .flex-centered;
      @extend %common-blue-link;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      svg {
        margin-right: 10px;
        // margin-left: 10px;
      }
    }
  }

  @include phone {
    margin: 0;
    border: none;
    padding: 0;
  }

  &.business {
    .login-text {
      color: $business-color;
    }
    .login-button {
      @extend %common-green-link;
      svg {
        fill: $business-color;
      }
    }
  }
  &.private {
    .login-text {
      color: $private-color;
    }
    .login-button {
      @extend %common-blue-link;
      svg {
        fill: $private-color;
      }
    }
  }
}
