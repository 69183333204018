@import "../../../common.scss";

.popup-modal {
  right: -6px;
  padding: 30px 30px 0;
  &__empty-plug {
    max-width: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 85px 0 0;
  }
  &__empty-plug-icon {
    margin: 0 0 15px;
  }
  &__text {
    margin: 0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.084px;
    color: #9b9fb3;
  }

  @include phone {
    &__empty-plug {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }
}
