@import '../../../common.scss';

.breadcrumbs {
  display: flex;
  align-items: center;

  &__link {
    font-size: 14px;
    line-height: 20px;
    color: $original-gray;

    &::after {
      content: '\2014';
      display: inline-block;
      color: $gray-light;
      margin-left: 16px;
      margin-right: 15px;
      position: relative;
      top: -3px;
    }

    &:last-of-type::after {
      display: none;
    }

    &:last-of-type {
      pointer-events: none;
    }
  }
}
