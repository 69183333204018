@import "../../../common.scss";
.price-list-banner {
    background: #e5eff8;
    background-image: url("../../../images/priceBanner/price-list-bg.png");
    background-position: 110% top;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 40px;
    max-width: 1140px;
    border-radius: 20px;
    box-sizing: border-box;
    margin: 0 auto;

    .price-list-banner-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 372px;
        width: 100%;

        .price-list-banner-caption {
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.084px;
            color: $very-dark-blue;
            margin: 0;
        }

        .price-list-banner-button {
            .round-buttons {
                max-width: 220px;
                width: 220px;
            }
        }
    }

    @media (max-width: 1140px) {
        margin: 0 20px;
    }

    @media (max-width: 1000px) {
        background-image: url("../../../images/priceBanner/price-list-bg-tablet.png");
    }

    @media (max-width: 700px) {
        background-position: right bottom;
        background-size: inherit;
        background-image: url("../../../images/priceBanner/price-list-bg-mobile.png");
    }

    @media (max-width: 480px) {
        padding: 20px;
        background-size: 60%;
        background-position: 97% bottom;

        .price-list-banner-content {
            .price-list-banner-caption {
                font-size: 18px;
            }

            .price-list-banner-button {
                .round-buttons {
                    max-width: 200px;
                    width: 200px;
                    padding: 17px 20px;
                }
            }
        }
    }
}
