@import "../../../common.scss";

.address-modal {
    > .modal {
        z-index: 6;

        > .modal-content {
            width: 520px;
            padding: 30px 30px 40px;
            box-sizing: border-box;

            h2 {
                font-size: 24px;
                line-height: 29px;
                margin-top: 10px;
            }

            .address-modal-caption {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: -0.084px;
                color: $very-dark-blue;
            }

            .radio-group {
                margin-bottom: 25px;
            }

            .content {
                padding-bottom: 0;
            }

            .delivery-area {
                padding: 0;
            }

            .address-details {
                margin-top: 20px;

                .row-line {
                    margin: 0 0 15px;
                }
            }

            .address-wrap {
                margin-bottom: 5px;
            }

            .row-line.w50 .Input:first-child {
                margin-right: 10px;
            }

            .dialog-buttons {
                padding-top: 10px;
            }

            .pick-up-address {
                display: flex;
                flex-direction: column;
                border: 1px solid rgba(32, 81, 163, 0.1);
                padding: 20px;
                border-radius: 8px;

                .title {
                    display: flex;
                    flex-direction: row;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    color: $very-dark-blue;
                    padding-bottom: 10px;

                    .delivery-area {
                        margin: 0;
                        display: flex;

                        button {
                            padding: 0;
                        }

                        &::before {
                            padding: 0 10px;
                            color: $original-gray;
                            content: "\2022";
                        }
                    }
                }

                .pick-up-address-details {
                    display: flex;
                    flex-direction: row;
                    font-family: Open Sans;
                    font-size: 14px;
                    line-height: 150%;
                    letter-spacing: -0.084px;
                    color: $very-dark-blue;
                    margin-bottom: 20px;

                    svg {
                        fill: $original-gray;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .address-modal-types {
        display: flex;
        gap: 20px;
    }

    .address-modal-type {
        display: flex;
        align-items: center;
        padding: 15px;
        width: 50%;
        box-sizing: border-box;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: $very-dark-blue;
        cursor: pointer;

        border: 1px solid rgba(32, 81, 163, 0.1);
        border-radius: 8px;
        transition-duration: 0.3s;

        &.selected {
            border: 1px solid $original-blue;
            transition-duration: 0.3s;
        }
    }

    .address-modal-input {
        svg {
            path {
                fill: $original-gray;
            }
        }
        margin: 0 0 25px;

        &:last-of-type {
            margin: 0 0 20px;
        }
    }

    @include phone {
        .modal {
            .modal-content {
                position: fixed;
                max-width: 100%;
                width: 100%;
                opacity: 0;
                transition: all 0.25s ease;
                bottom: 0;
                left: 0;
                top: 90%;
                border-radius: 20px 20px 0px 0px;
                padding: 30px 20px 40px;
                &.show {
                    top: 8%;
                    opacity: 1;
                    transition: all 0.25s ease;
                }

                .dialog-buttons {
                    position: fixed;
                    bottom: 0;
                    padding: 40px 20px;
                    left: 0;
                    right: 0;
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}
