@import "../../../common.scss";

.wrapped-icon {
  //   padding: 8px;
  cursor: pointer;
  background: rgba(32, 81, 163, 0.05);
  border-radius: 18px;
  margin-left: 20px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: $original-blue;
  }
}
