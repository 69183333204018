@import "../../../../common.scss";

.supplier-sidebar {
  min-width: 260px;
  max-width: 260px;
  padding: 25px;
  background-color: #fff;
  margin-right: 30px;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 10px;
  .filter-box {
    margin-top: 20px;
  }
}
