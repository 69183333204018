@import "../../../common.scss";

.personal {
  display: flex;
  flex-direction: row;
  background: #fff;
  padding: 40px;

  .info {
    width: 60%;
    padding-right: 89px;
    > .Input,
    > .drop-down,
    > .special,
    > .DatePicker {
      margin-bottom: 25px;
    }

    .special:last-of-type {
      margin: 0 0 30px;
    }
    .notifications-title {
      margin: 0 0 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
    }


  }

  .modal {
    .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 360px;

      .image {
        text-align: center;
        margin-bottom: 25px;
      }

      .check-icon {
        @extend .flex-centered;
        border-radius: 18px;
        margin: 0 0 30px;
        width: 36px;
        height: 36px;
        background: rgba($color: $original-blue, $alpha: 0.1);
        justify-content: center;
        svg {
          fill: $original-blue;
        }
      }


      h2 {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;

        letter-spacing: -0.084px;

        color: #000000;
        margin: 0;
      }
      .sub-header {
        font-family: Open Sans;
        font-size: 13px;
        line-height: 18px;
        color: $original-gray;
        padding-bottom: 25px;

        .value {
          color: $very-dark-blue;
        }
        .change-btn {
          @extend %common-blue-link;
          font-weight: normal;
          padding-left: 5px;
        }
      }
      .dialog-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 30px 0 0;

        .cancel-button {
          @extend %common-gray-link;
          @extend .flex-centered;
          font-family: Montserrat;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          margin-left: 30px;
          cursor: pointer;
        }
      }
    }
  }

  @include phone {
    flex-direction: column-reverse;

    .info {
      width: 100%;
      padding-right: 0;
    }

    .modal {
      .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        width: 100%;

        .check-icon {
          margin: 0 0 25px;
        }


      }
    }
  }
}
