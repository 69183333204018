@import "../../../common.scss";

.tabs-content {
  .titles {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    width: fit-content;


    .title {
      text-align: center;
      cursor: pointer;
      width: fit-content;
      margin: 0 10px 0 0;

      .name {
        color: $original-gray;
        padding: 10px;
        font-family: Montserrat;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        width: 100%;
        box-sizing: border-box;
        white-space: nowrap;

      }

      &.active {
        .line {
          background-color: $original-blue;
          width: 100%;
          height: 2px;
          border-radius: 5px;
        }

        .name {
          color: $original-blue;
        }
      }
    }
  }


  @include phone {
    .titles {
      margin-bottom: 20px;
      width:100vw;
      overflow-x: scroll;

    }
  }

}
