@import "../../../common.scss";

.product-card {
    background: #fff;
    border-radius: 10px;
    position: relative;
    height: 429px;
    max-width: 270px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);
    }

    .product-card-tags {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        gap: 10px;

        .product-card-tags-column {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .card-tag {
                margin: 0;
            }
        }
    }

    .image {
        max-width: 100%;
        height: 230px;
        object-fit: contain;
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        cursor: pointer;
        transition-duration: 0.6s;

        &.small {
            height: 180px;
            transition-duration: 0.6s;
        }
       
    }

    .quantity {
        opacity: 1;
        transition-duration: 0.6s;

        &.visible {
            opacity: 0;
            transition-duration: 0.6s;
        }
    }

    .title {
        @extend %common-dark-link;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: $very-dark-blue;
        max-height: 70px;
        max-width: 480px;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .owner {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $original-blue;
        padding-top: 5px;
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &.column-view {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }

        .product-card-info {
            overflow: hidden;
            white-space: nowrap;

            .product-card-info-old-price {
                > .product-price {
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: -0.084px;
                    color: $original-gray;
                    text-decoration-line: line-through;
                    > .measurement {
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: -0.084px;
                        color: $original-gray;
                    }
                }
            }
        }
    }

    .favorites-button {
        position: absolute;
        right: 13px;
        top: 10px;
        z-index: 1;
    }

    .product-card-image-wrap {
        position: relative;
        margin: 0 0 15px;

        .product-card-sale {
            margin: 10px 0 0;
        }
    }

    &.skeleton {
        background: #fff;
        border-radius: 5px;
        position: relative;
        padding: 20px;

        .image {
            height: 230px;
            width: 100%;
            max-width: none;
            background-color: $original-blue-o005;
            border-radius: 9px;
            margin: 0 0 15px;
        }
        .title {
            height: 80px;
            border-radius: 5px;
            background-color: $original-blue-o005;
            margin-bottom: 4px;
            width: 100%;
        }

        .bottom {
            // bottom: 25px;
            // left: 20px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            .price-wrap {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .price {
                    width: 93px;
                    height: 20px;
                    border-radius: 2px;
                    background-color: $original-blue-o005;
                    margin-bottom: 11px;
                }

                .package {
                    border-radius: 2px;
                    background-color: $original-blue-o005;
                    width: 89px;
                    height: 16px;
                }
            }

            .actions {
                background-color: $original-blue-o005;
                border-radius: 40px;
                width: 40px;
                height: 40px;
            }
        }
    }
}

@include tablet {
    .product-card {
        padding: 15px;
        max-width: 100%;
        height: 395px;

        .image {
            height: 206px;

            &.small {
                height: 156px;
            }
        }

        &.skeleton {
            .image {
                height: 206px;
            }
            .title {
                height: 41px;
            }

            .bottom {
                .price-wrap {
                    .price {
                        width: 90px;
                        height: 18px;
                    }

                    .package {
                        width: 120px;
                        height: 16px;
                    }
                }
            }
        }
    }
}
@include phone {
    .product-card {
        padding: 10px;
        max-width: 100%;
        height: 294px;
        // overflow: hidden;
        text-overflow: ellipsis;

        .product-card-tags {
            gap: 5px;
        }

        .image {
            height: 145px;

            &.small {
                height: 95px;
            }
        }

        .title {
            font-size: 12px;
            line-height: 15px;
        }

        &.skeleton {
            padding: 10px;

            .image {
                height: 145px;
            }
            .title {
                height: 21px;
            }

            .bottom {
                .price-wrap {
                    .price {
                        width: 63px;
                        height: 17px;
                        margin-bottom: 5px;
                    }

                    .package {
                        width: 42px;
                        height: 16px;
                    }
                }
            }
        }
    }
}
