@import "../../../common.scss";

.hint {
  position: relative;
  cursor: pointer;
  display: flex;

  > svg {
    fill: $original-blue;
  }

  .hint-wrap {
    display: none;
    z-index: 1;
    position: absolute;
    bottom: 2px;
    left: 24px;

    .hint-content {
      box-sizing: border-box;
      cursor: default;
      box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);
      border-radius: 5px;
      background: $blue-gray;
      display: flex;
      flex-direction: row;
      padding: 10px;
      z-index: 1;
      width: 288px;
      color: $very-dark-blue;
      align-items: flex-start;
      justify-content: center;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-width: 0 0 20px 14px;
        border-color: transparent transparent $blue-gray transparent;
        bottom: 0;
        left: -8px;
        margin-top: -10px;
      }

      .content-wrap {
        font-family: Open Sans;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &.left {
    .hint-wrap {
      bottom: 2px;
      left: -330px;

      .hint-content {
        padding: 15px;
        z-index: 1;
        width: 288px;

        &:after {
          content: "";
          border-style: solid;
          border-width: 0 14px 20px 0;
          border-color: transparent transparent $blue-gray transparent;
          bottom: 0;
          right: -8px;
          left: initial;
          margin-top: -10px;
        }
      }
    }
  }

  &.top {
    .hint-wrap {
      bottom: 34px;
      left: -90px;

      .hint-content {
        background: $original-blue;
        width: 200px;
        color: #fff;

        &:after {
          border-width: 11px 8px 0;
          bottom: -10px;
          left: 50%;
          margin-left: -10px;
          // border-color: transparent transparent $blue-gray transparent;
          border-color: $original-blue transparent;
        }
      }
    }

    &.right {
      .hint-wrap {
        right: -15px;
        left: auto;
        .hint-content {
          &::after {
            left: 89%;
          }
        }
      }
    }
  }

  &:hover {
    .hint-wrap {
      display: block;
    }
  }

  &.error {
    svg {
      fill: $original-red;
    }
  }

  &.disabled {
    .hint-wrap {
      display: none;
    }
  }
}
