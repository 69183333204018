@import "../../../common.scss";

.payment-delay-info {
    background: linear-gradient(90deg, #cfdef3 0%, #e0eafc 100%);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.084px;

    .payment-delay-info-content {
        display: flex;
        flex-direction: column;
        padding: 15px 0 0;
        &:first-of-type {
            border-bottom: 1px solid rgba($very-dark-blue, 0.1);
            padding: 0 0 15px;
        }
    }

    .payment-delay-info-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px;

        svg {
            path {
                fill: $original-blue;
            }
        }
    }

    .payment-delay-info-title {
        color: $very-dark-blue;
        margin: 0;
        font-weight: 600;
        font-size: 14px;
    }

    .payment-delay-info-sum {
        color: $original-blue;
        font-size: 18px;
        line-height: 20px;

        &.small {
            font-size: 14px;
        }
    }

    .payment-delay-info-text {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
    }

    .payment-delay-info-footer {
        padding: 10px 0 0;
        display: flex;
        gap: 10px;

        .payment-delay-info-footer-item {
            width: 110px;
            padding: 10px;
            box-sizing: border-box;
            background: rgba(32, 81, 163, 0.05);
            border-radius: 10px;
        }
    }
}

.payment-delay-info-disclaimer {
    height: 57px;
    padding: 20px;
    margin: 0 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(237, 80, 85, 0.05);
    border: 1px solid rgba(237, 80, 85, 0.05);
    border-radius: 10px;
    box-sizing: border-box;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
    cursor: pointer;

    svg {
        path {
            fill: $very-dark-blue;
        }
    }
}
