@import "../../../common.scss";

.catalog-btn {
  @extend .flex-centered;
  @extend .font-montserrat;
  line-height: 18px;
  @extend %common-white-link;
  padding: 22px 20px;
  min-width: $catalog-button-width;
  box-sizing: border-box;
  border-radius: 10px 0 0 10px;
  height: 62px;

  &.private {
    background-color: $private-color;
  }

  &.business {
    background-color: $business-color;
  }

  svg {
    margin-right: 11px;
    fill: #fff;
    height: 18px;
    width: 18px;
  }

  @include phone {
    border-radius: 25px;
    padding: 12px 15px;
    font-size: 12px;
    line-height: 15px;
    min-width: 108px;
    margin-right: 20px;
    height: 40px;
    white-space: nowrap;
    svg {
      margin-right: 8px;
    }
  }
}
