@import "../../../common.scss";

.not-buyer-modal {
    .modal .modal-content {
        max-width: 460px;
        box-sizing: border-box;
    }
    .not-buyer-modal-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: $very-dark-blue;

        .not-buyer-modal-title {
            font-family: "Montserrat";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.084px;
            margin: 0;
        }

        .not-buyer-modal-icon-wrap {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .not-buyer-modal-icon {
            width: 60px;
            height: 60px;
            border-radius: 20px;
            background: rgba($original-blue, 0.05);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .not-buyer-modal-text {
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.084px;
            margin: 0;
        }

        .not-buyer-modal-buttons {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }

    @include phone {
        .modal {
            padding-top: 5%;
            .modal-content {
                border-radius: 15px;
            }
        }
    }

    @media (max-width: 440px) {
        .modal {
            padding-top: 0;
            .modal-content {
                position: absolute;
                bottom: 0;
                left: 0;
                max-width: 100%;
                width: 100%;
                padding: 26px 20px 30px 20px;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                border-radius: 0;
            }
        }

        .not-buyer-modal-title {
            font-size: 20px;
        }
    }
}
