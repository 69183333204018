@import "../../../common.scss";

.round-buttons {
    position: relative;
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;
    @extend .flex-centered;
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    outline: none;
    border: none;

    &.progress {
        cursor: not-allowed;
    }

    svg {
        margin-right: 8px;
        width: 16px;
        height: 16px;
    }

    //Размеры
    &.large {
        width: 198px;
        height: 50px;
        padding: 17px 30px;
        font-size: 14px;
        line-height: 17px;
    }
    &.medium {
        width: 162px;
        height: 40px;
        padding: 13px 20px;
        font-size: 12px;
        line-height: 15px;
    }
    &.small {
        width: 143px;
        // height: 28px;
        padding: 6px 15px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.084px;
        border-radius: 20px;

        svg {
            margin: 0 5px 0 0;
        }
    }

    //Типы
    &.primary {
        color: #fff;
        background: $original-blue;

        svg {
            fill: #fff;
            path {
                fill: #fff;
            }
        }

        &:hover {
            background: $original-blue;
            opacity: 0.9;
        }

        &-disabled {
            background: rgba(155, 159, 179, 0.5);
            cursor: not-allowed;

            &:hover {
                background: rgba(155, 159, 179, 0.5);
                opacity: 1;
            }
        }
    }

    &.secondary {
        border: 1px solid $original-blue;
        color: $original-blue;
        background: #fff;

        svg {
            fill: $original-blue;
        }

        &:hover {
            background: $original-blue;
            color: #fff;

            svg {
                fill: #fff;
                path {
                    fill: #fff;
                }
            }
        }

        &-disabled {
            border: 1px solid $original-blue;
            opacity: 0.5;
            color: $original-blue;
            // cursor: not-allowed;

            &:hover {
                border: 1px solid $original-blue;
                color: $original-blue;
                opacity: 1;
            }
        }
    }

    &.subtle {
        border: 1px solid rgba(32, 81, 163, 0.2);
        color: $original-blue;
        background: #fff;

        svg {
            fill: $original-blue;
        }

        &:hover {
            background: $original-blue;
            color: #fff;

            svg {
                fill: #fff;
                path {
                    fill: #fff;
                }
            }
        }

        &-disabled {
            border: 1px solid $original-blue;
            opacity: 0.5;
            color: $original-blue;
            cursor: not-allowed;

            &:hover {
                border: 1px solid $original-blue;
                color: $original-blue;
                opacity: 1;
            }
        }
    }

    &.empty {
        background: #fff;
        color: $very-dark-blue;
    }

    &.link {
        background: none;
        color: $original-blue;
        width: auto;
    }

    &.text {
        background: #fff;
        color: $original-blue;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
    }

    &.ghost {
        border: 1px solid rgba(32, 81, 163, 0.3);
        color: $original-blue;
        background: transparent;

        svg {
            fill: $original-blue;
        }

        &-disabled {
            border: 1px solid $original-blue;
            color: $original-blue;
            cursor: not-allowed;

            &:hover {
                border: 1px solid $original-blue;
                color: $original-blue;
                opacity: 1;
            }
        }
    }

    .button-loader {
        position: relative;
        height: 18px;

        .loader {
            position: absolute;
            width: 18px;
            height: 18px;
            border: 2px solid #fff;
            border-top-color: transparent;
            border-radius: 50%;
            animation: preloader 1s linear infinite;
            left: calc(50% - 11px);
            top: calc(50% - 11px);
            box-sizing: content-box;
            transform: translateZ(0);
            animation: load8 1.1s infinite linear;
        }
    }
}

@include phone {
    .round-buttons {
        &.subtle {
            padding: 10px 15px;
            font-size: 10px;
            line-height: 14px;
        }
    }
}
