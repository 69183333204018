@import "../../../../common.scss";

.thumbs-gallery {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 30px 0 0;

  &__slider-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 395px;
  }

  &__slider-side-slide {
    max-width: 55px;
    width: 100%;
    max-height: 55px;
    min-height: 55px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  &__slide-active {
    border: 3px solid #2051a3;
    box-sizing: border-box;
  }

  &__slider-side-image {
    width: 55px;
    height: 55px;
    object-fit: cover;
  }

  &__slider-main {
    max-width: 395px;
    max-height: 395px;
    padding: 0;

    .swiper-wrapper {
      align-items: center;
      // max-width: 900px;
    }

    .swiper-pagination {
      display: none;

      .swiper-pagination-bullet {
        background: #e9eef6;
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        background: $original-blue;
      }
    }
  }

  &__slider-main-slide {
    position: relative;
    max-width: 395px;
    max-height: 395px;
    min-width: 395px;
    min-height: 395px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    border-radius: 15px;
  }

  &__slider-main-image {
    max-width: 395px;
    max-height: 395px;
    width: 100%;

    height: 100%;
    object-fit: contain;
    border-radius: 15px;
  }

  .swiper-slide.thumbs-gallery__slider-side-slide.swiper-slide-visible.swiper-slide-thumb-active {
    border: 3px solid #2051a3;
    box-sizing: border-box;
  }

  .swiper-bnt {
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      fill: $very-dark-blue;
    }

    &:hover {
      svg {
        fill: $original-blue;
      }
    }
  }
  .swiper-btn-next,
  .swiper-btn-prev {
    transition: 0.2s ease;
    width: 24px;
    height: 24px;
  }

  .swiper-btn-prev {
    transform: rotate(270deg);
    margin: 0 0 16px;
  }

  .swiper-btn-next {
    transform: rotate(90deg);
    margin: 6px 0 0;
  }

  @include phone {
    padding: 0 0 20px 0;

    &__slider-side {
      display: none;
    }

    &__slider-main {
      max-width: 335px;
      max-height: 335px;
      padding: 0;
      .swiper-pagination {
        display: flex;
        justify-content: center;
      }
    }

    &__slider-main-slide {
      max-width: 335px;
      max-height: 335px;
      min-width: 320px;
      min-height: 335px;
      width: 100%;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 35px;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.1) 4.86%, rgba(0, 0, 0, 0) 95.2%);
        border-radius: 0px 0px 15px 15px;
      }
    }

    &__slider-main-image {
      max-width: 335px;
      max-height: 335px;
      width: 100%;
      object-fit: contain;
    }
  }

  @include tablet {
    flex-direction: column;
    padding: 0 20px 0 0;

    &__slider-side {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-height: 46px;
      max-width: 353px;
      order: 2;
    }

    &__slider-main {
      max-width: 353px;
      max-height: 353px;
      padding: 0;

      .swiper-pagination {
        display: none;
      }
    }

    &__slider-main-slide {
      max-width: 353px;
      max-height: 353px;
      min-width: 353px;
      min-height: 353px;
      width: 100%;
    }

    &__slider-main-image {
      max-width: 353px;
      max-height: 353px;
      width: 100%;
      object-fit: contain;
    }

    &__slider-side-slide {
      max-width: 46px;
      width: 100%;
      max-height: 46px;
      min-height: 46px;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
    }

    &__slider-side-image {
      width: 46px;
      height: 46px;
      object-fit: cover;
    }
    .swiper-btn-prev {
      transform: rotate(180deg);
      margin: 0 16px 0 0;
    }

    .swiper-btn-next {
      transform: rotate(0deg);
      margin: 0 0 0 16px;
    }
  }

  @media (max-width: 460px) {
    &__slider-main {
      position: relative;
      max-width: 100%;
      width: 100%;
      max-height: 400px;
      padding: 0;
      border-radius: 15px;
    }

    &__slider-main-slide {
      max-height: 400px;
      min-width: 100%;
      min-height: 353px;
    }

    &__slider-main-image {
      max-width: 100%;
      max-height: 400px;
      width: 100%;
      object-fit: contain;
    }
  }
  @media (max-width: 412px) {
    &__slider-main {
      max-width: 100%;
      width: 100%;
      max-height: 335px;
      padding: 0;
      border-radius: 15px;
    }

    &__slider-main-slide {
      max-height: 353px;
      min-width: 100%;
      min-height: 353px;
    }

    &__slider-main-image {
      max-width: 100%;
      max-height: 353px;
      width: 100%;
      object-fit: contain;
    }
  }
  @media (max-width: 376px) {
    &__slider-main {
      max-width: 335px;
      width: 100%;
      max-height: 335px;
      padding: 0;
      border-radius: 15px;
    }
    &__slider-main-slide {
      max-width: 335px;
      max-height: 335px;
      min-height: 280px;
    }

    &__slider-main-image {
      max-width: 335px;
      max-height: 335px;

      object-fit: contain;
    }
  }
}
