@import "../../../common.scss";

.tooltip {
  position: absolute;
  top: -125px;
  right: -72px;
  padding: 15px;
  width: 200px;
  height: 110px;
  background: #fff;
  border: 1px solid rgba(32, 81, 163, 0.05);
  box-sizing: border-box;
  box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);
  border-radius: 10px;

  .tooltip-content {
    align-items: center;
  }

  .tooltip-text {
    margin: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: $very-dark-blue;
  }
  .tooltip-link {
    text-decoration: none;
    margin: 0 0 0 3px;
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -18px;
    transform: translateX(-50%);
    border: 8px solid transparent;
    border-top: 10px solid #fff;
  }
}
