@import "../../../../common.scss";

.review-image-upload {
  background: rgba(32, 81, 163, 0.05);
  .review-image-upload-content {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' stroke='%239B9FB3FF' stroke-width='1' stroke-dasharray='10 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 6px;
    width: 100%;
    @extend .flex-centered;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }

  .review-image-upload-drop-zone {
    @extend .flex-centered;
    justify-content: center;
    flex-direction: column;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: $original-gray;
      }
    }
  }

  .review-image-upload-title {
    margin: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $original-gray;
  }

  .review-image-upload-button {
    border: 1px solid rgba(32, 81, 163, 0.3);
    box-sizing: border-box;
    border-radius: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;

    text-align: center;

    color: #2051a3;
    padding: 12px 30px;
    box-sizing: border-box;
  }
}
