@import "../../../../common.scss";
$yellow: #f2c94c;
$blue3: #56ccf2;

.order-status-wrapper {
  position: relative;

  .order-status {
    background: rgba(155, 159, 179, 0.1);
    border-radius: 5px;
    padding: 8px 10px;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: $original-gray;
    @extend .flex-centered;
    width: fit-content;

    svg {
      margin-right: 8px;
    }

    &.Processing {
      svg {
        fill: $yellow;
      }
    }
    &.Draft {
      svg {
        fill: $yellow;
      }
    }

    &.Received {
      svg {
        fill: $green;
      }
    }

    &.Paid {
      svg {
        fill: $green;
      }
    }

    &.Refunded {
      svg {
        fill: $green;
      }
    }

    &.Completed {
      svg {
        fill: $green;
      }
    }

    &.PartialyReceived {
      svg {
        fill: $green2;
      }
    }

    &.Delivery {
      svg {
        fill: $purlpe2;
      }
    }

    &.NotReceived {
      svg {
        fill: $very-dark-blue;
      }
    }

    &.New {
      svg {
        fill: $yellow;
      }
    }

    &.AwaitingPayment {
      svg {
        fill: $blue3;
      }
    }

    &.PendingApproval {
      svg {
        fill: $blue3;
      }
    }

    &.AwaitingShipment {
      svg {
        fill: $original-blue;
      }
    }

    &.Cancelled {
      svg {
        fill: $original-gray;
      }
    }

    &.SentForDelivery {
      svg {
        fill: $purlpe2; // ???
      }
    }

    &.Assembled {
      svg {
        fill: $original-blue; // ???
      }
    }

    &.NotApproved {
      svg {
        fill: $original-gray;
      }
    }

    &.Waiting {
      svg {
        fill: $blue3;
      }
    }

    &.Refunding {
      svg {
        fill: #000;
      }
    }

    &.Cancelling {
      svg {
        fill: #000;
      }
    }
    
    &.Failed {
      svg {
        fill: #000;
      }
    }
    
  }

  .dropdown-content {
    margin-top: 10px;
    position: absolute;
    background: #ffffff;
    -webkit-box-shadow: 0px 6px 10px 9px rgba(34, 60, 80, 0.07);
    -moz-box-shadow: 0px 6px 10px 9px rgba(34, 60, 80, 0.07);
    box-shadow: 0px 6px 10px 9px rgba(34, 60, 80, 0.07);
    border-radius: 10px;
    width: 170px;
    z-index: 5;
    padding: 25px;
    color: $original-gray;
    display: none;
    left: -50px;
    border-radius: 5px;
  }

  &:hover {
    .dropdown-content {
      display: block;
    }
  }

  .status-item {
    font-family: Open Sans;
    font-size: 13px;
    line-height: 11px;
    color: $original-gray;

    svg {
      width: 11px;
      height: 11px;
      margin-right: 8px;
      fill: $gray-light;
    }

    .before-item,
    .after-item {
      border-left: 1px solid $gray-light;
      top: -1px;
      position: relative;
      left: 5px;
    }

    .before-item {
      height: 12px;
    }

    .after-item {
      height: 13px;
    }

    &.active {
      .before-item,
      .after-item {
        border-left-color: $green;
      }

      svg {
        fill: $green;
      }
    }
  }

  @include phone {
    .dropdown-content {
      left: -10px;
    }
  }
}
