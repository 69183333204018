@import "../../../common.scss";

@mixin transition($prop: all, $time: 1s, $ease: linear) {
    -webkit-transition: $prop $time $ease;
    -moz-transition: $prop $time $ease;
    -o-transition: $prop $time $ease;
    -ms-transition: $prop $time $ease;
    transition: $prop $time $ease;
}

@mixin placeholder($color: $original-gray) {
    margin-top: -10px;
    height: fit-content;
    padding: 0 5px;
    background: #fff;
    font-family: "Open Sans";
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: $color;
    margin-left: -5px;
    left: 15px;
}

.Input {
    height: 45px;
    position: relative;
    z-index: 0;
    min-width: 100px;

    span {
        font-family: "Open Sans";
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.15px;
        color: $original-gray;
    }
    .leftImg {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        z-index: 1;
    }
    .rightImg {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        z-index: 1;
        // flex: none;
        // z-index: 1;
        // padding-right: 14px;
    }

    .input {
        outline: none;
        border: none;
        font-size: 14px;
        height: 100%;
        width: 100%;
        color: $very-dark-blue;
        font-family: "Open Sans";
        box-sizing: border-box;
        position: relative;
        background: none;
        padding: 0 15px;
        z-index: 1;

        &::placeholder {
            color: $original-gray;
            visibility: hidden;
        }
        &:valid {
            ~ .input-placeholder {
                @include placeholder($original-gray);
            }
        }
        &.error {
            ~ .input-placeholder {
                color: rgba(220, 40, 46, 0.58);
            }
        }

        &:disabled {
            ~ .input-placeholder {
                @include placeholder($original-gray);
                // background-color: transparent;
                background: linear-gradient(180deg, rgba(255, 255, 255, 1) 51%, rgba(155, 159, 179, 0) 50%, rgba(155, 159, 179, 0) 100%);
            }
        }
        &:focus {
            &::placeholder {
                visibility: visible;
            }

            &:not(.error) {
                ~ .input-placeholder {
                    @include placeholder($original-blue);
                }
                .leftImg {
                    svg {
                        fill: $original-blue;
                    }
                }
            }
        }
        &:focus {
            &:not(.error) {
                ~ .input-wrapper {
                    border: 2px solid $original-blue;
                    // .input-error {
                    //     display: none;
                    // }
                }
            }
        }

        &:focus ~ .leftImg {
            svg {
                fill: $original-blue;
            }
        }
    }
    &:hover > .input-wrapper {
        border: 1px solid $original-gray;
    }
    &.disabled {
        color: $very-dark-blue;
        opacity: 1;
        .input-placeholder {
            background: transparent;
        }

        .input-wrapper {
            background: $blue-gray;
        }
    }
    .input-wrapper {
        border: 1px solid #d6d7e4;
        border-radius: 4px;
        padding: 0 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        background: #fff;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
    }
    .input-placeholder {
        position: absolute;
        height: 100%;
        width: fit-content;
        max-width: calc(100% - 15px);
        border-radius: 4px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        pointer-events: none;
        top: 0;
        left: 15px;
        font-family: "Open Sans";
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: $original-gray;
        z-index: 1;
        overflow: hidden;

        @include transition(all, 0.15s, ease);
    }
    &.error {
        .input {
            color: rgba(220, 40, 46, 0.58);
        }
        .input-wrapper {
            border: 1px solid rgba(220, 40, 46, 0.58);
        }
    }

    .input-error {
        position: absolute;
        bottom: -20px;
        left: 0;
        color: rgba(220, 40, 46, 0.58);
        font-size: 12px;
        display: block;
    }
    &.all-img-exist {
        .input-placeholder {
            left: 40px;
            max-width: calc(100% - 80px);
        }
        input {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    &.left-img-exist {
        .input-placeholder {
            left: 40px;
            max-width: calc(100% - 40px);
        }
        input {
            padding-left: 40px;
        }
    }
    &.right-img-exist {
        display: inline-flex;
        width: 100%;
        .input-placeholder {
            max-width: calc(100% - 40px);
        }
    }
    &.textarea-input {
        height: fit-content;

        .input-placeholder {
            align-items: flex-start;
            padding-top: 12px;
            white-space: normal;
        }
    }

    textarea.input {
        height: 120px;
        resize: none;
        padding-top: 15px;
    }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: $original-gray;
    font-size: 14px;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;

    ~ .input-placeholder {
        @include placeholder($original-blue);
    }
}
