@import "../../../../common.scss";

.mobile-filter {
    .modal {
        z-index: 5;

        .close {
            right: 20px;
            z-index: 2;
        }
    }
    .modal .modal-content {
        position: fixed;
        bottom: 0;
        max-width: 100%;
        width: 100%;
        height: 600px;
        padding: 40px 20px 140px 20px;
        box-sizing: border-box;
        animation-name: animatebottom;
        border-radius: 0;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;

        .content {
            .reset-btn {
                .round-buttons {
                    min-width: 125px;
                }
            }
        }

        .bottom-bar {
            @extend .flex-centered;
            justify-content: space-between;
            gap: 20px;
            position: fixed;
            bottom: 0;
            padding: 20px 20px 40px 20px;
            max-width: 100%;
            width: 100%;
            left: 0;
            box-sizing: border-box;
            background-color: #fff;
        }

        .filter-categories-list {
            margin: 0;
            padding: 0;
            list-style: none;
            height: 100%;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0;
            }
            scrollbar-width: none;

            .filter-categories-item {
                position: relative;
                padding: 15px 0 25px 0;
                border-top: 1px solid $blue-gray;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                svg {
                    fill: $original-gray;
                    transform: rotate(90deg);
                    transition-duration: 0.3s;
                }

                &.selected {
                    svg {
                        transform: rotate(-90deg);
                        transition-duration: 0.3s;
                    }
                }

                .title {
                    position: relative;
                    font-family: Montserrat;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    letter-spacing: -0.084px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0;
                    padding: 0;
                    font-style: normal;
                    color: $very-dark-blue;

                    &.checked {
                        &::after {
                            position: relative;
                            top: 0;
                            right: -10px;
                            content: "";
                            width: 6px;
                            height: 6px;
                            background-color: $original-blue;
                            border-radius: 100px;
                        }
                    }
                }

                .selected {
                    position: absolute;
                    left: 0;
                    bottom: 9px;
                    color: $original-gray;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 140%;
                    > span {
                        padding-right: 5px;
                    }
                }
            }
        }

        .caption {
            font-size: 20px;
            line-height: 24px;
            background: #fff;
            z-index: 1;
            width: 100%;
            margin: 0 0 20px;
        }

        .filter-box {
            margin: 0;
            padding: 0;
            background-color: #fff;

            .price-filter {
                margin: 0 0 25px;
            }

            .filter-items-container {
                width: 100%;
            }

            .content {
                .checkbox-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 25px;
                    cursor: pointer;

                    .checkbox {
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        border: 2px solid rgba(193, 207, 229, 0.5);
                        box-sizing: border-box;
                        border-radius: 5px;
                    }

                    .checkbox-value {
                        font-family: Open Sans;
                        font-size: 12px;
                        line-height: 16px;
                        margin-left: 14px;
                        color: $very-dark-blue;
                    }
                }
            }
        }
    }
}
