@import "../../../common.scss";

.photo-panel {
    padding-bottom: 20px;

    .caption {
        color: $green-gray;
        font-family: Open Sans;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 20px;
    }

    .photo-wrapper {
        position: relative;
        width: fit-content;
    }

    .photo {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: 4px solid rgba(32, 81, 163, 0.2);
        object-fit: cover;
    }

    .photo-delete {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ed5055;
        border: 2px solid #ffffff;
        border-radius: 100px;
        cursor: pointer;

        svg {
            path {
                fill: #fff;
            }
        }
    }

    .drop-zone-wrapper {
        background: rgba(32, 81, 163, 0.05);
        width: 232px;
        height: 152px;
        border-radius: 6px;
        margin-bottom: 40px;

        .drop-zone {
            position: relative;
            margin-bottom: 40px;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' stroke='%239B9FB3FF' stroke-width='1' stroke-dasharray='10 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 6px;
            width: 100%;
            height: 152px;
            @extend .flex-centered;
            align-items: center;
            flex-direction: column;
            width: 232px;
            height: 152px;
            padding: 41px 30px;
            box-sizing: border-box;

            svg {
                fill: $original-gray;
                width: 22px;
                height: 20px;
                margin-bottom: 17px;
            }
            .uploader-caption {
                font-family: Open Sans;
                font-size: 12px;
                line-height: 16px;
                color: $original-gray;
                text-align: center;
            }

            .drop-zone-error {
                position: absolute;
                bottom: -25px;
                left: 0;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: -0.016em;
                color: #eb5757;
                height: 20px;
            }
        }
    }

    @keyframes preloader {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
    .spinner {
        width: 52px;
        height: 52px;
        display: block;
        overflow: hidden;
        background: none;
    }
    .preloader {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;
        div {
            position: absolute;
            width: 36px;
            height: 36px;
            border: 2px solid #2051a3;
            border-top-color: transparent;
            border-radius: 50%;
            animation: preloader 1s linear infinite;
            top: 26px;
            left: 26px;
            box-sizing: content-box;
        }
    }

    @include phone {
        .drop-zone-wrapper {
            width: 100%;

            .drop-zone {
                width: 100%;
            }
        }
    }
}
