@import "../../../../common.scss";

.order-docs-download {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .order-docs-download-content {
        display: flex;
    }

    .order-docs-download-icon {
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        margin: 0 15px 0 0;
        background: rgba(32, 81, 163, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .order-docs-download-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $original-gray;
    }

    .order-docs-download-title {
        max-width: 190px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        color: $very-dark-blue;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .order-docs-download-header {
        display: flex;
        gap: 5px;
    }

    .order-docs-download-date {
        margin: 0;
    }

    .order-docs-download-filetype {
        height: 16px;
        padding: 1px 8px;
        box-sizing: border-box;
        background: rgba(155, 159, 179, 0.1);
        border-radius: 4px;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
    }

    .order-docs-download-link {
        margin: 0 10px 0 0;
    }
}
