@import "../../../common.scss";

.supplier-page {
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 1140px;
  box-sizing: border-box;

  .supplier-info-gap {
    margin: 0 0 30px;
  }

  .favorites-button {
    margin-left: 0;
    margin-right: 10px;
  }

  .supplier-info {
    background-color: #fff;
    border-radius: 5px;
    padding: 40px;
    margin: 0 0 40px;

    .supplier-logo {
      position: absolute;
      left: 20px;
      top: 20px;

      .supplier-info-logo {
        width: 50px;
        height: 50px;
        margin: 0 10px 0 0;
        border-radius: 100px;
      }

      .supplier-info-abbr {
        width: 50px;
        height: 50px;
        margin: 0 10px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: $yellow;

        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        text-transform: uppercase;

        color: #fff;
      }
    }

    .supplier-info-intro {
      display: flex;
      flex-direction: row;

      .supplier-info-image-wrap {
        position: relative;
        margin: 0 30px 0 0;
        height: 300px;
        border-radius: 15px;
      }
      .supplier-info-image-plug {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        min-width: 533px;
        height: 300px;
        border-radius: 15px;
        background: $original-blue-o005;
      }
      .supplier-info-play-icon-wrap {
        position: absolute;
        top: 50%;
        right: 50%;
        width: fit-content;
        background-color: rgba(255, 255, 255, 0.3);
        transform: translate(50%, -50%);
        border-radius: 100%;
        padding: 4px;
      }
      .supplier-info-play-icon {
        width: 50px;
        height: 50px;
        background-color: $original-blue;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition-duration: 0.2s;

        img {
          width: 24px;
          height: 24px;
        }

        &:hover {
          width: 55px;
          height: 55px;
        }
      }

      .supplier-info-image {
        max-width: 100%;
        min-width: 533px;
        height: 300px;

        object-fit: cover;

        border-radius: 15px;

        button.ytp-large-play-button {
          display: none;
        }

        button.ytp-large-play-button svg {
          display: none;
        }
      }

      .description {
        color: $very-dark-blue;

        .supplier-info-profile {
          display: flex;
          align-items: center;
          margin: 0 0 20px;
        }
        .supplier-info-title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -0.084px;
          padding: 0;
          margin: 0;
        }
        .supplier-info-date {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 140%;
          color: $original-gray;
          margin: 0;
        }

        .supplier-info-buttons {
          display: flex;

          .supplier-info-phone {
            margin: 0 15px 0 0;
            svg {
              path {
                fill: #fff;
              }
            }
          }

          .supplier-info-email {
            width: 40px;
            height: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(32, 81, 163, 0.3);
            border-radius: 100%;
            box-sizing: border-box;

            svg {
              width: 18px;
              height: 18px;
              path {
                fill: $original-blue;
              }
            }
          }
        }

        .badges {
          margin: 0 0 20px;
          gap: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .introduction {
          font-family: Open Sans;
          font-size: 14px;
          line-height: 150%;
          letter-spacing: -0.084px;
          margin: 0 0 15px;
        }
      }
    }
    .gallery-wrapper {
      .gallery-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 15px;
      }
      .gallery-title {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.084px;
        padding: 0;
        margin: 0;
        color: $very-dark-blue;
      }

      .gallery-buttons {
        z-index: 2;
        display: flex;
        gap: 10px;
      }

      .swiper-wrapper {
        position: relative;
        z-index: 1;
        .swiper-slide {
          font-family: Open Sans;
          font-size: 14px;
          line-height: 150%;
          letter-spacing: -0.084px;
          color: $original-gray;
          max-width: 250px;
          min-width: 250px;

          .gallery-slide {
            border-radius: 10px;
            width: 250px;
            height: 140px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .supplier-products {
    display: flex;
    flex-direction: row;
    margin: 0 0 100px;
  }

  .supplier-decsription {
    max-width: 760px;
    width: 100%;
    margin: 0 0 100px;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .supplier-decsription-text {
      width: 100%;
      margin: 0;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      white-space: pre-line;
    }
    .supplier-decsription-header {
      display: flex;
      align-items: center;

      .supplier-decsription-caption {
        margin: 0 10px 0 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
      }
    }

    .supplier-decsription-approve {
      display: flex;
      align-items: center;

      .supplier-decsription-approve-icon {
        margin: 0 9px 0 0;
        padding: 2px 0 0;
      }
      .supplier-decsription-approve-text {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: $green;
      }
    }

    .supplier-decsription-requisites-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .supplier-decsription-requisites-row {
      display: flex;
    }

    .supplier-decsription-requisites-item {
      width: 50%;
      margin: 0 20px 0 0;
      display: flex;
      flex-direction: column;

      &:last-of-type {
        margin: 0;
      }
    }

    .supplier-decsription-requisites-title {
      margin: 0 0 5px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.15px;
      color: $original-gray;
    }

    .supplier-decsription-requisites-text {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.15px;
      color: $very-dark-blue;
    }
  }

  .supplier-certificates {
    margin: 0 0 100px;
    padding: 0;

    .supplier-certificate {
      display: flex;
      padding: 17px 20px;
      background-color: #fff;
      border-bottom: 1px solid $original-blue-o005;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.6);
      }

      &:first-of-type {
        border-radius: 7px 7px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 7px 7px;
      }

      .supplier-certificate-image {
        width: 36px;
        height: 36px;
        border-radius: 10px;
        margin: 0 15px 0 0;
        object-fit: cover;
      }
      .supplier-certificate-icon-wrapper {
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        margin: 0 15px 0 0;
        background: $original-blue-o2;

        z-index: 0;
        svg {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          fill: $original-blue;
          width: 16px;
          height: 16px;
          z-index: 1;
        }
      }

      .supplier-certificate-info {
        margin: 0 5px 0 0;

        .supplier-certificate-title {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          margin: 0;
        }
        .supplier-certificate-date {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: $original-gray;
          margin: 0;
          text-align: left;
        }
      }
      .supplier-certificate-label {
        width: 36px;
        height: 15px;
        margin: 0;

        background: rgba(155, 159, 179, 0.1);
        border-radius: 4px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        color: $original-gray;
        text-transform: uppercase;
      }
    }
  }
  .supplier-reviews {
    margin: 0 0 100px;
    padding: 34px 40px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;

    .supplier-reviews-header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 30px;
      padding: 0 0 20px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(10, 31, 54, 0.05);

      .supplier-reviews-button {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    .supplier-reviews-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 25px;
      .supplier-review {
        max-width: 775px;
        width: 100%;
      }

      .supplier-review-content {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
      }

      .supplier-review-avatar {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin: 0 10px 0 0;
      }
      .supplier-review-avatar-plug {
        width: 45px;
        height: 45px;
        border-radius: 100%;
        margin: 0 10px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: $yellow;

        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        text-transform: uppercase;

        color: #fff;
      }

      .supplier-review-title {
        margin: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
      }

      .supplier-review-date {
        margin: 0;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.084px;
        color: $original-gray;
      }
      .supplier-review-stars {
        margin: 0 0 20px;
        display: flex;
        gap: 5px;
      }
      .supplier-review-star {
        width: 10px;
        height: 10px;
        svg {
          width: 10px;
          height: 10px;
        }
      }

      .supplier-review-text {
        margin: 0;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
        opacity: 0.8;
      }
    }

    .supplier-reviews-empty {
      display: flex;
      flex-direction: column;
      align-items: center;

      .supplier-reviews-empty-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 20px;
        margin: 0 0 15px;
        background: $original-blue-o2;
        svg {
          width: 24px;
          height: 24px;
        }
      }

      .supplier-reviews-text {
        max-width: 250px;
        width: 100%;
        margin: 0;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.084px;
        color: $original-gray;
      }
    }
  }

  @media (max-width: 460px) {
    .supplier-decsription {
      .supplier-decsription-requisites-list {
        .supplier-decsription-requisites-row {
          flex-direction: column;
        }

        .supplier-decsription-requisites-item {
          width: 100%;
          margin: 0 0 20px;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  @include phone {
    margin: 30px auto 0;

    .supplier-tabs {
      .titles {
        padding: 0 20px;
        margin: 0;
      }
    }
    .supplier-info {
      background-color: #fff;
      border-radius: 5px;
      padding: 20px 0;
      margin: 0 0 30px;

      .supplier-info-gap {
        margin: 0 0 5px;
      }

      .supplier-info-intro {
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        .supplier-info-image-wrap {
          margin: 0 0 20px;
          max-width: fit-content;
          height: 200px;
        }

        .supplier-info-image {
          min-width: 335px;
          max-width: 100%;
          height: 200px;
          object-fit: cover;

          border-radius: 15px;
        }
        .supplier-info-image-plug {
          min-width: calc(100vw - 40px);
          height: 200px;
        }

        .description {
          color: $very-dark-blue;

          .supplier-info-profile {
            display: flex;
            align-items: center;
            margin: 0 0 25px;
          }
          .supplier-info-title {
            font-size: 18px;
            line-height: 20px;
          }

          .badges {
            margin: 0 0 15px;

            display: flex;
            flex-direction: row;
          }
        }
      }

      .gallery-wrapper {
        padding: 0 0 0 20px;
        .gallery-header {
          align-items: flex-start;
          margin: 0;
        }

        .gallery-title {
          margin: 0 0 20px;
        }

        .swiper-wrapper {
          .swiper-slide {
            max-width: 290px;
            min-width: 290px;

            .gallery-slide {
              width: 290px;
              height: 164px;
            }
          }
        }
      }
    }

    .supplier-products {
      margin: 0 0 50px;
      display: block;
    }

    .supplier-decsription {
      background-color: #fff;
      margin: 0 0 50px;
      padding: 30px 20px;
    }

    .supplier-certificates {
      margin: 0 0 50px;
    }

    .supplier-reviews {
      margin: 0 0 50px;
      padding: 30px 20px;

      .supplier-reviews-header {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
        margin: 0 0 30px;
        padding: 0 0 20px;
      }
    }
  }

  @include tablet {
    margin: 30px auto 0;

    .supplier-tabs {
      padding: 0 20px;
    }
    .supplier-info {
      background-color: #fff;
      border-radius: 5px;
      padding: 30px 0;
      margin: 0 0 30px;

      .supplier-info-gap {
        margin: 0 0 20px;
      }
      .introduction {
        padding: 0 20px;
        box-sizing: border-box;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
        margin: 0 0 30px;
      }
      .supplier-info-intro {
        display: flex;
        flex-direction: row;
        padding: 0 20px;

        .supplier-info-image-wrap {
          margin: 0 20px 0 0;
          height: 200px;
        }
        .supplier-info-image {
          max-width: 354px;
          min-width: 335px;
          height: 200px;

          object-fit: cover;

          border-radius: 15px;
        }
        .supplier-info-image-plug {
          max-width: 354px;
          min-width: 335px;
          height: 200px;
          margin: 0 0 20px;
        }
        .description {
          color: $very-dark-blue;

          .supplier-info-profile {
            display: flex;
            align-items: center;
            margin: 0 0 25px;
          }

          .supplier-info-title {
            font-size: 18px;
            line-height: 20px;
          }

          .badges {
            margin: 0 0 20px;

            display: flex;
            flex-direction: row;

            &:last-of-type {
              margin: 0;
            }
          }
        }
      }

      .gallery-wrapper {
        padding: 0 0 0 20px;
        .gallery-header {
          align-items: flex-start;
          margin: 0;
        }

        .gallery-title {
          margin: 0 0 20px;
        }

        .swiper-wrapper {
          .swiper-slide {
            max-width: 250px;
            min-width: 250px;

            .gallery-slide {
              width: 250px;
              height: 140px;
            }
          }
        }
      }
    }

    .supplier-products {
      margin: 0 0 50px;

      display: block;
    }

    .supplier-decsription {
      background-color: #fff;
      margin: 0 0 50px;
      padding: 30px 20px;
    }

    .supplier-certificates {
      margin: 0 0 50px;
    }
  }
}
