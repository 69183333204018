@import "../../../common.scss";

.favorites.card-container {
  @include tablet {
    padding: 20px 20px 0;
    grid-template-columns: repeat(2, minmax(236px, 1fr));
  }

  @include phone {
    grid-template-columns: repeat(2, minmax(165px, 1fr));
  }

  @media (max-width: 374px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.favorite-suppliers {
  .alphabet-container {
    .letter {
      background: rgba(32, 81, 163, 0.05);
      border-radius: 10px;
      margin: 20px 0;
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
      padding: 10px 15px;
      width: fit-content;
    }

    @include phone {
      .letter {
        margin-left: 20px;
      }
    }
  }

  .suppliers-container {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: repeat(3, 270px);

    @include tablet {
      grid-template-columns: repeat(2, minmax(236px, 1fr));
    }

    @include phone {
      padding: 0 20px;
      grid-template-columns: repeat(1, minmax(165px, 1fr));
    }
  }
}

.loader-wrapper {
  align-items: center;
  display: flex;
  padding-top: 100px;
  position: relative;
}

.mobile-favorites {
  margin: 20px 20px 0;
  width: calc(100% - 40px);
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  h2 {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
    margin: 20px 0 0;
  }
  .sub-header {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.084px;
    color: $original-gray;
    margin: 5px 0 0;
  }

  @include phone {
    .empty {
      padding-top: 90px;

      .icon {
        max-width: 372px;
        width: 100%;
      }
    }
  }
}
