@import "../../common.scss";

.helpRoot {
  padding-top: 30px;
  padding-bottom: 40px;

  .container {
    flex-direction: column;
  }

  .container .helpRoot__title {
    margin: 20px 0 30px;
  }

  &--mobile {
    .container {
      padding: 30px 20px;
      background-color: #fff;
      box-shadow: 5px 20px 20px rgba(26, 49, 92, 0.03);
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      min-width: initial;
    }

    .navigation-block {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #f8f8fa;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      & > p {
        margin-bottom: 0;
      }

      & > p > svg {
        right: 2px;
      }

      &__questions {
        margin-top: 25px;
      }

      &__questions a {
        margin-bottom: 25px;
      }
    }
  }

  &__title-mobile {
    padding: 0 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.084px;
    color: #0b1f35;
    margin: 30px auto 20px;
    display: flex;
    max-width: 1140px;
  }
}

.helpRoot__grid {
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 30px;
    margin-bottom: 30px;
    max-width: calc((100% - 60px) / 3);
    width: 100%;
  }

  & > div:nth-of-type(3n + 3) {
    margin-right: 0;
  }
}

.helpQARoot__requisites-columns {
  display: flex;
}

.helpQARoot__requisites-row {
  margin: 0 0 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  padding: 0;

  &:last-of-type {
    margin: 0;
  }

  .helpQARoot__requisites {
    margin: 0 30px 0 0;
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.15px;
    color: $original-gray;

    &:last-of-type {
      margin: 0;
    }
  }

  .helpQARoot__requisites-title {
    margin: 0 0 5px;
    font-size: 10px;
    line-height: 14px;
  }

  .helpQARoot__requisites-text {
    margin: 0;
    font-size: 13px;
    line-height: 18px;
    color: $very-dark-blue;
  }
}

.helpQARoot__requisites-column {
  margin: 0 30px 0 0;
  max-width: 50%;
  width: 100%;

  &:last-of-type {
    margin: 0;
  }
}

.helpQARoot__requisites-caption {
  height: 20px;
  margin: 0 0 25px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.084px;
  color: $very-dark-blue;
}

.helpQARoot__requisites-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
