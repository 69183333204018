@import "../../../common.scss";

.hint-input-wrap {
  position: relative;
  height: 45px;

  .validation-msg {
    position: absolute;
    top: 45px;
    left: 0;
    @extend .validation-text;
  }

  .hint {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    svg {
      background: transparent;
    }
  }

  .Input {
    .input {
      padding-right: 36px;
    }
  }

  &.unconfirmed {
    .hint {
      > svg {
        fill: $original-red;
      }
    }
  }

  &.confirmed {
    .hint {
      > svg {
        fill: $green;
      }
    }
  }
}
