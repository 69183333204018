@import "/../../../../../common.scss";

.order-list-item-list-sceleton-list {
    max-width: 100%;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
    gap: 10px;
    display: flex;
    flex-direction: column;

    .order-list-item-sceleton {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;

        .order-list-item-sceleton-row {
            max-width: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .order-list-item-sceleton-list {
            max-width: 100%;
            width: 100%;
            display: flex;
            gap: 20px;
            list-style: none;
            padding: 0;
            margin: 0;

            .order-list-item-sceleton-item {
                max-width: 140px;
                width: 100%;
                height: 34px;
                background: rgba(32, 81, 163, 0.05);
                border-radius: 5px;

                &:last-of-type {
                    max-width: 115px;
                }
            }
        }

        .order-list-item-sceleton-sum {
            max-width: 150px;
            width: 100%;
            height: 34px;
            background: rgba(32, 81, 163, 0.05);
            border-radius: 5px;
        }

        .order-list-item-sceleton-pics {
            display: flex;
            gap: 10px;
            list-style: none;
            padding: 0;
            margin: 0;

            .order-list-item-sceleton-pic {
                width: 38px;
                height: 38px;
                background: rgba(32, 81, 163, 0.05);
                border-radius: 5px;
            }
        }

        .order-list-item-sceleton-button {
            width: 160px;
            height: 40px;
            background: rgba(32, 81, 163, 0.05);
            border-radius: 20px;
        }
    }

    @include phone {
        padding: 0 20px;

        .order-list-item-sceleton {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;

            .order-list-item-sceleton-row {
                max-width: 100%;
                flex-direction: column;
                gap: 20px;
            }

            .order-list-item-sceleton-pics {
                justify-content: flex-end;
            }
        }

        .order-list-item-sceleton-button {
            display: none;
            width: 160px;
            height: 40px;
            background: rgba(32, 81, 163, 0.05);
            border-radius: 20px;
        }
    }
}

.order-list-item-sceleton-caption {
    width: 65px;
    height: 20px;
    margin: 0 0 20px;
    background: rgba(32, 81, 163, 0.05);
    border-radius: 5px;

    @include phone {
        margin: 0 0 20px 20px;
    }
}
