@import "../../../common.scss";

.profile-btn {
  .avatar-btn {
    @extend .flex-centered;
    @extend %common-white-link;
    .avatar {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      border: 2px solid rgba(32, 81, 163, 0.3);
      object-fit: cover;

      &.empty {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Open Sans;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }
    }

    svg {
      margin-left: 5px;
    }
  }

  .info {
    border-bottom: 1px solid rgba(155, 159, 179, 0.1);
    text-align: left;
    padding-bottom: 14px;
    margin-bottom: 14px;

    .profile-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.084px;
      color: $very-dark-blue;
    }
    .profile-status {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.084px;
      color: $original-gray;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
    .dropdown-btn {
      border: none;
      color: $original-white;
      position: relative;
      top: 8px;
      border-bottom: dashed 1px $original-white;
      padding-bottom: 16px;
      &:hover {
        color: $original-white;
      }

      @include phone {
        border-bottom: none;
        padding-bottom: 0;
        top: 0;
      }
    }
    .dropdown-content {
      position: absolute;
      background: #ffffff;
      box-shadow: 15px 0px 35px rgba(21, 65, 139, 0.2);
      border-radius: 10px;
      max-width: 200px;
      width: 200px;
      z-index: 5;
      padding: 15px;
      text-align: right;
      // padding-top: 10px;
      color: $original-gray;
      display: none;
      left: -75px;

      //TODO: refactor
      @media (max-width: 1300px) {
        right: -40px;
      }

      .dropdown-item {
        min-height: 14px;
        @extend .flex-centered;
        justify-content: flex-start;
        margin-bottom: 10px;
        font-weight: normal;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        .icon-wrapper {
          @extend .flex-centered;
          justify-content: center;
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }
        svg {
          fill: $original-gray;
        }
        &:hover {
          color: $original-blue;
          font-weight: 500;

          svg {
            fill: $original-blue;
          }
        }
      }

      a {
        border-bottom: dashed 1px $original-white;
        color: inherit;
        text-decoration: none;
        display: block;
      }
    }

    &:hover {
      .dropdown-content {
        display: block;
      }
    }
  }
}
