@import "../../../common.scss";

.top-bar {
    padding: 20px 0 0;
    @extend .flex-centered;
    justify-content: space-between;
    width: 100%;
    .items {
        flex-direction: row;
        display: flex;
        .logo {
            @extend .flex-centered;

            > img {
                width: 120px;
            }
        }
    }

    .links {
        @extend .flex-centered;

        .top-link {
            @extend %common-gray-link;
            margin-left: 30px;
            font-weight: 600;
            font-size: 13px;
            line-height: 15px;
            font-family: Montserrat;
            &.phone {
                @extend .flex-centered;
                @extend %common-dark-link;
                svg {
                    fill: $very-dark-blue;
                    margin-right: 12px;
                }
            }
        }
    }

    &.column {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px;

        .items {
            flex-direction: column;
            align-items: flex-start;
            .logo {
                justify-content: flex-start;
            }
        }

        .links {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .top-link {
                margin-left: 0px;
                padding-top: 25px;

                &.phone {
                    @extend %common-blue-link;
                    svg {
                        fill: $original-blue;
                    }
                }
            }
        }
    }

    @include phone {
        &.column {
            height: 310px;
        }
    }
}
