@import "../../../common.scss";

.supplier-landing-small-banner {
    max-width: 1140px;
    margin: 0 auto 40px;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: $original-blue;
    background-image: url("../../../images/warehouse_background.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;

    .supplier-landing-small-banner-text {
        width: 490px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 130%;
        letter-spacing: -0.084px;
        margin: 0;

        color: #fff;

        .supplier-landing-small-banner-accent {
            font-weight: 600;
        }
    }

    .supplier-landing-small-banner-button {
        display: flex;

        .round-buttons {
            border: 1px solid #fff;
        }
    }

    @media (max-width: 1140px) {
        margin: 0 20px 30px;
    }
    @media (max-width: 950px) {
        padding: 30px 20px 110px 20px;
        background-image: url("../../../images/warehouse_background_mob.png");

        .supplier-landing-small-banner-text {
            width: auto;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
        }

        .supplier-landing-small-banner-button {
            display: none;
        }
    }
}
