@import "../../../common.scss";

@mixin transition($prop: all, $time: 1s, $ease: linear) {
  -webkit-transition: $prop $time $ease;
  -moz-transition: $prop $time $ease;
  -o-transition: $prop $time $ease;
  -ms-transition: $prop $time $ease;
  transition: $prop $time $ease;
}

.side-cart {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  max-width: 335px;
  padding: 45px 0 0px;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  z-index: 15;
  box-shadow: -20px 15px 25px rgba(21, 65, 139, 0.05);
  background: #fff;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  margin-right: -335px;
  opacity: 0;
  @include transition(all, 0.15s, ease);

  &.visible {
    margin-right: 0;
    opacity: 1;
  }

  .side-cart-wrapper {
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: auto;

    .summary {
      border-radius: 0;
      padding: 20px 23.5px;
      border-top: 1px solid rgba($color: $original-blue, $alpha: 0.1);
    }
  }
  .cart-items-wrapper {
    height: calc(100% - 302px);
    flex-direction: column;

    // .ScrollbarsCustom-Track,
    // .ScrollbarsCustom-Thumb {
    //   display: none;
    // }

    // &:hover {
    //   .ScrollbarsCustom-Track,
    //   .ScrollbarsCustom-Thumb {
    //     display: none;
    //   }
    // }


  }
  .close {
    position: absolute;
    right: 30px;
    top: 10px;
    cursor: pointer;
    svg {
      fill: $original-gray;
      height: 24px;
      width: 24px;
    }
  }

  // .modal-content {
  //   height: auto;
  //   background: #fff;
  //   flex: 1;
  //   display: flex;
  // }
}
