@import "../../../common.scss";

.applications-page {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8fa;
  z-index: 4;
  .applications-page-content {
    width: 375px;
    height: 812px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .applications-page-logo {
    margin: 0 0 15px;
    width: 60px;
    height: 60px;
    background: #fff;
    box-shadow: 10px 10px 30px rgba(26, 68, 138, 0.1);
    border-radius: 15px;
  }

  .applications-page-title {
    max-width: 152px;
    margin: 0 0 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
  }

  .applications-page-button {
    width: 200px;
    height: 62px;
    margin: 0 0 15px;
  }
}
