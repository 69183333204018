@import "../../../common.scss";

.fillable-dropdown-wrap {
  margin-bottom: 25px;
  position: relative;
  .validation-msg {
    @extend .validation-text;
  }

  .Input {
    // min-height: 45px;
    // height: auto;
    .input {
      // min-height: 35px;
      // margin: 5px;
      // -webkit-appearance: none;
      // overflow-wrap: normal;
      word-wrap: normal;
      word-break: break-all;
    }
  }

  .fillable-dropdown {
    position: absolute;
    position: absolute;
    background: #ffffff;
    box-shadow: 3px 3px 50px rgba(12, 51, 117, 0.1);
    border-radius: 5px;
    width: 100%;
    z-index: 2;
    color: $very-dark-blue;
    top: 55px;
    left: 0;

    .fillable-item {
      @extend .flex-centered;
      height: 52px;
      padding: 0 20px;
      font-family: Open Sans;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.084px;
      cursor: pointer;
      > div {
        width: 100%;
      }

      .main {
        color: $very-dark-blue;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

      &.selected {
        background-color: $blue-gray;
        color: $original-blue;
      }
    }
  }
}
