@import '../../../common.scss';

.helpCardRoot {
  background: $original-white;
  box-shadow: 5px 20px 20px rgba(26, 49, 92, 0.03);
  border-radius: 5px;
  padding: 11px 30px 15px;
  box-sizing: border-box;

  &--supplier {
    background-color: $blue-gray;
  }

  &__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
    margin-bottom: 20px;
  }

  &__list {
    list-style-type: none;
    padding: 0;
  }

  &__list-item {
    padding-left: 23px;
    position: relative;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $very-dark-blue;
    margin-bottom: 15px;
    width: fit-content;

    &:hover {
      color: $original-blue;

      &::before {
        color: $original-blue;
      }
    }

    a {
      color: inherit;
    }

    &::before {
      content: '\2014';
      color: $gray-light;
      position: absolute;
      top: 50%;
      transform: translateY(-58%);
      left: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
