@import "../../../common.scss";
.cookies-accept-modal {
  .modal {
    background: none;
    backdrop-filter: none;
    z-index: 11000;
    pointer-events: none;
    .modal-content {
      pointer-events: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 15px;
      align-items: center;
      bottom: 25px;
      //   left: 0;
      width: 78%;
      left: 0;
      right: 0;
      max-width: 1140px;
      position: fixed;
      transition: all 0.25s ease;

      .disclaimer {
        display: flex;
        flex-direction: row;
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.286854px;
        color: $very-dark-blue;
        align-items: center;
        padding: 0;
        img {
          margin-right: 10px;
          height: 25px;
          width: 24px;
        }
      }

      .round-buttons.medium {
        max-width: 58px;
      }
    }

    @include phone {
      .modal-content {
        border-radius: 10px;
        max-width: 336px;
        bottom: 20px;
      }
    }
  }
}
