@import "../../../common.scss";

.zoom-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  position: fixed;
  @extend .flex-centered;
  justify-content: center;
  gap: 20px;

  width: 100%;
  height: 100vh;
  padding: 40px 0;
  box-sizing: border-box;
  z-index: 4;
  background-color: #fff;



  &__slider-side {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    z-index: 3;
    .swiper-wrapper {
      order: 2;
    }


  }


  &__slider-counter {
    margin: 0 0 15px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: $original-gray;
    text-align: center;
    order: 1;
  }

  &__slider-side-slide {
    max-width: 55px;
    width: 100%;
    max-height: 55px;
    min-height: 55px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }
  &__slide-active {
    border: 3px solid #2051a3;
    box-sizing: border-box;
  }
  &__slider-side-image {
    width: 55px;
    height: 55px;
    object-fit: cover;
  }

  &__slider-main {

    .swiper-wrapper {
      align-items: center;
      max-width: 900px;
    }
  }

  &__slider-main-slide {
    max-width: 900px;
    max-height: calc(100vh - 80px);
    width: 100%;
  }

  &__slider-main-image {
    max-width: 900px;
    max-height: calc(100vh - 80px);
    width: 100%;
    object-fit: contain;
  }

  &__close {
    position: absolute;
    top: 45px;
    right: 45px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .swiper-slide.zoom-modal__slider-side-slide.swiper-slide-visible.swiper-slide-thumb-active {
    border: 3px solid #2051a3;
    box-sizing: border-box;
  }

  .swiper-bnt {
    background: #fff;

    border-radius: 15px;
    height: 45px;
    width: 45px;
    box-sizing: border-box;
    @extend .flex-centered;
    justify-content: center;

    cursor: pointer;
    transition: 0.2s ease;
    border: 1px solid rgba(32, 81, 163, 0.1);
    svg {
      width: 18px;
      height: 18px;
      fill: $original-blue;
    }

    &:hover {
      background: $original-blue;
      svg {
        fill: #fff;
      }
    }
  }
  .swiper-btn-next,
  .swiper-btn-prev {
    transition: 0.2s ease;
  }

  .swiper-btn-prev {
    transform: rotate(180deg);
  }


  @include phone {
    padding: 40px 0;
    &__slider-side {
      position: absolute;
      top: auto;
      bottom: 40px;
      left: 50%;
    max-width: calc(100% - 20px);
      padding: 0 10px;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: column;

    }

    &__close {
      position: absolute;
      top: 25px;
      right: 25px;

    }

    &__slider-main-slide {
      max-width: 900px;
      max-height: calc(100vh - 300px);
      width: 100%;
    }

    &__slider-main-image {
      max-width: 900px;
      max-height: calc(100vh - 300px);
      width: 100%;
      object-fit: contain;
    }
  }

  @include tablet {
    padding: 40px 0;
    &__slider-side {
      position: absolute;
      top: auto;
      bottom: 40px;
      left: 50%;
      padding: 0 10px;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: column;

    }

    &__close {
      position: absolute;
      top: 25px;
      right: 25px;
    }

    &__slider-main-slide {
      max-width: 900px;
      max-height: calc(100vh - 300px);
      width: 100%;
    }

    &__slider-main-image {
      max-width: 900px;
      max-height: calc(100vh - 300px);
      width: 100%;
      object-fit: contain;
    }
  }
}
