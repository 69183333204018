@import "../../../common.scss";

.address-wrap {
  position: relative;
  margin-bottom: 25px;

  .validation-msg {
    @extend .validation-text;
  }

  .fillable-dropdown-wrap {
    margin: 0;
  }
}

.address-panel {
  @extend .round-panel;
  margin: 25px 0px;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.084px;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(32, 81, 163, 0.05);
  border: 1px solid #fff;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    border: 1px solid $original-blue;
  }

  .address-panel-title {
    margin: 0 0 5px;
  }

  .edit-address {
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      path {
        fill: $original-gray;
      }
    }
  }

  .delivery-address {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.084px;
    color: $very-dark-blue;
    margin: 0 0 5px;
  }

  .delivery-date {
    color: $very-dark-blue;
    .time {
      font-weight: normal;
    }
  }
}

.address-button {
  margin-left: 5px;
  .svg {
    margin-right: 5px;
  }
  &.business {
    .round-buttons {
      svg {
        fill: $business-color;
      }
      color: $business-color;
    }
  }
  &.private {
    .round-buttons {
      svg {
        fill: $private-color;
      }
      color: $private-color;
    }
  }

  @include phone {
    margin-left: 0;
    .round-buttons {
      padding: 0;
      padding-top: 25px;
    }
  }
}
