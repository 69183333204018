@import "../../../common.scss";

.supplier-landing-cooperation {
    max-width: 1140px;
    margin: 0 auto 20px;

    .supplier-landing-cooperation-accent {
        font-weight: 700;
    }

    .supplier-landing-cooperation-table {
        border: 1px solid rgba(32, 81, 163, 0.1);
        border-radius: 20px;
        border-spacing: 0;

        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 160%;

        color: $very-dark-blue;

        .supplier-landing-cooperation-table-header {
            height: 30px;

            .supplier-landing-cooperation-table-heading {
                padding: 20px 20px 20px 30px;
                text-align: left;
                border-right: 1px solid rgba(32, 81, 163, 0.1);
                border-bottom: 1px solid rgba(32, 81, 163, 0.1);
                max-width: 25%;
                min-width: 25%;

                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 130%;

                &:first-of-type {
                    padding-left: 40px;
                    border-top-left-radius: 20px;
                }
                &:last-of-type {
                    border-top-right-radius: 20px;
                }
                &.right {
                    border-right: none;
                }
            }
        }

        .supplier-landing-cooperation-table-cell {
            max-width: 25%;
            width: 285px;
            border-right: 1px solid rgba(32, 81, 163, 0.1);
            border-bottom: 1px solid rgba(32, 81, 163, 0.1);
            padding: 20px 20px 20px 30px;
            height: 136px;
            vertical-align: top;

            &:first-of-type {
                padding-left: 40px;
            }

            &.bottom {
                border-bottom: none;
            }

            &.right {
                border-right: none;
            }
        }
    }

    .supplier-landing-cooperation-table-mobile {
        display: none;
        flex-direction: column;
        border: 1px solid rgba(32, 81, 163, 0.1);
        border-radius: 20px;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        color: $very-dark-blue;

        list-style: none;

        .supplier-landing-cooperation-caption {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 130%;
            letter-spacing: -0.084px;
            margin: 0 0 10px;
        }

        .supplier-landing-cooperation-text {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            margin: 0 0 10px;

            &:last-of-type {
              margin: 0;
            }
        }

        .supplier-landing-cooperation-table-mobile-cell {
            border-bottom: 1px solid rgba(32, 81, 163, 0.1);
            padding: 30px 20px;
            display: flex;
            flex-direction: column;

            &:first-of-type {
                padding-top: 30px;
            }

            &:last-of-type {
                border: none;
                padding-bottom: 30px;
            }
        }
    }

    @media (max-width: 1140px) {
        margin: 0 20px 20px;
    }

    @media (max-width: 950px) {
        .supplier-landing-cooperation-table {
            display: none;
        }

        .supplier-landing-cooperation-table-mobile {
            display: flex;
        }
    }
}
