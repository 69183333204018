@import "../../../common.scss";
.error-fallback {
    position: fixed;
    width: 100%;
    top: 150px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .error-fall-back-image {
        width: 360px;
        height: 286px;
    }

    .error-fall-back-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: -0.084px;
        margin: 0;
        color: $very-dark-blue;
    }

    .error-fall-back-text {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;

        text-align: center;
        letter-spacing: -0.084px;
        color: $very-dark-blue;
        margin: 0;
    }

    .error-fall-back-link {
        font-weight: 600;
        color: $original-blue;
        text-decoration: none;
    }
}
