@import "../../../../common.scss";

.order-item-status {
    min-width: 120px;
    width: fit-content;
    width: -moz-fit-content;
    display: flex;
    justify-content: center;
    background: rgba(155, 159, 179, 0.1);
    border-radius: 5px;
    padding: 8px 10px;
    box-sizing: border-box;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: $original-gray;
    svg {
        margin-right: 8px;
    }

    > span {
        display: flex;
        align-items: center;
    }

    &.Returned {
        svg {
            fill: $original-red;
        }
    }

    &.NotReceived,
    &.OutOfStock {
        svg {
            fill: $original-gray;
        }
    }

    &.InProgress {
        svg {
            fill: $original-blue;
        }
    }

    &.Assembled,
    &.Received {
        svg {
            fill: $green;
        }
    }
}
