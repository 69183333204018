@import "../../../common.scss";

// $bgColor: rgba(
//   $color: $gray-light,
//   $alpha: 0.2,

// );
$bgColor: rgba(
    $alpha: 0.2,
    $color: #fff,
);

.categories-menu {
    left: 0;
    right: 0;
    background: #fff;
    z-index: 3;
    position: fixed;
    bottom: 0;
    top: 160px;
    height: 100vh;
    display: flex;
    box-shadow: 15px 15px 25px rgba(21, 65, 139, 0.05);
    border-top: 1px solid $bg-color;
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $bgColor;
        right: 50%;
        z-index: -1;
    }

    .scroll-wrapper {
        display: flex;
        background-color: white;
        width: 100%;

        @include phone {
            height: 100vh;
        }
        .intro {
            padding-left: 150px;
        }

        .categories {
            flex-direction: column;
            position: relative;
            display: flex;
            border-right: 1px solid rgba($color: $gray-light, $alpha: 0.3);
            flex: 1;
            height: 100%;
            max-width: 380px;
            min-width: 380px;
            background-color: #fff;

            .ScrollbarsCustom-Track,
            .ScrollbarsCustom-Thumb {
                opacity: 0.1;
            }

            &:hover {
                .ScrollbarsCustom-Track,
                .ScrollbarsCustom-Thumb {
                    opacity: 1;
                }
            }
            &.container {
                margin: 0;
                margin-left: auto;
            }

            &.main {
                background-color: $bgColor;
            }

            &.last {
                border-right: none;
            }

            &.level2 {
                .menu-item {
                    &.selected {
                        background-color: rgba(233, 238, 246, 0.5);
                    }
                }
            }

            .menu-item {
                @extend .flex-centered;
                flex-direction: row;
                padding-right: 20px;
                padding: 0px 13px 0px 20px;
                min-height: 67px;
                .category-wrapper {
                    @extend .flex-centered;
                }

                a {
                    @extend %common-dark-link;
                    font-size: 13px;
                    line-height: 18px;
                    @extend .flex-centered;
                    justify-content: space-between;
                    width: 100%;
                    > svg {
                        path {
                            fill: $original-gray;
                        }
                    }
                    span {
                        width: 190px;
                    }

                    &:hover {
                        color: #fff;
                    }
                }

                .category-img {
                    margin-right: 10px;
                    width: 40px;
                    height: 40px;
                }

                &.selected {
                    background-color: $blue-gray;
                    > a {
                        color: $very-dark-blue;
                        > svg {
                            path {
                                fill: $original-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}
